import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem, Paper } from "@material-ui/core";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import ErrorDisplay from "../../../components/ErrorDisplay";
import FormInputField from "../../../components/FormInputField";
import FormReactSelect from "../../../components/FormReactSelect";
import { formatDate, removeEmpty } from "../../../components/Functions";
import Modal from "../../../components/Modal";
import FilterDropdown from "../../../components/MUTable/FilterDropdown";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import {
  roleStyling,
  statusStyling,
} from "../../../components/StylingFunctions";
import { userSchema } from "../../../components/Validation/Admin/AssignSalaryMethodSchema";
import profile from "../../../images/table/default_profile.svg";
import EditButton from "./../../../components/MaterialTableButtons/EditButton";

const SalaryMethod = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [idStatus, setIdStatus] = useState();
  const [disabled, setDisabled] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    name: "",
    arrivalTime: "",
    departureTime: "",
  });
  const [error, setError] = useState("");

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = methods;

  const deleteUser = (rowData) => {
    setSelectedUser(rowData);
    setDeleteModal(true);
  };

  const editUser = (rowData) => {
    setSelectedUser(rowData);

    if (rowData.isGrade === true) {
      setIdStatus("grade");
      setEditModal(true);
      reset({
        gradeId: rowData.gradeId._id,
      });
    } else {
      reset({
        salary: rowData.salary,
        health_allowance: rowData.health_allowance,
        fuel_allowance: rowData.fuel_allowance,
      });
      setIdStatus("manual");
      setEditModal(true);
    }
    console.log(selectedUser.isGrade);
  };

  //Adding user
  const HandleForm = (data) => {
    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/grade`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setModal(false);
          setDisabled(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Grade Added ",
        error: "An error has occurred",
      });
    }
  };

  const HandleEditForm = (data) => {
    console.log(data);

    if (data.gradeSelected === "manual") {
      delete data["gradeId"];
    } else if (data.gradeSelected === "grade") {
      delete data["salary"];
      delete data["health_allowance"];
      delete data["fuel_allowance"];
    }

    delete data["gradeSelected"];

    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/users/${selectedUser._id}/change`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Salary Method Updated ",
        error: "An error has occurred",
      });
    }
  };

  const handleImageErr = (err) => {
    err.target.src = profile;
  };

  const columns = [
    // {
    //   title: "",
    //   field: "photo",
    //   filtering: false,
    //   render: (rowData) => (
    //     <Image
    //       src={
    //         rowData.photo === "no-photo.jpg"
    //           ? profile
    //           : `${process.env.REACT_APP_BASEURL}/uploads/profile/${rowData.photo}`
    //       }
    //       className="h-10 rounded-full"
    //     />
    //   ),
    // },
    { title: "NAME", field: "username", filtering: false },
    {
      title: "ROLE",
      field: "role",
      filtering: false,
      render: (rowData) => (
        <div style={roleStyling(rowData.role)}>{rowData.role}</div>
      ),
    },
    {
      title: "CREATED DATE",
      field: "createAt",
      filtering: false,
      render: (rowData) => formatDate(rowData.createAt),
    },
    {
      title: "DESIGNATION",
      field: "designation",
      filtering: false,
    },
    {
      title: "STATUS",
      field: "status",
      filterComponent: (props) => (
        <FilterDropdown data={props}>
          <MenuItem value={"Active"}>Active</MenuItem>
          <MenuItem value={"Inactive"}>Inactive</MenuItem>
        </FilterDropdown>
      ),
      render: (rowData) => (
        <div style={statusStyling(rowData.status)}>{rowData.status}</div>
      ),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-col  gap-6 mb-4 md:absolute z-10 right-10"></div>
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            borderBottom: "2px solid #FFFFFF",
            filtering: true,
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderColor: "white",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: () => <EditButton />,
              onClick: (event, rowData) => {
                reset();
                editUser(rowData);
                setEditForm(false);
              },
            },

            // (rowData) => ({
            //   icon: "delete",
            //   tooltip: "Delete User",
            //   onClick: (event, rowData) =>
            //     confirm("You want to delete " + rowData.name),
            //   disabled: rowData.birthYear < 2000,
            // }),
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${process.env.REACT_APP_BASEURL}/api/v1/users?`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              if (query.search) {
                url += "&username=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* Edit Grade Modal */}
      <Modal
        show={editModal}
        className="w-3/4 lg:w-2/6 md:h-fit"
        close={() => {
          setEditModal(false);
          reset({});
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleEditForm)}
            className="flex flex-col gap-6 justify-center align-center"
          >
            <div className="w-full">
              <h2 className="text-lg font-bold mb-2">Salary Details</h2>

              <div className="flex flex-col gap-4">
                <div>
                  <div
                    className="flex flex-row justify-between form-check"
                    onChange={(e) => setIdStatus(e.target.value)}
                  >
                    <h4>Manual Salary Assignment</h4>
                    <input
                      name="manual"
                      type="radio"
                      disabled={!editForm}
                      defaultChecked={!selectedUser.isGrade}
                      value={"manual"}
                      onChange={(e) => setIdStatus(e.target.value)}
                      className="cursor-pointer w-4"
                      {...register("gradeSelected")}
                    />
                  </div>
                </div>

                <div
                  className={
                    idStatus === "manual"
                      ? "grid grid-cols-1 sm:grid-cols-2 gap-3"
                      : "hidden"
                  }
                >
                  <FormInputField
                    placeholder="Base Salary (in PKR)"
                    type="number"
                    defaultValue={selectedUser.salary}
                    disabled={!editForm}
                    name={"salary"}
                  />
                  <FormInputField
                    placeholder="Health Allowance"
                    type="number"
                    defaultValue={selectedUser.health_allowance}
                    disabled={!editForm}
                    name={"health_allowance"}
                  />
                  <FormInputField
                    placeholder="Fuel Allowance"
                    type="number"
                    defaultValue={selectedUser.fuel_allowance}
                    disabled={!editForm}
                    name={"fuel_allowance"}
                  />
                </div>

                <div>
                  <div
                    className="flex flex-row justify-between form-check"
                    onChange={(e) => {
                      setIdStatus(e.target.value);
                    }}
                  >
                    <h4>Grade Assignment</h4>
                    <input
                      name="manual"
                      type="radio"
                      disabled={!editForm}
                      value={"grade"}
                      defaultChecked={selectedUser.isGrade}
                      onChange={(e) => setIdStatus(e.target.value)}
                      className="cursor-pointer w-4"
                      {...register("gradeSelected")}
                    />
                  </div>
                  {/* <p>
                    No automated ID will be provided for your users and you will
                    have to manually enter a custom ID for each user.
                  </p> */}
                </div>
                <div className={idStatus === "grade" ? "" : "hidden"}>
                  {
                    <FormReactSelect
                      apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/grade?`}
                      searchCriteria={"name"}
                      name={"gradeId"}
                      isDisabled={!editForm}
                      label="Grade"
                      placeholder={
                        selectedUser.gradeId ? selectedUser.gradeId.name : null
                      }
                    />
                  }
                  {/* <FormSelectField
                    name="gradeId"
                    isDisabled={idStatus === "grade" ? false : true}
                  ></FormSelectField> */}
                </div>
              </div>
            </div>

            <ErrorDisplay>{error}</ErrorDisplay>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                onClick={() => setEditModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              {editForm === true ? (
                <input
                  type={"submit"}
                  value={"Save Changes"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                />
              ) : (
                <button
                  onClick={() => setEditForm(true)}
                  type={"button"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                >
                  Edit{" "}
                </button>
              )}
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Delete Grade Modal */}
      {/* <Modal
        show={deleteModal}
        close={() => {
          setDeleteModal(false);
        }}
      >
        <h2 className="text-lg">
          Are you sure you want to <br />
          {selectedUser.status == "Active" ? "deactivate " : "activate "}
          {selectedUser.name}
        </h2>
        <div className="grid grid-cols-2 gap-4 mt-5">
          <input
            onClick={() => setDeleteModal(false)}
            type="button"
            value="Cancel"
            className="grey p-3 rounded-lg text-white cursor-pointer"
          />
          <input
            type="button"
            onClick={() => HandleDeleteForm()}
            value={selectedUser.status == "Active" ? "Deactivate" : "Activate"}
            className="red p-3 rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal> */}
    </main>
  );
};

export default SalaryMethod;
