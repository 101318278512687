import { userSchema } from "../../../components/Validation/Admin/CompleteDetailsSchema";
import { useForm, FormProvider } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";

import { toast } from "react-hot-toast";
import { useState } from "react";
import SignupInputField from "../../../components/SignUpInput/SignupInputField";
import SignupSelectField from "../../../components/SignUpInput/SignupSelectField";
import SignupTextareaField from "../../../components/SignUpInput/SignupTextareaField";
import ErrorDisplay from "../../../components/ErrorDisplay";
import logo from "../../../images/insignia_logo.svg";
import FormTextArea from "../../../components/FormTextArea";

const CompleteDetails = () => {
  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
    mode: "all",
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = methods;

  // const history = useHistory();
  const [idStatus, setIdStatus] = useState();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [formStep, setFormStep] = useState(0);
  let navigate = useNavigate();

  const completeFormStep = () => {
    console.log(errors);

    //This is for preventing the next button to work if current fields have
    //validation errors. A better approach would be to seperate all
    //pages of a form wizard into their own seperate forms.

    //Validation for first page
    if (formStep === 0) {
      if (
        !errors.username &&
        !errors.address &&
        !errors.designation &&
        !errors.emerg_contact &&
        !errors.emerg_name &&
        !errors.nic &&
        !errors.phone
      )
        setFormStep((current) => current + 1);
    }

    //Validation for second page
    if (formStep === 1) {
      if (
        !errors.coreServices &&
        !errors.location &&
        !errors.office_address &&
        !errors.office_end &&
        !errors.office_start
      )
        setFormStep((current) => current + 1);
    }
  };

  const previousStep = () => {
    setFormStep((current) => current - 1);
  };

  const renderButton = () => {
    if (formStep > 2) {
      return undefined;
    } else if (formStep === 2) {
      return (
        <div className="flex flex-row gap-4">
          <button
            type="button"
            onClick={previousStep}
            className="black text-white py-3 px-7 rounded-lg "
          >
            Back
          </button>

          <button
            type="button"
            onClick={handleSubmit(HandleForm)}
            className={`${
              isValid ? "green" : "black"
            } text-white py-3 px-7 rounded-lg`}
          >
            Submit
          </button>
        </div>
      );
    } else if (formStep === 0) {
      return (
        <div>
          <button
            type="button"
            onClick={completeFormStep}
            className="black text-sm xl:text-base text-white py-3 px-7 rounded-lg "
          >
            Next
          </button>
        </div>
      );
    } else if (formStep !== 0 && formStep !== 2) {
      return (
        <div className="flex flex-row gap-4">
          <button
            type="button"
            onClick={previousStep}
            className="black text-white py-3 px-7 rounded-lg "
          >
            Back
          </button>
          <button
            type="button"
            onClick={completeFormStep}
            className="black text-white py-3 px-7 rounded-lg"
          >
            Next
          </button>
        </div>
      );
    }
  };

  const HandleForm = (data) => {
    // const file = data["file"];
    // data["file"] = file[0];

    if (data.autoID === "autoID") {
      data["autoID"] = "true";
      delete data.customID;
      if (data.test) delete data.test;
    } else if (data.autoID === "customID") {
      delete data.autoID;
      data["customID"] = "true";
      if (data.temp) delete data.temp;
    }
    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();
      const promise = axios({
        // Endpoint to send files
        url: `${
          process.env.REACT_APP_BASEURL
        }/api/v1/users/${localStorage.getItem("id")}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res.data.data);
          setDisabled(false);
          // setError(res.message);

          localStorage.setItem("name", res.data.data.username);
          localStorage.setItem("photo", res.data.data.photo);
          localStorage.setItem("id", res.data.data.id);

          //redirecting
          navigate("/AD/dashboard");
        })
        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          setError(err.response.data.error);
          // toast.error(err.response.data.error);
          return Promise.reject();
        });
      toast.promise(promise, {
        loading: "Loading",
        success: "Updated ",
        error: "An error has occurred",
      });
    }
  };

  return (
    <div className="p-6 2xl:p-10 bg-white h-screen overflow-scroll w-full">
      <div className="flex flex-row justify-end">
        <Link
          onClick={() => {
            localStorage.clear();
          }}
          to="/login"
          className="black text-white py-3 px-4 rounded-lg text-xs"
        >
          Sign out
        </Link>
      </div>
      <div className="flex flex-col justify-center items-center gap-6">
        <img className="h-24 xl:h-32" src={logo} alt="" />
        <h1 className="text-xl xl:text-2xl font-semibold">Initial Setup</h1>
      </div>

      <FormProvider {...methods}>
        <form className="mt-2  flex flex-col gap-7 items-center">
          {console.log(formStep)}
          {formStep >= 0 && (
            <section className={formStep === 0 ? "w-3/4 min-w-fit" : "hidden"}>
              <div>
                <h2 className="text-lg xl:text-xl text-center font-light mb-6">
                  Enter Personal Details
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                  <SignupInputField
                    placeholder="Name*"
                    name={"username"}
                    labelClass={"font-normal"}
                  />

                  <SignupInputField
                    name={"phone"}
                    type="number"
                    labelClass={"font-normal"}
                    placeholder="030XXXXXXXX*"
                  />
                  <SignupInputField
                    name={"nic"}
                    type="number"
                    labelClass={"font-normal"}
                    autocomplete="new-password"
                    placeholder="National Identity Number*"
                  />

                  <SignupInputField
                    placeholder="Date of Birth"
                    type="text"
                    id="dateOfBirth"
                    labelClass={"font-normal"}
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    name={"dateOfBirth"}
                    size="30"
                  />

                  <SignupSelectField
                    name={"maritalStatus"}
                    type="text"
                    autocomplete="new-password"
                    labelClass={"font-normal"}
                    placeholder="Marital Status*"
                  >
                    <option value="" disabled selected>
                      Select Marital
                    </option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                  </SignupSelectField>

                  <SignupSelectField
                    name={"gender"}
                    labelClass={"font-normal"}
                    autocomplete="new-password"
                    placeholder="National Identity Number*"
                  >
                    <option value="" disabled selected>
                      Select Gender
                    </option>
                    <option value="male">Male</option>
                    <option value="physical">Female</option>
                  </SignupSelectField>

                  <SignupInputField
                    placeholder="Address"
                    type="text"
                    name={"address"}
                    labelClass={"font-normal"}
                  />

                  <SignupInputField
                    placeholder="Designation"
                    name={"designation"}
                    labelClass={"font-normal"}
                  />

                  <SignupInputField
                    placeholder="Emergency Name"
                    name={"emerg_name"}
                    labelClass={"font-normal"}
                  />

                  <SignupInputField
                    placeholder="030XXXXXXXX*"
                    type="number"
                    name={"emerg_contact"}
                    labelClass={"font-normal"}
                  />
                </div>
              </div>
            </section>
          )}

          {formStep >= 1 && (
            <section className={formStep === 1 ? "w-3/4 min-w-fit" : "hidden"}>
              <div>
                <h2 className="text-lg xl:text-xl text-center font-light mb-6">
                  Enter Office Details
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                  <SignupInputField
                    placeholder="Office Address*"
                    type="text"
                    name={"office_address"}
                    labelClass={"font-normal"}
                  />

                  <SignupInputField
                    placeholder="City & Country*"
                    type="text"
                    name={"location"}
                    labelClass={"font-normal"}
                  />

                  <SignupInputField
                    placeholder="Business Website"
                    type="text"
                    name={"website"}
                    labelClass={"font-normal"}
                  />
                  {/* <SignupInputField
                    placeholder="Salary"
                    type="number"
                    name={"salary"}
                    labelClass={"font-normal"}
                  /> */}

                  <SignupInputField
                    placeholder="Office Start Time*"
                    type="text"
                    onFocus={(e) => (e.target.type = "time")}
                    onBlur={(e) => (e.target.type = "text")}
                    name={"office_start"}
                    labelClass={"font-normal"}
                  />

                  <SignupInputField
                    placeholder="Office End Time*"
                    type="text"
                    onFocus={(e) => (e.target.type = "time")}
                    onBlur={(e) => (e.target.type = "text")}
                    name={"office_end"}
                    labelClass={"font-normal"}
                  />
                </div>
                <SignupTextareaField
                  placeholder="Enter Core Services*"
                  type="text"
                  name={"coreServices"}
                  className="mt-4 p-5"
                  labelClass={"font-normal"}
                />
              </div>
            </section>
          )}

          {formStep >= 2 && (
            <section className={formStep === 2 ? "w-3/4 min-w-fit" : "hidden"}>
              <div>
                <h2 className="text-lg xl:text-xl text-center font-light mb-6">
                  Select ID Generation Method
                </h2>

                <div className="flex flex-col gap-4 ">
                  <div>
                    <div
                      className="flex flex-row justify-between form-check"
                      onChange={(e) => setIdStatus(e.target.value)}
                    >
                      <h4 className="text-lg">Auto ID</h4>
                      <input
                        name="autoID"
                        type="radio"
                        value={"autoID"}
                        onChange={(e) => setIdStatus(e.target.value)}
                        className="cursor-pointer w-4"
                        {...register("autoID")}
                      />
                    </div>
                    <p>
                      You will only need to provide intials for your office and
                      the ID generation for your users will be handled by our
                      system.
                    </p>
                  </div>

                  <div className={idStatus === "autoID" ? "" : "hidden"}>
                    <SignupInputField
                      placeholder="Office Initials"
                      type="text"
                      maxLength={3}
                      required={idStatus === "autoID" ? true : false}
                      name={"temp"}
                    />
                  </div>

                  <div>
                    <div
                      className="flex flex-row justify-between form-check"
                      onChange={(e) => setIdStatus(e.target.value)}
                    >
                      <h4 className=" text-lg">Custom ID</h4>
                      <input
                        name="autoID"
                        type="radio"
                        value={"customID"}
                        className="cursor-pointer w-4"
                        {...register("autoID")}
                      />
                    </div>

                    <p>
                      You will have to manually enter each user's ID upon
                      creation.
                    </p>

                    <div className={idStatus === "customID" ? "" : "hidden"}>
                      <SignupInputField
                        placeholder="Enter Your Custom ID"
                        type="text"
                        className={"mt-3"}
                        required={idStatus === "customID" ? true : false}
                        name={"test"}
                      />
                    </div>
                  </div>
                  <p className="text-red-700">
                    NOTE: THIS CANNOT BE CHANGED LATER
                  </p>
                </div>
              </div>
            </section>
          )}
          <div className="w-3/4 min-w-fit">
            <ErrorDisplay>{error}</ErrorDisplay>
            {console.log(errors)}
          </div>

          {/* <div className=" self-end">
            <button
              type="submit"
              className="green text-white py-3 px-7 rounded-lg"
            >
              Submit
            </button>
          </div> */}
          <div className=" self-center mt-3">{renderButton()}</div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CompleteDetails;
