import { MdOutlineGroups } from "react-icons/md";

const CSVComponent = ({
  heading,
  HandleRequest,
  value,
  description,
  name,
  icon,
  buttonText,
}) => {
  return (
    <div
      className={`p-8 min-w-fit h-full flex flex-col items-center text-center bg-white rounded-lg shadow-xl `}
    >
      <div className={` flex flex-col gap-4  w-full h-full items-center`}>
        {icon && icon}
        <h2 className="text-2xl font-semibold whitespace-normal">{heading}</h2>

        {description && <p>{description}</p>}
      </div>

      <div className={"flex flex-row whitespace-nowrap mt-4"}>
        <button
          type="button"
          onClick={(e) => HandleRequest(name)}
          className="py-3 px-4 text-right font-extralight rounded-lg primary text-white text-sm"
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default CSVComponent;
