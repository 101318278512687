import Cards from "../../../components/Dashboard/Cards";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useState, useEffect } from "react";
import LineGraph from "../../../components/LineGraph";
import PageLoader from "../../../components/PageLoader";

const Dashboard = () => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [cardData, setCardData] = useState({
    NewOfficeRequests: "",
    InactiveOffices: "",
    NewComplains: "",
    NewModuleRequests: "",
  });

  const [graphData, setGraphData] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getCardData();
    getGraphData();
  }, []);

  //For cards
  const getCardData = (data) => {
    setError();

    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/api/v1/offices/getTotals`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res.data.data);
        setCardData(res.data.data);
      })

      // Catch errors if any
      .catch((err) => {
        setDisabled(false);
        return Promise.reject();
      });

    // toast.promise(promise, {
    //   loading: "Loading",
    //   success: "Card Data loaded",
    //   error: "An error has occurred",
    // });
  };

  //Sorting graph API data
  const sortGraphData = (data) => {
    let newData = [];

    for (const property in data) {
      newData.push({ month: property, value: data[property] });
    }
    console.log(newData);
    setGraphData(newData);
    setLoaded(true);
  };

  //For graphs
  const getGraphData = () => {
    setError();
    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/api/v1/offices/getSuperAdminGraph`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        sortGraphData(res.data.data[0].data);
      })
      // Catch errors if any
      .catch((err) => {
        console.log(err);
        return Promise.reject();
      });
    // toast.promise(promise, {
    //   loading: "Loading",
    //   success: "Graph Data loaded",
    //   error: "An error has occurred",
    // });
  };

  if (loaded === false) {
    return <PageLoader />;
  } else
    return (
      <main className="content-container">
        <div className="flex flex-col gap-10 mt-4">
          <div>
            <h1 className="text-3xl md:text-5xl font-extrabold mb-6">
              Dashboard
            </h1>
            <div className="mt-4 flex flex-wrap flex-col items-center md:justify-start md:flex-row gap-5">
              <Cards
                className="grow"
                label={"TOTAL OFFICES"}
                number={cardData.totalOffices}
                link={"/SU/offices"}
                color={"bg-custom-green"}
              />
              <Cards
                className="grow"
                label={"OFFICE REQUESTS"}
                number={cardData.NewOfficeRequests}
                link={"/SU/offices/office-req"}
                color={"bg-custom-secondary"}
              />
              <Cards
                className="grow"
                label={"NEW COMPLAINS"}
                number={cardData.NewComplains}
                link={"/SU/complains"}
                color={"bg-custom-primary"}
              />
              <Cards
                className="grow"
                label={"NEW MODULES REQUEST"}
                number={cardData.NewModuleRequests}
                link={"/SU/modules"}
                color={"bg-custom-secondary"}
              />
            </div>
          </div>

          <div>
            <h1 className="text-2xl font-bold mb-3">Monthly Office Requests</h1>
            <LineGraph data={graphData} />
          </div>
        </div>
      </main>
    );
};

export default Dashboard;
