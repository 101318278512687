import { useFormContext } from "react-hook-form";
import Textarea from "react-expanding-textarea";
import { useCallback, useEffect, useRef } from "react";

const FormTextArea = ({
  name,
  className = "input-form bg-slate-100",
  children,
  label,

  labelClass = "text-lg font-semibold",
  ...otherProps
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const textareaRef = useRef(null);

  const handleChange = useCallback((e) => {
    console.log("Changed value to: ", e.target.value);
  }, []);

  return (
    <div className="flex flex-col w-full">
      {label && (
        <label className={`inline-block mb-2 ${labelClass} `}>{label}</label>
      )}
      <Textarea
        className={className}
        ref={textareaRef}
        id="my-textarea"
        maxLength="3000"
        onChange={handleChange}
        {...register(name)}
        {...otherProps}
      />
      <div>
        <p className="text-red-700 mt-2 text-left">{errors[name]?.message}</p>
      </div>
    </div>
  );
};

export default FormTextArea;
