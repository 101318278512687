import { Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useRef, useState } from "react";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";

import ReactDatePicker from "react-datepicker";
import {
  formatDate,
  formatTimeDate,
  getDay,
  getFirstDay,
  getLastDay,
} from "../../../components/Functions";
import Modal from "../../../components/Modal";
import { attendanceStyling } from "../../../components/StylingFunctions";
import profile from "../../../images/table/default_profile.svg";
import ArrowButton from "./../../../components/MaterialTableButtons/ArrowButton";

const UserAttendance = (props) => {
  const [modal, setModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedUser, setSelectedUser] = useState({
    username: "",
    attendanceStatus: "",
    startTime: "",
    endTime: "",
    userId: "",
    hours: "",
    attendanceStatus: "",
    shift: "",
  });

  //getting current day date for attendance modal
  const now = new Date();
  const firstDay = () => {
    const day = new Date(now.getFullYear(), now.getMonth(), 1);
    return day;
  };

  const lastDay = () => {
    const day = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return day;
  };

  const [chooseDate1, setChooseDate1] = useState(firstDay());
  const [chooseDate2, setChooseDate2] = useState(lastDay());

  const [attendanceStatus, setAttendanceStatus] = useState("Present");
  const [error, setError] = useState("");

  // For refreshing table
  const tableRef = useRef();
  const tableModalRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  const refreshModalTable = () => {
    tableModalRef.current.onQueryChange();
  };

  //For form

  const viewAttendance = (rowData) => {
    setSelectedUser(rowData);
    setModal(true);
  };

  const handleImageErr = (err) => {
    err.target.src = profile;
  };

  const columns = [
    // {
    //   title: "",
    //   field: "photo",
    //   filtering: false,
    //   render: (rowData) => (
    //     <img
    //       onError={handleImageErr}
    //       src={
    //         rowData.photo === "no-photo.jpg"
    //           ? profile
    //           : `${process.env.REACT_APP_BASEURL}/uploads/profile/${rowData.photo}`
    //       }
    //       className="w-12 rounded-full"
    //     />
    //   ),
    // },
    { title: "NAME", field: "username", filtering: false },
    { title: "EMAIL", field: "email", filtering: false },
    {
      title: "ARRIVAL TIME",
      filtering: false,
      field: "startTime",
      render: (rowData) => formatTimeDate(rowData.startTime),
    },
    {
      title: "DEPARTURE TIME",
      filtering: false,
      field: "endTime",
      render: (rowData) => formatTimeDate(rowData.endTime),
    },
    {
      title: "TOTAL HOURS",
      filtering: false,
      field: "workingHours",
    },
    {
      title: "ATTENDANCE STATUS",
      field: "attendanceStatus",
      lookup: { Present: "Present", Absent: "Absent" },
      filtering: true,
      render: (rowData) => (
        <div style={attendanceStyling(rowData.attendanceStatus)}>
          {rowData.attendanceStatus}
        </div>
      ),
    },
    {
      title: "SHIFT",
      filtering: false,
      field: "shiftId.name",
      // render: (rowData) => rowData.endTime.slice(0, 10),
    },
  ];

  const columnsModal = [
    {
      title: "Date",
      filtering: false,
      field: "startTime",
      render: (rowData) => formatDate(rowData.startTime),
    },
    {
      title: "Day",
      filtering: false,
      field: "startTime",
      render: (rowData) => getDay(formatDate(rowData.startTime)),
    },
    // {
    //   title: "Day",
    //   field: "startTime",
    //   filtering: false,
    //   render: (rowData) => rowData.startTime.slice(0, 10),
    // },
    {
      title: "ARRIVAL TIME",
      filtering: false,
      field: "startTime",
      render: (rowData) => formatTimeDate(rowData.startTime),
    },
    {
      title: "DEPARTURE TIME",
      filtering: false,
      field: "endTime",
      render: (rowData) => formatTimeDate(rowData.endTime),
    },
    {
      title: "TOTAL HOURS",
      filtering: false,
      field: "workingHours",
      // render: (rowData) =>
      //   //Nested if implemented
      //   rowData.workingHours !== undefined
      //     ? rowData.workingHours !== null
      //       ? `${rowData.workingHours}:${rowData.workingMinutes}`
      //       : ""
      //     : "",
    },
    {
      title: "ATTENDANCE STATUS",
      field: "attendanceStatus",
      lookup: { Present: "Present", Absent: "Absent" },
      filtering: true,
      render: (rowData) => (
        <div style={attendanceStyling(rowData.attendanceStatus)}>
          {rowData.attendanceStatus}
        </div>
      ),
    },
    {
      title: "SHIFT",
      filtering: false,
      field: "shiftId.name",
      // render: (rowData) => rowData.endTime.slice(0, 10),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 relative px-8 bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-row md:absolute gap-6 mb-4 z-10 right-10 top-9">
          <ReactDatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              refreshTable();
            }}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            placeholderText="Select Month"
            className="input-calendar cursor-pointer"
          />
          {console.log(getLastDay(startDate))}
          {console.log(getFirstDay(startDate))}
        </div>
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            filtering: false,

            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderBottom: "null",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: () => <ArrowButton />,
              onClick: (event, rowData) => {
                viewAttendance(rowData);
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${
                process.env.REACT_APP_BASEURL
              }/api/v1/attendance/getAttendanceStatus?&createAt[gte]="${getFirstDay(
                startDate
              )}"&createAt[lte]="${getLastDay(startDate)}`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              if (query.search) {
                url += "&username=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* View Attendance */}
      <Modal
        className="md:w-3/4 lg:w-3/4 h-3/4 md:h-fit"
        show={modal}
        close={() => {
          setModal(false);
        }}
      >
        <div className=" p-3">
          <div className="flex flex-col lg:flex-row gap-4 justify-between items-center">
            <div className="flex flex-col md:flex-row gap-4 items-center">
              <img
                onError={handleImageErr}
                src={
                  selectedUser.photo === "no-photo.jpg"
                    ? profile
                    : `${process.env.REACT_APP_BASEURL}/uploads/profile/${selectedUser.photo}`
                }
                className="h-20 w-20 rounded-full"
                alt=""
              />
              <div>
                <h2 className="text-xl font-bold ">{selectedUser.username}</h2>
                <p className="text-base">{selectedUser.email}</p>
              </div>
            </div>
            <div>
              {/* today's attendance */}
              <div>
                <div className="flex flex-row justify-between items-center gap-3 p-3 rounded-xl bg-gradient-to-r from-custom-gradient-1 to-custom-gradient-2 drop-shadow-gradient ">
                  <div className="text-center text-white">
                    <h3 className="font-semibold">TODAY</h3>
                    <div className="flex flex-row mt-1 gap-3">
                      <div>
                        <p className="font-semibold">Arrival</p>
                        <p className="whitespace-nowrap font-thin">
                          {formatTimeDate(selectedUser.startTime)}
                        </p>
                      </div>
                      <div>
                        <p className="font-semibold">Departure</p>
                        <p className="whitespace-nowrap font-thin">
                          {formatTimeDate(selectedUser.endTime)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="text-center p-3 bg-white rounded-xl">
                    <p className="w-full text-transparent bg-clip-text bg-gradient-to-r from-custom-gradient-1 to-custom-gradient-2">
                      SHIFT
                    </p>
                    <p className="w-full text-transparent bg-clip-text bg-gradient-to-r from-custom-gradient-1 to-custom-gradient-2">
                      {selectedUser.shiftId && selectedUser.shiftId.name}
                    </p>
                  </div>
                </div>

                {/* Date selector */}
                <div className="mt-3 flex flex-col md:flex-row gap-3">
                  <div>
                    <h2 className="font-bold ">From</h2>
                    <input
                      className="input-form"
                      type="date"
                      onChange={(e) => {
                        setChooseDate1(e.target.value);
                        if (!e.target.value) {
                          setChooseDate1(firstDay);
                        }
                        refreshModalTable();
                      }}
                    />
                  </div>
                  <div>
                    <h2 className="font-bold ">To</h2>
                    <input
                      className="input-form"
                      type="date"
                      onChange={(e) => {
                        setChooseDate2(e.target.value);
                        if (!e.target.value) {
                          setChooseDate2(lastDay);
                        }
                        refreshModalTable();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-7 p-4 bg-custom-gray rounded-2xl">
            <MaterialTable
              id={"modalTable"}
              tableRef={tableModalRef}
              components={{
                Container: (props) => (
                  <Paper
                    {...props}
                    elevation={0}
                    style={{
                      backgroundColor: "#F3F3F3",
                    }}
                  />
                ),
                Toolbar: (props) => (
                  <div
                    style={{
                      display: "none",
                      justifyContent: "left",
                      marginLeft: "-58px",
                      marginBottom: "20px",
                    }}
                  >
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              icons={tableIcons}
              title=""
              columns={columnsModal}
              options={{
                paging: false,
                loadingType: "linear",
                style: {
                  background: "rgba(243, 243, 243, 1)",
                },
                filtering: true,
                debounceInterval: 700,
                headerStyle: {
                  fontWeight: "bold",
                  background: "rgba(243, 243, 243, 1)",

                  fontSize: "15px",
                  textTransform: "uppercase",
                },
                rowStyle: {
                  background: "rgba(243, 243, 243, 1)",
                  borderBottom: "null",
                },
                search: false,
                sorting: false,
                actionsColumnIndex: -1,
              }}
              // actions={[
              //   {
              //     icon: ArrowForwardIcon,
              //     onClick: (event, rowData) => {
              //       viewAttendance(rowData);
              //     },
              //   },
              // ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  var myHeaders = new Headers();
                  myHeaders.append("Accept", "application/json");
                  myHeaders.append(
                    "Authorization",
                    `Bearer ${localStorage.getItem("token")}`
                  );

                  var requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                  };
                  let url = `${process.env.REACT_APP_BASEURL}/api/v1/attendance/${selectedUser.userId}?createAt[gte]="${chooseDate1}"&createAt[lte]="${chooseDate2}`;

                  if (query.filters.length) {
                    const filter = query.filters.map((filter) => {
                      if (filter.value[0] && filter.value.length == 1)
                        return `&${filter.column.field}${filter.operator}${filter.value[0]}`;
                    });
                    url += filter.join("");
                  }

                  if (query.search) {
                    url += "&username=" + query.search;
                  }
                  url += "&limit=" + query.pageSize;
                  url += "&page=" + (query.page + 1);

                  console.log("page", parseInt(query.page));
                  fetch(url, requestOptions)
                    .then((response) => {
                      if (response.ok) {
                        return response.json();
                      }
                      return Promise.reject(response); // 2. reject instead of throw
                    })
                    .then((result) => {
                      console.log(result);
                      console.log("apicall", result.data);
                      resolve({
                        data: result.data,
                        page: query.page,
                        totalCount: result.total,
                      });
                    })
                    .catch((error) => console.log("error", error));
                  // .then((result) => {
                  //   console.log("res", result);
                  //   resolve({
                  //     data: result.data,
                  //     page: result.page - 1,
                  //     totalCount: result.total,
                  //   });
                  // });
                })
              }
            />
          </div>
        </div>
      </Modal>
    </main>
  );
};

export default UserAttendance;
