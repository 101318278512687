import { useFormContext } from "react-hook-form";

const ModuleComponent = ({
  heading,
  HandleDeleteForm,
  value,
  description,
  name,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div
      className={`p-4 rounded-xl shadow-lg min-w-fit h-full flex flex-col ${
        value ? "bg-[#dbefd1]" : ""
      }`}
    >
      <div
        className={` flex flex-col gap-3  w-full h-full ${
          value ? " opacity-50 " : ""
        }`}
      >
        <div className="flex flex-row gap-4 items-center">
          <input
            type="checkbox"
            className="h-5 w-5 accent-custom-green"
            defaultChecked={value}
            disabled={value}
            {...register(name)}
          />
          <h2 className="text-xl font-semibold whitespace-normal">{heading}</h2>
        </div>
        <p>{description}</p>
      </div>

      <div className={value ? "flex flex-row self-end  mt-4" : "hidden"}>
        <button
          type="button"
          onClick={(e) => HandleDeleteForm(name)}
          className="py-1 px-3  text-right rounded red text-white text-sm"
        >
          Remove
        </button>
      </div>
    </div>
  );
};

export default ModuleComponent;
