import { MenuItem, Paper } from "@material-ui/core";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useRef, useState } from "react";

import Modal from "../../../components/Modal";
import FilterDropdown from "../../../components/MUTable/FilterDropdown";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import {
  roleStyling,
  statusStyling,
} from "../../../components/StylingFunctions";
import UserInventoryReport from "./UserInventoryReport";
import UserLateReport from "./UserLateReport";
import ReactDatePicker from "react-datepicker";

const UserReports = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [error, setError] = useState("");

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  const columns = [
    // {
    //   title: "",
    //   field: "photo",
    //   filtering: false,
    //   render: (rowData) => (
    //     <Image
    //       src={
    //         rowData.photo === "no-photo.jpg"
    //           ? profile
    //           : `${process.env.REACT_APP_BASEURL}/uploads/profile/${rowData.photo}`
    //       }
    //       className="h-10 rounded-full"
    //     />
    //   ),
    // },
    {
      title: "NAME",
      field: "username",
      filtering: false,
      render: (rowData) => (
        <>
          <p className="font-bold text-base">{rowData.username}</p>
          <p className="font-normal">{rowData.email}</p>
        </>
      ),
    },
    // { title: "EMAIL", field: "email", filtering: false },
    {
      title: "ROLE",
      field: "role",
      filtering: false,
      render: (rowData) => (
        <div style={roleStyling(rowData.role)}>{rowData.role}</div>
      ),
    },
    { title: "USER ID", field: "test", filtering: false },
    // { title: "Causal Leaves", field: "casualLeaves", filtering: false },
    // { title: "Sick Leaves", field: "sickLeaves", filtering: false },
    {
      title: "DESIGNATION",
      field: "designation",
      filtering: false,
    },

    {
      title: "STATUS",
      field: "status",
      //  filterComponent: (props) => (
      filterComponent: (props) => (
        <FilterDropdown data={props}>
          <MenuItem value={"Active"}>Active</MenuItem>
          <MenuItem value={"Inactive"}>Inactive</MenuItem>
        </FilterDropdown>
      ),
      render: (rowData) => (
        <div style={statusStyling(rowData.status)}>{rowData.status}</div>
      ),
    },
    {
      filtering: false,
      render: (rowData) => (
        <UserInventoryReport username={rowData.username} id={rowData._id} />
      ),
    },
    {
      filtering: false,
      render: (rowData) => (
        <UserLateReport
          username={rowData.username}
          id={rowData._id}
          date={selectedDate}
        />
      ),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-col  gap-6 mb-4 md:absolute z-10 right-10">
          <ReactDatePicker
            selected={selectedDate}
            onChange={(date) => {
              setSelectedDate(date);
            }}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            placeholderText="Select Month"
            className="input-calendar cursor-pointer"
          />
        </div>
        <div className="flex flex-col md:flex-row  gap-6 mb-4 md:absolute z-10 right-10"></div>
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            filtering: true,
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderBottom: "null",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          //   actions={[
          //     {
          //       icon: () => <EditButton />,
          //       onClick: (event, rowData) => {
          //         setEditForm(false);
          //       },
          //     },
          //     (rowData) => ({
          //       icon: () =>
          //         rowData.status === "Active" ? (
          //           <DeleteButton />
          //         ) : (
          //           <ReEnableButton />
          //         ),

          //       onClick: (event, rowData) => {},
          //     }),

          //     // (rowData) => ({
          //     //   icon: "delete",
          //     //   tooltip: "Delete User",
          //     //   onClick: (event, rowData) =>
          //     //     confirm("You want to delete " + rowData.name),
          //     //   disabled: rowData.birthYear < 2000,
          //     // }),
          //   ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };

              let url = `${process.env.REACT_APP_BASEURL}/api/v1/users?`;

              console.log(query);

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              // if (tableFilter.length) {
              //   url += `&status=${tableFilter}`;
              // }

              if (query.search) {
                url += "&username=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* Add User Modal */}
      <Modal
        show={modal}
        className=" w-4/5 md:w-3/5"
        close={() => {
          setModal(false);
        }}
      >
        <div>
          <p className="text-red-700 mt-2 text-left">{error}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 md:w-2/6 min-w-fit text-center self-center gap-4">
          <input
            onClick={() => setModal(false)}
            type="button"
            value="Cancel"
            className="red p-3  rounded-lg text-white cursor-pointer"
          />
          <input
            type="submit"
            value="Add"
            className="green p-3  rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal>

      {/* Edit User Modal */}
      <Modal
        show={editModal}
        className=" w-4/5 md:w-3/5"
        close={() => {
          setEditModal(false);
        }}
      >
        <div>
          <p className="text-red-700 mt-2 text-left">{error}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 md:w-2/6 min-w-fit text-center self-center gap-4">
          <input
            onClick={() => setModal(false)}
            type="button"
            value="Cancel"
            className="red p-3  rounded-lg text-white cursor-pointer"
          />
          <input
            type="submit"
            value="Add"
            className="green p-3  rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal>
    </main>
  );
};

export default UserReports;
