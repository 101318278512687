import { MdOutlineCheck, MdOutlineClose } from "react-icons/md";

const ModulesDisplay = ({ module, moduleName }) => {
  return (
    <>
      {module === true && (
        <div>
          <span className="flex flex-row gap-2 items-center">
            {moduleName} <MdOutlineCheck className="text-green-700" />
          </span>
        </div>
      )}

      {module === false && (
        <div>
          <span className="flex flex-row gap-2 items-center">
            {moduleName} <MdOutlineClose className="text-red-700" />
          </span>
        </div>
      )}
    </>
  );
};

export default ModulesDisplay;
