import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { MdListAlt } from "react-icons/md";
import CSVComponent from "../../../components/CSVComponent";
import ErrorDisplay from "../../../components/ErrorDisplay";
import PageLoader from "../../../components/PageLoader";
import {
  displayItemsPDF,
  formatDate,
  getFirstDay,
} from "../../../components/Functions";
import { getLastDay } from "../../../components/Functions";
import { getMonthYear } from "../../../components/Functions";

const SalarySlip = ({ date, id }) => {
  const [renderTable, setRenderTable] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(true);
  const [selectedUser, setSelectedUser] = useState({
    info: {
      deductionDays: 0,
      _id: "",
      username: "",
      officeId: {
        _id: "",
        name: "",
        location: "",
      },
      userId: {
        salary: 0,
        _id: "",
        designation: "",
        id: "",
      },
      date: "",
      __v: 0,
    },
    totalEarnings: null,
    deductions: {
      taxDeduction: null,
      absentDeduction: 0,
      totalDeductions: null,
    },
    netPay: null,
  });

  const doc = new jsPDF();

  const HandleRequest = (data) => {
    console.log(disabled);

    if (!disabled) {
      setDisabled(true);

      setError();
      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/salary/${id}`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then(async (res) => {
          console.log(res.data.data);
          const pdfPromise = await setSelectedUser(res.data.data);
          console.log(pdfPromise);

          try {
            GeneratePDF(res.data.data);
          } catch (error) {
            throw new Error(error);
          }

          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          setError(err.response.data.error);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "This may take a while",
        success: "Salary Generated",
        error: "An error has occurred",
      });
    }
  };

  const GeneratePDF = (data) => {
    setRenderTable(true);

    //config

    doc.setFontSize(18);
    doc.text(`${data.info.username}'s Salary Report`, 14, 20);

    doc.setFontSize(11);
    doc.text(getMonthYear(date), 14, 28);

    autoTable(doc, {
      html: "#table4",
      startY: 40,
      styles: {
        lineColor: [44, 62, 80],
        lineWidth: 0.01,
      },
      allSectionHooks: true,
      // Use for customizing texts or styles of specific cells after they have been formatted by this plugin.
      // This hook is called just before the column width and other features are computed.
      didParseCell: function (data) {
        if (data.row.index === 0) {
          data.cell.styles.fillColor = [46, 128, 186];
          data.cell.styles.textColor = [255, 255, 255];
        }
      },
    });

    autoTable(doc, {
      html: "#table",

      styles: {
        lineColor: [44, 62, 80],
        lineWidth: 0.01,
      },

      allSectionHooks: true,
      // Use for customizing texts or styles of specific cells after they have been formatted by this plugin.
      // This hook is called just before the column width and other features are computed.
      didParseCell: function (data) {
        if (data.row.index === 0) {
          data.cell.styles.fillColor = [46, 128, 186];
          data.cell.styles.textColor = [255, 255, 255];
        }
      },
    });

    autoTable(doc, {
      html: "#table2",
      styles: {
        lineColor: [44, 62, 80],
        lineWidth: 0.01,
      },
      allSectionHooks: true,
      // Use for customizing texts or styles of specific cells after they have been formatted by this plugin.
      // This hook is called just before the column width and other features are computed.
      didParseCell: function (data) {
        if (data.row.index === 0) {
          data.cell.styles.fillColor = [46, 128, 186];
          data.cell.styles.textColor = [255, 255, 255];
        }
      },
    });

    autoTable(doc, {
      html: "#table3",
      styles: {
        lineColor: [44, 62, 80],
        lineWidth: 0.01,
      },
      allSectionHooks: true,
      // Use for customizing texts or styles of specific cells after they have been formatted by this plugin.
      // This hook is called just before the column width and other features are computed.
      didParseCell: function (data) {
        if (data.row.index === 0) {
          data.cell.styles.fillColor = [46, 128, 186];
          data.cell.styles.textColor = [255, 255, 255];
        }
      },
    });

    var finalY = doc.lastAutoTable.finalY || 10;
    doc.setFontSize(11);
    doc.text(`Net Pay ${data.netPay}`, 14, finalY + 10);

    // //signature line
    // doc.line(20, finalY + 30, 60, finalY + 30);

    doc.save(`${date} Monthly Salary Report`);
    setRenderTable(false);
  };

  if (loaded === false) {
    return <PageLoader />;
  } else
    return (
      <div>
        <div
          className="py-2 px-4 text-center text-white  primary rounded-lg cursor-pointer whitespace-nowrap"
          onClick={HandleRequest}
        >
          Generate Salary Slip
        </div>

        <ErrorDisplay>{error}</ErrorDisplay>
        {renderTable && (
          <div className="hidden">
            <table id="table4">
              <tr>
                <td colspan="2">Office Details</td>
              </tr>
              <tr>
                <th>Name</th>
                <th>{selectedUser.info.officeId.name}</th>
              </tr>

              <tr>
                <th>Location</th>
                <th>{selectedUser.info.officeId.location}</th>
              </tr>
            </table>

            <tr></tr>

            <br />

            {/* Employee Details */}
            <table id="table">
              <tr>
                <td colspan="4">Employee Details</td>
              </tr>
              <tr>
                <th>ID</th>
                <td>{selectedUser.info.userId.test}</td>
                <th>Name</th>
                <td>{selectedUser.info.username}</td>
              </tr>
              {/* <!-----2 row---> */}
              <tr>
                <th>Email</th>
                <td>{selectedUser.info.userId.email}</td>
                <th>Joining Date</th>
                <td>{formatDate(selectedUser.info.userId.JoiningDate)}</td>
              </tr>
              {/* <!------3 row----> */}
              <tr>
                <th>Department</th>
                <td>{selectedUser.info.userId.department}</td>
                <th>Designation</th>
                <td>{selectedUser.info.userId.designation}</td>
              </tr>
              {/* <!------4 row----> */}
              <tr>
                <th>Role</th>
                <td>{selectedUser.info.userId.role}</td>
                <th>Hours Worked</th>
                <td>{selectedUser.info.userId.hoursWorked}</td>
              </tr>
              {/* <!------5 row----> */}
              <tr>
                <th>NIC</th>
                <td>{selectedUser.info.userId.nic}</td>
                <th>Contact</th>
                <td>{selectedUser.info.userId.phone}</td>
              </tr>
            </table>

            <tr></tr>

            {/* Earnings */}
            <br />
            <table id="table2">
              <tr>
                <th>Earnings</th>
                <th>Amount</th>
                {/* <th>Deductions</th>
                <th>Amount</th> */}
              </tr>
              <tr>
                <td>Basic</td>
                <td>{selectedUser.info.userId.salary}</td>
              </tr>
              {selectedUser.info.userId.gradeId &&
                selectedUser.info.userId.gradeId.allowance.map(
                  ({ allowanceType, value }) => (
                    <tr>
                      <td>{allowanceType.name}</td>
                      <td>{value}</td>
                      {/* <td>Income tax</td>
              <td>500</td> */}
                    </tr>
                  )
                )}
              <tr>
                <th>Bonus</th>
                <td>
                  {selectedUser.info.bonus && selectedUser.info.bonus.bonus}
                </td>
              </tr>

              <tr>
                <th>Gross Earnings</th>
                <td>Rs. {selectedUser.totalEarnings}</td>
              </tr>
              {/* <tr>
                <td></td>
                <td>
                  <strong>NET PAY</strong>
                </td>
                <td>Rs.35500</td>
                <td></td>
              </tr> */}
            </table>

            <tr></tr>

            <br />

            {/* Dedections */}
            <table id="table3">
              <tr>
                <th>Deductions</th>
                <th>Amount</th>
                {/* <th>Deductions</th>
                <th>Amount</th> */}
              </tr>

              <tr>
                <td>Absent Deduction</td>
                <td>{selectedUser.deductions.absentDeduction}</td>
              </tr>

              <tr>
                <td>Task Deduction</td>
                <td>{selectedUser.deductions.taxDeduction}</td>
              </tr>

              <tr>
                <td>Gross Deductions</td>
                <td>{selectedUser.deductions.totalDeductions}</td>
              </tr>

              {/* <tr>
                <td></td>
                <td>
                  <strong>NET PAY</strong>
                </td>
                <td>Rs.35500</td>
                <td></td>
              </tr> */}
            </table>
          </div>
        )}
      </div>
    );
};

export default SalarySlip;
