import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ImCross } from "react-icons/im";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MdOutlineClose } from "react-icons/md";

import Image from "../Image";
import AddMember from "../../images/add_member.svg";
import PageLoader from "../PageLoader";

import FormReactMultiSelectTask from "../ProjectView/FormReactMultiSelectTask";
import ErrorDisplay from "../ErrorDisplay";
import FormReactMultiSelect from "../FormReactMultiSelect";

const ManageUsers = ({ prefix, editable, projectId }) => {
  const [disabled, setDisabled] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [smallModal, setSmallModel] = useState(false);
  const [users, setUsers] = useState();
  const [error, setError] = useState("");

  //For Users to assign
  const [customSelectValue, setCustomSelectValue] = useState();
  const [userImage, setUserImage] = useState([]);

  //For form
  const methods = useForm({
    // resolver: yupResolver(userSchema),
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = () => {
    if (!disabled) {
      setDisabled(true);

      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/project/${projectId}`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res.data.data);
          setUsers(res.data.data.project_members);

          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          return Promise.reject();
        });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Data loaded",
      //   error: "An error has occurred",
      // });
    }
  };

  const removeUser = (_id) => {
    const project_members = [_id];
    console.log("MEMBER ID " + _id);
    console.log("PROJECT ID " + projectId);

    const data = {
      project_members: project_members,
    };

    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/project/${projectId}/removeMember`,
        method: `PUT`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          getInfo();
          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });
    }
  };

  const HandleForm = (data) => {
    console.log(data);
    console.log(customSelectValue);

    if (!disabled) {
      setDisabled(true);

      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/project/${projectId}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setCustomSelectValue(null);
          setUserImage([]);
          getInfo();

          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);

          return Promise.reject();
        });
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4 ">
        <div className="flex flex-row gap-1 flex-wrap">
          {users &&
            users.map((users) => (
              <div key={users._id} className="relative p-2 ">
                {editable && (
                  <ImCross
                    onClick={() => {
                      removeUser(users._id);
                    }}
                    size={16}
                    className="red text-white p-1 rounded-full cursor-pointer absolute right-2 top-2 ml-auto mr-0"
                  />
                )}
                {console.log(users)}

                <Image
                  src={`${prefix}/${users.photo}`}
                  className="h-16 w-16 rounded-lg "
                  alt="upload"
                />

                <div className="mt-2 p-1  flex flex-col items-center overflow-clip">
                  <span className="font-thin w-24 text-ellipsis text-center whitespace-normal break-after-all">
                    {users.username}
                  </span>
                </div>
                {/* <button
              className="red text-white p-1.5 rounded font-thin text-xs mt-3 "
              type="button"
              onClick={() => deleteHandler(image)}
            >
              Remove
            </button> */}
              </div>
              //   <div className="">
              //     <ImCross
              //       size={16}
              //       className="red text-white p-1 rounded-full cursor-pointer ml-auto mr-"
              //     />
              //     <Image
              //       className="h-12 w-12"
              //       src={"www.random.com"}
              //       label={users.username}
              //     />
              //   </div>
            ))}
          {editable && (
            <div className="relative p-2 ">
              <Image
                src={AddMember}
                className="h-16 w-16 rounded-lg cursor-pointer hover:opacity-70"
                alt="upload"
                onClick={() => setSmallModel(true)}
              />

              <div className="mt-2 p-1  flex flex-col items-center overflow-clip">
                <span className="font-thin w-24 text-ellipsis text-center whitespace-normal break-after-all">
                  {"Add Project Members"}
                </span>
              </div>
            </div>
          )}
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className={
              smallModal
                ? "flex flex-col gap-6 justify-start align-center"
                : "hidden"
            }
          >
            <div className="flex flex-col gap-5 md:w-3/6">
              <div className="flex flex-row justify-between items-center gap-4">
                <h3 className="text-base">Add Project Members</h3>
                <MdOutlineClose
                  size={20}
                  className="text-gray-500 hover:opacity-70 cursor-pointer"
                  onClick={() => setSmallModel(false)}
                />
              </div>

              <FormReactMultiSelect
                apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/users?&status=Active`}
                searchCriteria={"username"}
                name={"project_members"}
                setCustomValue={setCustomSelectValue}
                customValue={customSelectValue}
                selectedImage={userImage}
                setSelectedImage={setUserImage}
                images={false}
              />

              <input
                type="submit"
                value={"Save"}
                className="green text-white rounded-lg p-2 w-28 min-w-fit cursor-pointer"
              />
            </div>
          </form>
        </FormProvider>
      </div>

      <ErrorDisplay>{error}</ErrorDisplay>

      {disabled && <PageLoader size={30} type="ThreeDots" />}
    </>
  );
};

export default ManageUsers;
