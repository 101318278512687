import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem, Paper } from "@material-ui/core";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { MdClose } from "react-icons/md";
import ErrorDisplay from "../../../components/ErrorDisplay";
import {
  convertToArrayDynamic,
  formatDate,
} from "../../../components/Functions";
import Modal from "../../../components/Modal";
import FilterDropdown from "../../../components/MUTable/FilterDropdown";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { votingStyling } from "../../../components/StylingFunctions";
import { userSchema } from "../../../components/Validation/Admin/AddVoting";
import ArrowButton from "./../../../components/MaterialTableButtons/ArrowButton";
import EditButton from "./../../../components/MaterialTableButtons/EditButton";

const Voting = (props) => {
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    voteName: "",
    startTime: "",
    endTime: "",
    date: "",
    _id: "",
    status: "",
    choices: [
      {
        value: "Undefined",
        votes: "0",
      },
    ],
    editedChoices: [
      {
        value: "Undefined",
        votes: "0",
      },
    ],
  });
  const [status, setStatus] = useState("Active");
  const [error, setError] = useState("");

  //For calculating total number of votes
  const totalVotes = selectedUser.choices.reduce((accumulator, object) => {
    return accumulator + object.votes;
  }, 0);

  // dynamically rendering input fields for polling modal
  const [formValues, setFormValues] = useState([{ value: "" }]);

  // let handleChange = (i, e, choices) => {
  //   let newSelections = selectedUser.choices;
  //   // console.log(newSelections);
  //   newSelections = newSelections[i][e.target.name] = e.target.value;
  //   // // selectedUser.choices[i][e.target.name] = e.target.value;
  //   setNewChoices(newSelections);
  // };

  let addFormFields = () => {
    setFormValues([...formValues, { value: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  //for today's time
  const getTodayDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    return today;
  };

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  //For form
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "choices",
  });

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== delete obj[k]);
    return obj;
  };

  const HandleForm = (data) => {
    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);

      //adding votes=0 because of a backend requirement :/
      newData.choices.forEach((object) => {
        object.votes = 0;
      });

      console.log(newData);

      // let formData = new FormData();

      // // appending data into formdata
      // Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/poll`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: newData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          reset();
          setAddModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          setError(err.response.data.error);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Poll Added ",
        error: "An error has occurred",
      });
    }
  };

  const HandleEditForm = (data) => {
    //Only updating choices
    let preValue = convertToArrayDynamic(selectedUser.choices, "value");
    console.log(preValue);

    let value = convertToArrayDynamic(data.choices, "value");
    console.log(value);

    delete data.choices;

    data = {
      ...data,
      preValue: preValue,
      value: value,
    };

    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);

      // // Adding edited polling array
      // data["choices"] = selectedUser.choices;

      //Adding today's date
      // if (data["startTime"]) {
      //   const updatedStartTime = `${getTodayDate()}T${data["startTime"]}`;
      //   data["startTime"] = updatedStartTime;
      // }

      // if (data["endTime"]) {
      //   const updatedEndTime = `${getTodayDate()}T${data["endTime"]}`;
      //   data["endTime"] = updatedEndTime;
      // }

      console.log(data);
      // let formData = new FormData();

      // appending data into formdata
      // Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/poll/updatePoll/${selectedUser._id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: newData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          console.log(res.error);
          setDisabled(false);
          reset();
          setEditModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          setError(err.response.data.error);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Poll Updated ",
        error: "An error has occurred",
      });
    }
  };

  const abortVoting = () => {
    if (!disabled) {
      setDisabled(true);
      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/deactivate/${selectedUser._id}`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          setModal(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Leave request status updated",
        error: "An error has occurred",
      });
    }
  };

  const viewComplaint = (rowData) => {
    setSelectedUser(rowData);
    setModal(true);
  };

  const editUser = (rowData) => {
    setEditForm(false);
    setError();
    setSelectedUser(rowData);
    reset({
      voteName: rowData.voteName,
      startTime: rowData.startTime,
      endTime: rowData.endTime,
      date: formatDate(rowData.date),
      choices: rowData.choices,
    });
    if (rowData.status === "Scheduled") setEditModal(true);
    else setModal(true);
  };

  const columns = [
    // {
    //   title: "",
    //   field: "image",
    //   filtering: false,
    //   render: (rowData) => (
    //     <img
    //       src={
    //         rowData.image === "no-image.jpg"
    //           ? profile
    //           : `${process.env.REACT_APP_BASEURL}/uploads/offices/${rowData.image}`
    //       }
    //       className="w-12 rounded-full"
    //     />
    //   ),
    // },
    { title: "POLL NAME", field: "voteName", filtering: false },
    {
      title: "VOTING START TIME",
      filtering: false,
      field: "startTime",
    },
    {
      title: "VOTING END TIME",
      filtering: false,
      field: "endTime",
    },
    {
      title: "VOTING DATE",
      filtering: false,
      field: "date",
      render: (rowData) => formatDate(rowData.date),
    },
    {
      title: "STATUS",
      field: "status",
      filterComponent: (props) => (
        <FilterDropdown data={props}>
          <MenuItem value={"Active"}>Active</MenuItem>
          <MenuItem value={"Inactive"}>Inactive</MenuItem>
        </FilterDropdown>
      ),
      filtering: false,
      render: (rowData) => (
        <div style={votingStyling(rowData.status)}>{rowData.status}</div>
      ),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-col  gap-6 mb-4 md:absolute z-10 right-10">
          <button
            onClick={() => {
              setError();
              setAddModal(true);
            }}
            className="py-4 px-10 green text-white font-thin rounded-xl whitespace-nowrap"
          >
            Add Voting
          </button>
        </div>
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            // filtering: true,
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderBottom: "null",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          actions={[
            (rowData) => ({
              icon: () =>
                rowData.status === "Scheduled" ? (
                  <EditButton />
                ) : (
                  <ArrowButton />
                ),

              onClick: (event, rowData) => {
                reset({});
                editUser(rowData);

                // deleteUser(rowData);
              },
            }),

            // (rowData) => ({
            //   icon: "delete",
            //   tooltip: "Delete User",
            //   onClick: (event, rowData) =>
            //     confirm("You want to delete " + rowData.name),
            //   disabled: rowData.birthYear < 2000,
            // }),
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${process.env.REACT_APP_BASEURL}/api/v1/poll?`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              if (query.search) {
                url += "&voteName=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* View Poll */}
      <Modal
        className="w-4/5  md:w-3/5 h-3/4 h-fit"
        show={modal}
        close={() => {
          setModal(false);
        }}
      >
        <div className="flex flex-col gap-3 text-center items-center">
          <h2 className="text-xl font-bold">{selectedUser.voteName}</h2>
          <p style={votingStyling(selectedUser.status)}>
            {selectedUser.status}
          </p>
          <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-4 mt-2">
            {selectedUser.choices.map((choices) => (
              <ProgressBar
                title={choices.value}
                totalVotes={totalVotes}
                votes={choices.votes}
              />
            ))}
          </div>

          <ErrorDisplay>{error}</ErrorDisplay>

          <div
            className={
              selectedUser.status !== "Completed"
                ? "flex flex-row mt-6 md:justify-center gap-3"
                : "hidden"
            }
          >
            <button
              onClick={() => setModal(false)}
              className="grey text-white rounded-lg h-10 w-32"
              type="button"
            >
              Cancel
            </button>
            <button
              onClick={abortVoting}
              className="red text-white rounded-lg h-10 w-32"
              type="button"
            >
              End Voting
            </button>
          </div>
        </div>
      </Modal>

      {/* Add Voting Modal */}
      <Modal
        className="max-w-screen-md"
        show={addModal}
        close={() => {
          setAddModal(false);
        }}
      >
        <form
          onSubmit={handleSubmit(HandleForm)}
          className="flex flex-col gap-6 justify-center align-center"
        >
          <div>
            <div>
              <h3 className="font-bold">Voting Name</h3>
              <input
                type="text"
                required
                className="input-form mt-3 w-full"
                {...register("voteName")}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-3">
            <div>
              <h3 className="font-bold">Voting Start Time</h3>
              <input
                type="time"
                required
                min={getTodayDate()}
                className="input-form mt-3"
                {...register("startTime")}
              />
            </div>
            <div>
              <h3 className="font-bold">Voting End Time</h3>
              <input
                type="time"
                required
                className="input-form mt-3"
                {...register("endTime")}
              />
            </div>
            <div>
              <h3 className="font-bold">Start Poll on:</h3>
              <input
                type="date"
                required
                className="input-form mt-3"
                {...register("date")}
              />
            </div>
          </div>

          <div>
            <div>
              <h3 className="font-bold">Polling Options</h3>

              <div className="flex flex-row gap-3 flex-start flex-wrap mt-3">
                {/* Dynamically rendering input fields */}

                {fields.map(({ id, value }, index) => (
                  <div className="relative" key={id}>
                    <input
                      type="text"
                      required
                      className="input-form-x"
                      name="value"
                      defaultValue={value}
                      placeholder={`Option ${index + 1}`}
                      // value={element.value || ""}
                      // onChange={(e) => handleChange(index, e)}
                      {...register(`choices.${index}.value`)}
                    />

                    {index ? (
                      <MdClose
                        type="button"
                        className="absolute right-3 top-3.5 cursor-pointer"
                        onClick={() => remove(index)}
                      />
                    ) : null}
                  </div>
                ))}

                <button
                  className="input-form  bg-gray-400 border-dashed border-2"
                  type="button"
                  onClick={() => append({ value: "" })}
                >
                  Add Option
                </button>
              </div>
              <p className="mt-2 text-red-600">{errors.choices?.message}</p>
            </div>
          </div>

          <ErrorDisplay>{error}</ErrorDisplay>

          <div className="grid grid-cols-2 gap-4">
            <input
              onClick={() => setAddModal(false)}
              type="button"
              value="Cancel"
              className="red p-3  rounded-lg text-white cursor-pointer"
            />
            <input
              type="submit"
              value="Add"
              className="green p-3  rounded-lg text-white cursor-pointer"
            />
          </div>
        </form>
      </Modal>

      {/* Edit Voting Modal */}
      <Modal
        className="max-w-screen-md"
        show={editModal}
        close={() => {
          setEditModal(false);
        }}
      >
        <form
          onSubmit={handleSubmit(HandleEditForm)}
          className="flex flex-col gap-6 justify-center align-center"
        >
          <div>
            <h3 className="font-bold">Voting Name</h3>
            <input
              type="text"
              disabled={!editForm}
              className="input-form mt-3"
              {...register("voteName")}
            />
          </div>

          <div className="flex flex-col md:flex-row gap-3">
            <div>
              <h3 className="font-bold">Voting Start Time</h3>
              <input
                type="time"
                disabled={!editForm}
                className="input-form mt-3"
                {...register("startTime")}
              />
            </div>
            <div>
              <h3 className="font-bold">Voting End Time</h3>
              <input
                type="time"
                disabled={!editForm}
                className="input-form mt-3"
                {...register("endTime")}
              />
            </div>
            <div>
              <h3 className="font-bold">Start Poll on:</h3>
              <input
                type={"date"}
                disabled={!editForm}
                className="input-form mt-3"
                {...register("date")}
              />
            </div>
          </div>

          <div>
            <div>
              <h3 className="font-bold">Polling Options</h3>

              <div className="flex flex-row gap-3 flex-start flex-wrap mt-3">
                {/* Dynamically rendering input fields */}

                {selectedUser.choices.map((choices, index) => (
                  <div className="relative" key={choices.id}>
                    <input
                      type="text"
                      className="input-form"
                      name="value"
                      disabled={!editForm}
                      placeholder={choices.value}
                      // onChange={(e) => {
                      //   handleChange(index, e, choices);
                      // }}
                      {...register(`choices.${index}.value`)}
                    />
                  </div>
                ))}
              </div>
              <p className="mt-2 text-red-600">{errors.choices?.message}</p>
            </div>
          </div>

          <ErrorDisplay>{error}</ErrorDisplay>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              onClick={() => setEditModal(false)}
              type="button"
              value="Cancel"
              className="red p-3  rounded-lg text-white cursor-pointer"
            />
            {editForm === true ? (
              <input
                type={"submit"}
                value={"Save Changes"}
                className="green p-3  rounded-lg text-white cursor-pointer"
              />
            ) : (
              <button
                onClick={() => setEditForm(true)}
                type={"button"}
                className="green p-3  rounded-lg text-white cursor-pointer"
              >
                Edit{" "}
              </button>
            )}
          </div>
        </form>
      </Modal>
    </main>
  );
};

export default Voting;
