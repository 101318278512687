import { FormProvider, useForm } from "react-hook-form";
import Question from "../../../components/TalentGame/Question";
import { useState, useEffect } from "react";
import axios from "axios";
import PageLoader from "../../../components/PageLoader";
import { convertToArrayDynamic } from "../../../components/Functions";
import { toast } from "react-hot-toast";
import ErrorDisplay from "../../../components/ErrorDisplay";
import logo from "../../../images/insignia_logo.svg";
import { Link, useNavigate } from "react-router-dom";
import MyProfile from "../../../components/RightNavbar/Submenus/MyProfile";

const TalentGame = () => {
  const [quizData, setQuizData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [answers, setAnswers] = useState({});
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  //For form
  const methods = useForm({
    // resolver: yupResolver(userSchema),
    // mode: "all",
  });
  const {
    handleSubmit: handleMainSubmit,
    formState: { errors, isValid },
  } = methods;

  //The below logic is for handling the quiz's response
  //All this logic is due to how the API is accepting these answers
  const onSubmitFunctions = [];

  //Final submit function
  const createHandleSubmitFunction = (name) => {
    return (data) => {
      // console.log(`${name} submitted: `, data);
      // var array = Object.keys(answers).map(function (k) {
      //   return answers[k].data;
      // });
      // console.log(array);
      console.log(data);
      const candidate = localStorage.getItem("id");
      const questions = convertToArrayDynamic(quizData, "_id");

      let finalAnswers = [];
      for (let index = 0; index < quizData.length; index++) {
        finalAnswers.push(
          quizData[index].answers.find((o) => o._id === data[index + 1])
        );
      }

      // console.log("candidate", candidate);
      // console.log("questions", questions);
      // console.log("answers", finalAnswers);

      const finalData = {
        candidate: candidate,
        questions: questions,
        answers: finalAnswers,
      };

      console.log(finalData);

      if (!disabled) {
        setDisabled(true);
        setError();

        const promise = axios({
          // Endpoint to send files
          url: `${process.env.REACT_APP_BASEURL}/api/v1/talentgame/response`,
          method: "POST",
          headers: {
            // Add any auth token here
            // "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },

          // Attaching the form data
          data: finalData,
        })
          // Handle the response from backend here
          .then((res) => {
            console.log(res);

            setDisabled(false);
            navigate("/signup-wait");
            localStorage.clear();
          })

          // Catch errors if any
          .catch((err) => {
            setError(err.response.data.error);
            setDisabled(false);
            console.log(err);
            return Promise.reject();
          });

        toast.promise(promise, {
          loading: "Loading",
          success: "Response Submitted",
          error: "An error has occurred",
        });
      }
    };
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const id = e.target.getAttribute("_id");
    onSubmit({ [name]: value, [`_id`]: id });
  };

  quizData &&
    quizData.forEach((radioSet) => {
      const onSubmitFunction = createHandleSubmitFunction(radioSet._id);
      onSubmitFunctions.push(onSubmitFunction);
    });

  const getQuestionInfo = () => {
    if (!disabled) {
      setDisabled(true);

      setError();
      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/talentgame/question`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res.data);
          setQuizData(res.data.data);
          setLoaded(true);
          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          console.log(err);
          setError(err.response.data.error);
          return Promise.reject();
        });

      Promise.all([promise]).then((values) => {
        setLoaded(true);
      });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Data loaded",
      //   error: "An error has occurred",
      // });
    }
  };

  useEffect(() => {
    getQuestionInfo();
  }, []);

  const onSubmit = (data) => {
    // if(!([data._id] in answers)){
    //   setAnswers({
    //     ...answers,
    //     [data._id]: { data },
    //   });
    // } else {

    // }

    setAnswers({
      ...answers,
      [data._id]: { data },
    });

    console.log(answers);
  };

  if (loaded === false) {
    return (
      <div className="grid background-gradient h-screen w-full place-items-center overflow-auto py-4">
        <PageLoader />
      </div>
    );
  } else
    return (
      <div className="grid background-gradient h-screen w-full place-items-center overflow-auto py-8">
        <div className="flex flex-col md:flex-row gap-4 justify-between items-center w-full py-5 px-28 mb-16">
          <img className="h-16 " src={logo} alt="" />

          <div className="flex flex-col md:flex-row items-center gap-4">
            <span className="font-bold uppercase text-gray-600">
              {localStorage.getItem("username")}
            </span>
            <Link
              onClick={() => {
                localStorage.clear();
              }}
              to="/login"
              className="black text-white py-3 px-4 rounded-lg text-xs"
            >
              Sign out
            </Link>
          </div>
        </div>
        <div className="lg:w-3/5 md:h-4/6.no bg-white shadow-lg rounded-xl">
          {/* Header */}
          <div className="py-5 tracking-wide font-semibold text-xl mx-auto text-center text-white bg-custom-primary md:rounded-t-xl">
            <p>TALENT GAME</p>
          </div>

          <div className="py-9 px-12  ">
            <div className="grid grid-cols-1 justify-center text-left  self-center  gap-8">
              {quizData &&
                quizData.map((data, i) => (
                  <FormProvider {...methods}>
                    <form onSubmit={handleMainSubmit(onSubmitFunctions[i])}>
                      <Question
                        index={i + 1}
                        totalQuestions={quizData && quizData.length}
                        key={data._id}
                        question={data.question}
                        answers={data.answers}
                        handleInputChange={(e) =>
                          handleInputChange(e, onSubmitFunctions[i])
                        }
                      />
                      {i + 1 === quizData.length && (
                        <button
                          className="green mt-12 p-4 rounded-lg text-white"
                          type="submit"
                        >
                          Submit
                        </button>
                      )}
                    </form>
                  </FormProvider>
                ))}
            </div>
            <ErrorDisplay>{error}</ErrorDisplay>
          </div>
        </div>
      </div>
    );
};

export default TalentGame;
