import * as yup from "yup";

export const userSchema = yup.object().shape({
  name: yup.string(),
  location: yup.string(),
  phone: yup
    .string()
    .matches(
      /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm,
      "Inavlid contact format"
    ),
  email: yup.string().email("Invalid email format"),
  photo: yup.string(),
  files: yup.mixed(),
  password: yup.string(),
  leaveManagement: yup.bool(),
  rewardSystem: yup.bool(),
  voting: yup.bool(),
  attendance: yup.bool(),
  payroll: yup.bool(),
});
