import * as yup from "yup";

export const userSchema = yup.object().shape({
  taskDetail: yup
    .string("Please enter the task's name")
    .required("Task's name is required"),
  startDate: yup
    .string("Please enter a starting date")
    .required("Starting date is required"),
  endDate: yup
    .string("Please enter an ending date")
    .required("Ending date is required"),
  endTime: yup
    .string("Please enter an ending time")
    .required("Ending time is required"),
  description: yup.string(),
});
