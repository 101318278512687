import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem, Paper } from "@material-ui/core";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import Display_Supervisor from "../../../components/AddUser/display-supervisor";
import FileListDisplay from "../../../components/FileListDisplay";
import FormImageField from "../../../components/FormImageField";
import FormInputField from "../../../components/FormInputField";
import FormPasswordField from "../../../components/FormPasswordField";
import FormSelectField from "../../../components/FormSelectField";
import { formatDate } from "../../../components/Functions";
import InventoryListDisplay from "../../../components/InventoryListDisplay";
import DeleteButton from "../../../components/MaterialTableButtons/DeleteButton";
import EditButton from "../../../components/MaterialTableButtons/EditButton";
import ReEnableButton from "../../../components/MaterialTableButtons/ReEnableButton";
import Modal from "../../../components/Modal";
import FilterDropdown from "../../../components/MUTable/FilterDropdown";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import ResetPsComponent from "../../../components/ResetPsComponent";
import {
  roleStyling,
  statusStyling,
} from "../../../components/StylingFunctions";
import { userSchema } from "../../../components/Validation/Admin/AddUserSchema";
import FormReactSelect from "./../../../components/FormReactSelect";

const Users = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [role_check, setRole_check] = useState();
  const [disabled, setDisabled] = useState(false);
  const [idStatus, setIdStatus] = useState();
  const [supervisor, setSuperVisor] = useState([]);
  const [grade, setGrade] = useState([]);
  const [officeDetails, getOfficeDetails] = useState({
    customID: false,
  });
  const [editForm, setEditForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    username: "",
    status: "",
    designation: "",
    email: "",
    phone: "",
    JoiningDate: "",
    supervisedBy: {
      username: "",
      _id: "",
    },
    shiftId: {
      name: "",
      id: "",
    },
    _id: "",
    address: "",
    photo: "",
  });

  const [error, setError] = useState("");
  const [shifts, setShifts] = useState([]);

  //For filtering
  const [tableFilter, setTableFilter] = useState("Active");

  // For refreshing table
  const tableRef = useRef();
  const modalRef = useRef(null);

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== delete obj[k]);
    return obj;
  };

  const deleteUser = (rowData) => {
    setSelectedUser(rowData);
    setDeleteModal(true);
  };

  const editUser = (rowData) => {
    setSelectedUser(rowData);

    if (rowData.isGrade === true) {
      setIdStatus("grade");
      reset({
        username: rowData.username,
        email: rowData.email,
        nic: rowData.nic,
        phone: rowData.phone,
        dateOfBirth: formatDate(rowData.dateOfBirth),
        gender: rowData.gender,
        test: rowData.test,
        role: rowData.role,
        designation: rowData.designation,
        department: rowData.department,
        supervisedBy: rowData.supervisedBy && rowData.supervisedBy.id,
        shiftId: rowData.shiftId && rowData.shiftId.id,
        JoiningDate: formatDate(rowData.JoiningDate),
        emerg_name: rowData.emerg_name,
        emerg_contact: rowData.emerg_contact,
      });

      setEditModal(true);
    } else {
      setIdStatus("manual");
      reset({
        username: rowData.username,
        email: rowData.email,
        nic: rowData.nic,
        phone: rowData.phone,
        dateOfBirth: formatDate(rowData.dateOfBirth),
        gender: rowData.gender,
        test: rowData.test,
        role: rowData.role,
        designation: rowData.designation,
        department: rowData.department,
        supervisedBy: rowData.supervisedBy && rowData.supervisedBy.id,
        shiftId: rowData.shiftId && rowData.shiftId.id,
        JoiningDate: formatDate(rowData.JoiningDate),
        emerg_name: rowData.emerg_name,
        emerg_contact: rowData.emerg_contact,
      });

      setEditModal(true);
    }
    setEditModal(true);
  };

  //because backend completely removes the supervisedBy field when no supervisor is added
  const checkForSupervisor = (supervisor) => {
    if (supervisor.supervisedBy) {
      return supervisor.supervisedBy.username;
    } else return null;
  };

  const checkForShiftId = (shift) => {
    if (shift.shiftId) {
      return shift.shiftId.name;
    } else return null;
  };

  useEffect(() => {
    fetchingSupervisors();
    fetchingShifts();
    fetchingGrades();
    fetchingOfficeDetails();
  }, []); // <-- empty dependency array

  const fetchingOfficeDetails = () => {
    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/api/v1/auth/office`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res.data.data.office);
        getOfficeDetails(res.data.data.office);
      })

      // Catch errors if any
      .catch((err) => {
        console.log(err);
        return Promise.reject();
      });

    // toast.promise(promise, {
    //   loading: "Loading",
    //   success: "User Added ",
    //   error: "An error has occurred",
    // });
  };

  const fetchingGrades = () => {
    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/api/v1/grade?&status=Active`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
        setGrade(res.data.data);
      })

      // Catch errors if any
      .catch((err) => {
        return Promise.reject();
      });

    // toast.promise(promise, {
    //   loading: "Loading",
    //   success: "User Added ",
    //   error: "An error has occurred",
    // });
  };

  const fetchingSupervisors = () => {
    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/api/v1/users?role=supervisor&role=admin&status=Active`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
        setSuperVisor(res.data.data);
      })

      // Catch errors if any
      .catch((err) => {
        return Promise.reject();
      });

    // toast.promise(promise, {
    //   loading: "Loading",
    //   success: "User Added ",
    //   error: "An error has occurred",
    // });
  };

  const fetchingShifts = () => {
    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/api/v1/shift?&status=Active`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
        setShifts(res.data.data);
      })

      // Catch errors if any
      .catch((err) => {
        return Promise.reject();
      });

    // toast.promise(promise, {
    //   loading: "Loading",
    //   success: "User Added ",
    //   error: "An error has occurred",
    // });
  };

  //Adding user
  const HandleForm = (data) => {
    const file = data["file"];
    data["file"] = file[0];

    // console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/users`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          reset();
          setModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User Added ",
        error: "An error has occurred",
      });
    }
  };

  const HandleEditForm = (data) => {
    const file = data["file"];
    data["file"] = file[0];

    console.log(data);
    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/users/${selectedUser._id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          setEditForm(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);

          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User Updated ",
        error: "An error has occurred",
      });
    }
  };

  const HandleDeleteForm = () => {
    setDeleteModal(false);
    if (!disabled) {
      setDisabled(true);

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/users/${selectedUser._id}`,
        method: `${selectedUser.status === "Active" ? "DELETE" : "POST"}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User Updated ",
        error: "An error has occurred",
      });
    }
  };

  // function checkImage(url) {
  //   var request = new XMLHttpRequest();
  //   request.open("GET", url, true);
  //   request.send();
  //   request.onload = function () {
  //     status = request.status;
  //     if (request.status == 200) {
  //       //if(statusText == OK)
  //       console.log("image exists");
  //     } else {
  //       console.log("image doesn't exist");
  //     }
  //   };
  // }

  const columns = [
    // {
    //   title: "",
    //   field: "photo",
    //   filtering: false,
    //   render: (rowData) => (
    //     <Image
    //       src={
    //         rowData.photo === "no-photo.jpg"
    //           ? profile
    //           : `${process.env.REACT_APP_BASEURL}/uploads/profile/${rowData.photo}`
    //       }
    //       className="h-10 rounded-full"
    //     />
    //   ),
    // },
    {
      title: "NAME",
      field: "username",
      filtering: false,
      render: (rowData) => (
        <>
          <p className="font-bold text-base">{rowData.username}</p>
          <p className="font-normal">{rowData.email}</p>
        </>
      ),
    },
    // { title: "EMAIL", field: "email", filtering: false },
    {
      title: "ROLE",
      field: "role",
      filtering: false,
      render: (rowData) => (
        <div style={roleStyling(rowData.role)}>{rowData.role}</div>
      ),
    },
    { title: "USER ID", field: "test", filtering: false },
    // { title: "Causal Leaves", field: "casualLeaves", filtering: false },
    // { title: "Sick Leaves", field: "sickLeaves", filtering: false },
    {
      title: "CREATED DATE",
      field: "createAt",
      filtering: false,
      render: (rowData) => formatDate(rowData.createAt),
    },
    {
      title: "DESIGNATION",
      field: "designation",
      filtering: false,
    },
    {
      title: "COINS",
      field: "coins",
      filtering: false,
    },
    {
      title: "STATUS",
      field: "status",
      //  filterComponent: (props) => (
      filterComponent: (props) => (
        <FilterDropdown data={props}>
          
          <MenuItem value={"Active"}>Active</MenuItem>
          <MenuItem selected value={"Inactive"}>
            Inactive
          </MenuItem>
        </FilterDropdown>
        // <div className="w-36">
        //   <select
        //     className="filter-input-form text-base"

        //   >
        //     <option value={"Active"}>Active</option>
        //     <option value={"Inactive"}>Inactive</option>
        //   </select>
        // </div>
      ),
      render: (rowData) => (
        <div style={statusStyling(rowData.status)}>{rowData.status}</div>
      ),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-col md:flex-row  gap-6 mb-4 md:absolute z-10 right-10">
          {/* <select
            className="input-form"
            value={tableFilter}
            onChange={(e) => {
              setTableFilter(e.target.value);
              refreshTable();
            }}
          >
            <option value={"Active"}>Active</option>
            <option value={"Inactive"}>Inactive</option>
          </select> */}
          <button
            onClick={() => {
              setModal(true);
              reset({});
              setIdStatus();
            }}
            className="py-4 px-10 green text-white font-thin rounded-xl whitespace-nowrap"
          >
            Add User
          </button>
        </div>
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            filtering: true,
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderBottom: "null",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: () => <EditButton />,
              onClick: (event, rowData) => {
                reset();
                editUser(rowData);
                setEditForm(false);
              },
            },
            (rowData) => ({
              icon: () =>
                rowData.status === "Active" ? (
                  <DeleteButton />
                ) : (
                  <ReEnableButton />
                ),

              onClick: (event, rowData) => {
                reset();
                deleteUser(rowData);
              },
            }),

            // (rowData) => ({
            //   icon: "delete",
            //   tooltip: "Delete User",
            //   onClick: (event, rowData) =>
            //     confirm("You want to delete " + rowData.name),
            //   disabled: rowData.birthYear < 2000,
            // }),
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };

              let url = `${process.env.REACT_APP_BASEURL}/api/v1/users?`;

              console.log(query);

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              // if (tableFilter.length) {
              //   url += `&status=${tableFilter}`;
              // }

              if (query.search) {
                url += "&username=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* Add User Modal */}
      <Modal
        show={modal}
        className=" w-4/5 md:w-3/5"
        close={() => {
          setModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className="flex flex-col gap-6 justify-center align-center "
          >
            <FormImageField name={"file"} />

            <div>
              <h2 className="text-lg font-bold mb-2">Enter User Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormInputField
                  placeholder="Name*"
                  required
                  name={"username"}
                />

                <FormInputField
                  name={"email"}
                  required
                  type="email"
                  autocomplete="new-password"
                  placeholder="Email*"
                />

                <FormPasswordField
                  autocomplete="new-password"
                  required
                  placeholder="Password*"
                  name={"password"}
                />

                <FormInputField
                  name={"nic"}
                  required
                  type="number"
                  autocomplete="new-password"
                  placeholder="National Identity Number*"
                />

                <FormInputField
                  name={"phone"}
                  type="number"
                  required
                  placeholder="030X-XXXXXXX*"
                />

                <FormInputField
                  required
                  placeholder="Date of Birth"
                  type="text"
                  id="dateOfBirth"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                  name={"dateOfBirth"}
                  size="30"
                />

                <FormSelectField
                  name={"maritalStatus"}
                  type="text"
                  autocomplete="new-password"
                  required
                  placeholder="Marital Status*"
                >
                  <option value="" disabled selected>
                    Select Marital Status*
                  </option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                </FormSelectField>

                <FormSelectField
                  name={"gender"}
                  autocomplete="new-password"
                  placeholder="Gender*"
                  required
                >
                  <option value="" disabled selected>
                    Select Gender*
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </FormSelectField>
              </div>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-2">
                Enter Employment Details
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormSelectField
                  name="role"
                  onClick={(e) => {
                    setRole_check(e.target.value);
                    console.log(role_check);
                  }}
                  required
                >
                  <option value="" disabled selected>
                    Select Role*
                  </option>
                  <option value="employee">Employee</option>
                  <option value="admin">Admin</option>
                  <option value="supervisor">Supervisor</option>
                </FormSelectField>

                <FormInputField name={"department"} placeholder="Department" />

                <FormInputField
                  type="text"
                  required
                  placeholder="Designation"
                  name={"designation"}
                />

                {officeDetails.customID === true && (
                  <FormInputField
                    type="text"
                    required
                    disabled={!officeDetails.customID}
                    placeholder="USER ID*"
                    name={"test"}
                  />
                )}

                <FormReactSelect
                  apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/users?role=supervisor&role=admin&status=Active`}
                  searchCriteria={"username"}
                  isDisabled={role_check !== "admin" ? false : true}
                  placeholder={"Select Supervisor"}
                  name={"supervisedBy"}
                />
                {/* <FormSelectField
                  name="supervisedBy"
                  disabled={role_check !== "admin" ? false : true}
                >
                  <option value="" disabled selected>
                    Select Supervisor
                  </option>
                  <option value="">None</option>
                  {role_check !== "admin"
                    ? supervisor.map((supervisor) => (
                        <Display_Supervisor
                          name={supervisor.username}
                          _id={supervisor._id}
                        />
                      ))
                    : null}
                </FormSelectField> */}

                <FormReactSelect
                  apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/shift?&status=Active`}
                  searchCriteria={"shiftName"}
                  displayName={"name"}
                  // isDisabled={role_check !== "admin" ? false : true}
                  name={"shiftId"}
                  placeholder={"Select Shift*"}
                />

                <FormReactSelect
                  apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/leaveSetting?&status=Active`}
                  searchCriteria={"name"}
                  isDisabled={role_check !== "admin" ? false : true}
                  placeholder={"Select Leave Type"}
                  name={"leave"}
                />

                {/* <FormSelectField
                  name="shiftId"
                  disabled={role_check !== "admin" ? false : true}
                  // className={role_check !== "admin" ? "" : "hidden"}
                >
                  <option value="" disabled selected>
                    Select Shift*
                  </option>
                  {role_check !== "admin"
                    ? shifts.map((shifts) => (
                        <Display_Supervisor
                          name={shifts.name}
                          _id={shifts._id}
                        />
                      ))
                    : null}
                </FormSelectField> */}

                <FormInputField
                  required
                  placeholder="Joining Date"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                  id="JoiningDate"
                  name="JoiningDate"
                  size="30"
                />
              </div>

              {/* <FormInputField
                required
                placeholder="CustomID"
                disabled={localStorage.getItem("adminProf")}
                name="test"
              /> */}
            </div>

            <div className="w-full">
              <h2 className="text-lg font-bold mb-2">Salary Details</h2>

              <div className="flex flex-col gap-4">
                <div>
                  <div
                    className="flex flex-row justify-between form-check"
                    onChange={(e) => setIdStatus(e.target.value)}
                  >
                    <h4>Enter Salary Manually</h4>
                    <input
                      name="manual"
                      type="radio"
                      value={"manual"}
                      onChange={(e) => setIdStatus(e.target.value)}
                      className="cursor-pointer w-4"
                      {...register("salaryMethod")}
                    />
                  </div>
                </div>

                <div
                  className={
                    idStatus === "manual"
                      ? "grid grid-cols-1 sm:grid-cols-2 gap-3"
                      : "hidden"
                  }
                >
                  <FormInputField
                    placeholder="Base Salary (in PKR)*"
                    type="number"
                    disabled={idStatus === "manual" ? false : true}
                    required={idStatus === "manual" ? true : false}
                    name={"salary"}
                  />
                  <FormInputField
                    placeholder="Health Allowance*"
                    type="number"
                    disabled={idStatus === "manual" ? false : true}
                    required={idStatus === "manual" ? true : false}
                    name={"health_allowance"}
                  />
                  <FormInputField
                    placeholder="Fuel Allowance*"
                    type="number"
                    disabled={idStatus === "manual" ? false : true}
                    required={idStatus === "manual" ? true : false}
                    name={"fuel_allowance"}
                  />
                </div>

                <div>
                  <div
                    className="flex flex-row justify-between form-check"
                    onChange={(e) => setIdStatus(e.target.value)}
                  >
                    <h4>Assign a Grade</h4>
                    <input
                      name="manual"
                      type="radio"
                      value={"grade"}
                      onChange={(e) => setIdStatus(e.target.value)}
                      className="cursor-pointer w-4"
                      {...register("salaryMethod")}
                    />
                  </div>
                  <div>
                    <p className="text-red-700 mt-2 text-left">
                      {errors.salaryMethod?.message}
                    </p>
                  </div>
                </div>
                <div className={idStatus === "grade" ? "" : "hidden"}>
                  <FormReactSelect
                    apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/grade?&status=Active`}
                    searchCriteria={"name"}
                    isDisabled={idStatus === "grade" ? false : true}
                    name={"gradeId"}
                    placeholder={"Select Grade*"}
                  />
                </div>
              </div>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-2">
                Enter Emergency Details
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormInputField
                  name={"emerg_name"}
                  required
                  placeholder="Enter Name*"
                />
                <FormInputField
                  name={"emerg_contact"}
                  type="number"
                  required
                  placeholder="030X-XXXXXXX*"
                />
              </div>
            </div>

            <div>
              <p className="text-red-700 mt-2 text-left">{error}</p>
            </div>

            {console.log(errors)}

            <div className="grid grid-cols-1 md:grid-cols-2 md:w-2/6 min-w-fit text-center self-center gap-4">
              <input
                onClick={() => setModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              <input
                type="submit"
                value="Add"
                className="green p-3  rounded-lg text-white cursor-pointer"
              />
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Edit User Modal */}
      <Modal
        show={editModal}
        className=" w-4/5 md:w-3/5"
        close={() => {
          setEditModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleEditForm)}
            className="flex flex-col gap-6 justify-center align-center "
          >
            <FormImageField
              name={"file"}
              edit={editForm}
              value={selectedUser.photo}
              prefix={`${process.env.REACT_APP_IMAGE_PROFILE}`}
            />

            <div>
              <h2 className="text-lg font-bold mb-2">User Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormInputField
                  placeholder="Name*"
                  disabled={!editForm}
                  defaultValue={selectedUser.username}
                  name={"username"}
                />

                <FormInputField
                  name={"email"}
                  type="email"
                  disabled={!editForm}
                  defaultValue={selectedUser.email}
                  autocomplete="new-password"
                  placeholder="Email*"
                />

                {/* <FormInputField
                  type="password"
                  autocomplete="new-password"
                  required
                  disabled={!editForm}
                  placeholder="Password*"
                  name={"password"}
                /> */}

                <FormInputField
                  name={"nic"}
                  type="number"
                  disabled={!editForm}
                  defaultValue={selectedUser.nic}
                  autocomplete="new-password"
                  placeholder="National Identity Number*"
                />

                <FormInputField
                  name={"phone"}
                  type="number"
                  disabled={!editForm}
                  defaultValue={selectedUser.phone}
                  required
                  placeholder="030X-XXXXXXX*"
                />

                <FormInputField
                  required
                  placeholder="Date of Birth"
                  type="date"
                  disabled={!editForm}
                  id="dateOfBirth"
                  name={"dateOfBirth"}
                  size="30"
                />

                <FormSelectField
                  name={"maritalStatus"}
                  type="text"
                  autocomplete="new-password"
                  placeholder="Marital Status*"
                  disabled={!editForm}
                >
                  <option
                    defaultValue={selectedUser.maritalStatus}
                    disabled
                    selected
                  >
                    {selectedUser.maritalStatus}
                  </option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                </FormSelectField>

                <FormSelectField
                  name={"gender"}
                  autocomplete="new-password"
                  disabled={!editForm}
                >
                  <option defaultValue={selectedUser.gender} disabled selected>
                    {selectedUser.gender}
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </FormSelectField>

                <FormInputField
                  disabled
                  defaultValue={selectedUser.test}
                  placeholder="UserID"
                  name="test"
                />
              </div>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-2">Employment Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormSelectField
                  name="role"
                  onClick={(e) => {
                    setRole_check(e.target.value);
                    console.log(role_check);
                  }}
                  disabled={!editForm}
                  required
                >
                  <option defaultValue={selectedUser.role} disabled selected>
                    {selectedUser.role}
                  </option>
                  <option value="employee">Employee</option>
                  <option value="admin">Admin</option>
                  <option value="supervisor">Supervisor</option>
                </FormSelectField>

                <FormInputField
                  name={"department"}
                  disabled={!editForm}
                  defaultValue={selectedUser.department}
                  placeholder="Department*"
                />

                <FormInputField
                  type="text"
                  required
                  disabled={!editForm}
                  defaultValue={selectedUser.designation}
                  placeholder="Designation*"
                  name={"designation"}
                />

                <FormSelectField name="supervisedBy" disabled={!editForm}>
                  <option
                    defaultValue={checkForSupervisor(selectedUser)}
                    disabled
                    selected
                  >
                    {checkForSupervisor(selectedUser)}
                  </option>
                  <option value="">None</option>
                  {role_check !== "admin"
                    ? supervisor.map((supervisor) => (
                        <Display_Supervisor
                          name={supervisor.username}
                          _id={supervisor._id}
                        />
                      ))
                    : null}
                </FormSelectField>

                <FormReactSelect
                  apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/leaveSetting?&status=Active`}
                  searchCriteria={"name"}
                  isDisabled={!editForm}
                  placeholder={selectedUser.leave && selectedUser.leave.name}
                  name={"leave"}
                />

                <FormSelectField
                  name="shiftId"
                  disabled={!editForm}
                  className={role_check !== "admin" ? "" : "hidden"}
                >
                  <option
                    defaultValue={checkForShiftId(selectedUser)}
                    disabled
                    selected
                  >
                    {checkForShiftId(selectedUser)}
                  </option>
                  {role_check !== "admin"
                    ? shifts.map((shifts) => (
                        <Display_Supervisor
                          name={shifts.name}
                          _id={shifts._id}
                        />
                      ))
                    : null}
                </FormSelectField>

                <FormInputField
                  required
                  placeholder="Joining Date"
                  type="date"
                  disabled={!editForm}
                  id="JoiningDate"
                  name="JoiningDate"
                  size="30"
                />
              </div>
            </div>

            {selectedUser.inventoryItem &&
              selectedUser.inventoryItem.length !== 0 && (
                <div>
                  <h2 className="text-lg font-bold mb-2">Assigned Items</h2>
                  <div>
                    {selectedUser.inventoryItem &&
                      selectedUser.inventoryItem.map(({ photo, name, _id }) => (
                        <InventoryListDisplay
                          name={name}
                          src={photo}
                          key={_id}
                        />
                      ))}
                  </div>
                  <button type="button"></button>
                </div>
              )}

            {selectedUser.files && selectedUser.files.length !== 0 && (
              <div className="w-full lg:w-2/4">
                <h2 className="text-lg font-bold mb-2">File Records</h2>
                <div className="flex flex-col gap-8">
                  {selectedUser.files &&
                    selectedUser.files.map(({ name, docURL, _id }) => (
                      <FileListDisplay name={name} docURL={docURL} key={_id} />
                    ))}
                </div>
                <button type="button"></button>
              </div>
            )}

            <div className="w-full">
              <h2 className="text-lg font-bold mb-2">Salary Details</h2>
              <p class="mt-1 mb-4 text-sm text-gray-500" id="file_input_help">
                To edit salary assignment method, navigate to the Grades tab
                under Payroll.
              </p>

              <div className="flex flex-col gap-4">
                <div>
                  <div
                    className="flex flex-row justify-between form-check"
                    onChange={(e) => setIdStatus(e.target.value)}
                  >
                    <h4>Salary</h4>
                    <input
                      name="manual"
                      type="radio"
                      disabled
                      defaultChecked={!selectedUser.isGrade}
                      value={"manual"}
                      onChange={(e) => setIdStatus(e.target.value)}
                      className="cursor-pointer w-4"
                    />
                  </div>
                </div>

                <div
                  className={
                    selectedUser.isGrade
                      ? "hidden"
                      : "grid grid-cols-1 sm:grid-cols-2 gap-3"
                  }
                >
                  <FormInputField
                    placeholder="Salary Amount (in PKR)"
                    type="number"
                    defaultValue={selectedUser.salary}
                    disabled
                    name={"salary"}
                  />
                  <FormInputField
                    placeholder="Health Allowance"
                    type="number"
                    defaultValue={selectedUser.health_allowance}
                    disabled
                    name={"health_allowance"}
                  />
                  <FormInputField
                    placeholder="Fuel Allowance"
                    type="number"
                    defaultValue={selectedUser.fuel_allowance}
                    disabled
                    name={"fuel_allowance"}
                  />
                </div>

                <div>
                  <div
                    className="flex flex-row justify-between form-check"
                    onChange={(e) => setIdStatus(e.target.value)}
                  >
                    <h4>Grade</h4>
                    <input
                      name="manual"
                      type="radio"
                      disabled
                      defaultChecked={selectedUser.isGrade}
                      value={"grade"}
                      onChange={(e) => setIdStatus(e.target.value)}
                      className="cursor-pointer w-4"
                    />
                  </div>
                  {/* <p>
                    No automated ID will be provided for your users and you will
                    have to manually enter a custom ID for each user.
                  </p> */}
                </div>
                <div
                  className={
                    selectedUser.isGrade
                      ? "grid grid-cols-1 sm:grid-cols-2 gap-3"
                      : "hidden"
                  }
                >
                  <FormSelectField name="gradeId" disabled>
                    <option
                      value={selectedUser.gradeId && selectedUser.gradeId._id}
                      disabled
                      selected
                    >
                      {selectedUser.gradeId && selectedUser.gradeId.name}
                    </option>
                    <option value="">None</option>
                    {grade.map((grade) => (
                      <Display_Supervisor name={grade.name} _id={grade._id} />
                    ))}
                  </FormSelectField>
                </div>
              </div>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-2">Emergency Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormInputField
                  name={"emerg_name"}
                  disabled={!editForm}
                  defaultValue={selectedUser.emerg_name}
                  placeholder="Enter Name"
                />
                <FormInputField
                  name={"emerg_contact"}
                  type="number"
                  disabled={!editForm}
                  defaultValue={selectedUser.emerg_contact}
                  placeholder="Enter Contact"
                />
              </div>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-2">Reset Password</h2>
              <div className="w-full md:w-2/4 min-w-fit">
                <ResetPsComponent id={selectedUser._id} />
              </div>
            </div>

            <div>
              <p className="text-red-700 mt-2 text-left">{error}</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 md:w-2/6 min-w-fit text-center self-center gap-4">
              <input
                onClick={() => setEditModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              {editForm === true ? (
                <input
                  type={"submit"}
                  value={"Save Changes"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                />
              ) : (
                <button
                  onClick={() => setEditForm(true)}
                  type={"button"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                >
                  Edit{" "}
                </button>
              )}
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Delete User Modal */}
      <Modal
        show={deleteModal}
        close={() => {
          setDeleteModal(false);
        }}
      >
        <h2 className="text-lg">
          Are you sure you want to <br />
          {selectedUser.status == "Active" ? "deactivate " : "activate "}
          {selectedUser.username}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
          <input
            onClick={() => setDeleteModal(false)}
            type="button"
            value="Cancel"
            className="grey p-3 rounded-lg text-white cursor-pointer"
          />
          <input
            type="button"
            onClick={() => HandleDeleteForm()}
            value={selectedUser.status == "Active" ? "Deactivate" : "Activate"}
            className="red p-3 rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal>
    </main>
  );
};

export default Users;
