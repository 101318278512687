import { Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import React, { useState, useRef, useReducer, useEffect } from "react";
import { userSchema } from "../../../components/Validation/Admin/AddUserSchema";
import { useForm, FormProvider } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import { formatDate } from "../../../components/Functions";
import EditButton from "../../../components/MaterialTableButtons/EditButton";
import { useNavigate } from "react-router-dom";

const AppraisalFormList = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const [error, setError] = useState("");
  const navigate = useNavigate();

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== delete obj[k]);
    return obj;
  };

  const deleteUser = (rowData) => {
    setSelectedUser(rowData);
    setDeleteModal(true);
  };

  const editUser = (rowData) => {
    setSelectedUser(rowData);

    if (rowData.isGrade === true) {
      reset({
        username: rowData.username,
        email: rowData.email,
        nic: rowData.nic,
        phone: rowData.phone,
        dateOfBirth: formatDate(rowData.dateOfBirth),
        gender: rowData.gender,
        test: rowData.test,
        role: rowData.role,
        designation: rowData.designation,
        department: rowData.department,
        supervisedBy: rowData.supervisedBy && rowData.supervisedBy.id,
        shiftId: rowData.shiftId && rowData.shiftId.id,
        JoiningDate: formatDate(rowData.JoiningDate),
        emerg_name: rowData.emerg_name,
        emerg_contact: rowData.emerg_contact,
      });

      setEditModal(true);
    } else {
      reset({
        username: rowData.username,
        email: rowData.email,
        nic: rowData.nic,
        phone: rowData.phone,
        dateOfBirth: formatDate(rowData.dateOfBirth),
        gender: rowData.gender,
        test: rowData.test,
        role: rowData.role,
        designation: rowData.designation,
        department: rowData.department,
        supervisedBy: rowData.supervisedBy && rowData.supervisedBy.id,
        shiftId: rowData.shiftId && rowData.shiftId.id,
        JoiningDate: formatDate(rowData.JoiningDate),
        emerg_name: rowData.emerg_name,
        emerg_contact: rowData.emerg_contact,
      });

      setEditModal(true);
    }
    setEditModal(true);
  };

  //Adding user
  const HandleForm = (data) => {
    const file = data["file"];
    data["file"] = file[0];

    // console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/users`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          reset();
          setModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User Added ",
        error: "An error has occurred",
      });
    }
  };

  const HandleEditForm = (data) => {
    const file = data["file"];
    data["file"] = file[0];

    console.log(data);
    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/appraisal/appraisalResponse/${selectedUser._id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          setEditForm(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);

          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User Updated ",
        error: "An error has occurred",
      });
    }
  };

  const HandleDeleteForm = () => {
    setDeleteModal(false);
    if (!disabled) {
      setDisabled(true);

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/appraisal/appraisalResponse/${selectedUser._id}`,
        method: `${selectedUser.status === "Active" ? "DELETE" : "POST"}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User updated",
        error: `Error: ${error}`,
      });
    }
  };

  const handleAppraisal = (rowData) => {
    var data = {};
    data["appraisalResponse"] = [rowData._id];
    if (rowData.employee) {
      data["employeeId"] = [rowData.employee._id];
    } else data["employeeId"] = rowData.employee;

    console.log(data);

    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/api/v1/appraisal/appraisalResponse/applyIncrement`,
      method: `POST`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
        setDisabled(false);
        refreshTable();
      })

      // Catch errors if any
      .catch((err) => {
        setError(err.response.data.error);
        setDisabled(false);
        return Promise.reject();
      });

    toast.promise(promise, {
      loading: "Loading",
      success: "Appraisal Applied",
      error: `Error: ${error}`,
    });
  };

  const columns = [
    {
      title: "NAME",
      field: "employeename",
      filtering: false,
      render: (rowData) => (
        <>
          <p className="font-bold text-base">{rowData.employeename}</p>
          <p>{rowData.employee && rowData.employee.email}</p>
        </>
      ),
    },
    // { title: "EMAIL", field: "email", filtering: false },
    {
      title: "DESIGNATION",
      field: "employee",
      filtering: false,
      render: (rowData) => rowData.employee && rowData.employee.designation,
    },
    {
      title: "CREATED DATE",
      field: "createAt",
      filtering: false,
      render: (rowData) => formatDate(rowData.createAt),
    },
    {
      title: "SUPERVISOR",
      field: "supervisor",
      filtering: false,
      render: (rowData) => rowData.supervisor && rowData.supervisor.username,
    },
    {
      title: "SUPERVISOR'S COMMENT",
      field: "supervisorComment",
      filtering: false,
    },
    {
      title: "SCORE",
      field: "appraisalScore",
      filtering: false,
    },
    {
      filtering: false,
      render: (rowData) => (
        <div className="flex flex-col gap-3">
          <div
            className="py-2 px-4 text-center text-white green rounded-lg cursor-pointer whitespace-nowrap "
            onClick={() => {
              // reset();
              // salaryPage(rowData);
              handleAppraisal(rowData);
            }}
          >
            Apply Appraisal
          </div>
        </div>
      ),
    },
    // { title: "Causal Leaves", field: "casualLeaves", filtering: false },
    // { title: "Sick Leaves", field: "sickLeaves", filtering: false },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-col  gap-6 mb-4 md:absolute z-10 right-10">
          <button
            onClick={() => {
              navigate("add");
            }}
            className="py-4 px-10 green text-white font-thin rounded-xl whitespace-nowrap"
          >
            Appraisal Form
          </button>
        </div>
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            filtering: true,
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderBottom: "null",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          actions={
            [
              // {
              //   icon: () => <EditButton />,
              //   onClick: (event, rowData) => {
              //     navigate(`form-view/${rowData._id}`);
              //   },
              // },
              // (rowData) => ({
              //   icon: () =>
              //     rowData.status === "Active" ? (
              //       <DeleteButton />
              //     ) : (
              //       <ReEnableButton />
              //     ),
              //   onClick: (event, rowData) => {
              //     reset();
              //     deleteUser(rowData);
              //   },
              // }),
              // (rowData) => ({
              //   icon: "delete",
              //   tooltip: "Delete User",
              //   onClick: (event, rowData) =>
              //     confirm("You want to delete " + rowData.name),
              //   disabled: rowData.birthYear < 2000,
              // }),
            ]
          }
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${process.env.REACT_APP_BASEURL}/api/v1/appraisal/appraisalResponse?`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              if (query.search) {
                url += "&employeename=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* Add User Modal */}
      <Modal
        show={modal}
        className=" w-4/5 md:w-3/5"
        close={() => {
          setModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className="flex flex-col gap-6 justify-center align-center "
          >
            <div>
              <p className="text-red-700 mt-2 text-left">{error}</p>
            </div>

            {console.log(errors)}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                onClick={() => setModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              <input
                type="submit"
                value="Add"
                className="green p-3  rounded-lg text-white cursor-pointer"
              />
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Edit User Modal */}
      <Modal
        show={editModal}
        className=" w-4/5 md:w-3/5"
        close={() => {
          setEditModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleEditForm)}
            className="flex flex-col gap-6 justify-center align-center "
          >
            <div>
              <p className="text-red-700 mt-2 text-left">{error}</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 md:w-2/6 min-w-fit text-center self-center gap-4">
              <input
                onClick={() => setEditModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              {editForm === true ? (
                <input
                  type={"submit"}
                  value={"Save Changes"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                />
              ) : (
                <button
                  onClick={() => setEditForm(true)}
                  type={"button"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                >
                  Edit{" "}
                </button>
              )}
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Delete User Modal */}
      <Modal
        show={deleteModal}
        close={() => {
          setDeleteModal(false);
        }}
      >
        <h2 className="text-lg">
          Are you sure you want to <br />
          {selectedUser.status == "Active" ? "deactivate " : "activate "}
          {selectedUser.username}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
          <input
            onClick={() => setDeleteModal(false)}
            type="button"
            value="Cancel"
            className="grey p-3 rounded-lg text-white cursor-pointer"
          />
          <input
            type="button"
            onClick={() => HandleDeleteForm()}
            value={selectedUser.status == "Active" ? "Deactivate" : "Activate"}
            className="red p-3 rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal>
    </main>
  );
};

export default AppraisalFormList;
