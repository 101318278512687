import logo from "../images/insignia_logo.svg";
import { userSchema } from "../components/Validation/SignupSchema";
import { useForm, FormProvider } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useState } from "react";
import FormImageField from "./../components/FormImageField";

import mobileImage from "../images/signup-mockup.png";
import SignupInputField from "../components/SignUpInput/SignupInputField";
import SignupPasswordField from "../components/SignUpInput/SignupPasswordField";

const SignUp = () => {
  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  // const history = useHistory();
  const [showPass, setShowPass] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  let navigate = useNavigate();

  const errorDisplay = (error) => {
    if (error) {
      const answer = error.split(",").join("\n");
      return answer;
    }
  };

  const HandleForm = (data) => {
    // const file = data["file"];
    // data["file"] = file[0];
    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();
      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/offices`,
        method: "POST",
        headers: {
          // Add any auth token here
          "Content-Type": "application/json",
          // authorization: "your token comes here",
        },
        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          // setError(res.message);

          //redirecting

          navigate("/signup-wait");
        })
        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          setError(err.response.data.error);
          // toast.error(err.response.data.error);
          return Promise.reject();
        });
      toast.promise(promise, {
        loading: "Loading",
        success: "Request Sent ",
        error: "An error has occurred",
      });
    }
  };

  return (
    <div className="grid h-screen w-full place-items-center bg-white overflow-auto">
      <div className=" flex flex-col md:flex-row items-center text-center gap-4 p-6 sm:p-1 md:gap-36 justify-start">
        <div className="flex flex-col gap-3 items-center">
          <img className="h-24 xl:h-32" src={logo} alt="" />
          <img
            className="hidden  md:block h-72 xl:h-96"
            src={mobileImage}
            alt=""
          />
        </div>

        <div className="flex flex-col gap-3 md:py-3 md:px-16 xl:py-8 xl:gap-6 md:shadow-3xl  md:rounded-3xl">
          <div>
            <h1 className="text-2xl xl:text-3xl font-bold">Sign Up</h1>
          </div>

          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(HandleForm)}
              action=""
              className="flex flex-col justify-center  gap-3"
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-1 content-center gap-3">
                <SignupInputField
                  type="text"
                  placeholder="Office Name"
                  name={"name"}
                />

                <SignupInputField
                  type="text"
                  placeholder="Admin Name"
                  name="username"
                />

                <SignupInputField
                  type="number"
                  placeholder="030XXXXXXXX"
                  min={0}
                  name="phone"
                />

                <SignupInputField
                  type="email"
                  autoComplete="new-password"
                  placeholder="Email"
                  name={"email"}
                />

                <SignupPasswordField
                  randomGenerator={false}
                  autoComplete="new-password"
                  placeholder="Password"
                  name={"password"}
                />
              </div>

              <p className="text-center text-red-700 mt-2 w-full">
                {errorDisplay(error)}
              </p>

              {console.log(errors)}

              <button className=" px-4 py-2 primary rounded-lg text-white w-full lg:w-2/5 self-center text-sm xl:text-base">
                Sign Up
              </button>
            </form>
          </FormProvider>

          <div>
            <h3 className="text-sm xl:text-base">
              Already have an account yet?
            </h3>
            <Link
              to={"/login"}
              className=" underline text-sm xl:text-base text-lime-500"
              href=""
            >
              Sign In
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
