import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";
import { formatDate, getMonthYear } from "../../../components/Functions";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";

const Report = () => {
  const { state } = useLocation();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [slip, setSlip] = useState({
    _id: "",
    presents: "0",
    monthlyLeaves: "0",
    lates: "0",
    absents: "0",
    userId: "",
    officeId: "",
    createAt: "",
    __v: 0,
  });

  useEffect(() => {
    if (state) {
      getReport();
    }
  }, []);

  const getReport = () => {
    setError();
    console.log(state._id);
    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/api/v1/salary/report/${state._id}`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res.data.data[0]);
        setSlip(res.data.data[0]);
      })
      // Catch errors if any
      .catch((err) => {
        console.log(err);
        return Promise.reject();
      });
    toast.promise(promise, {
      loading: "Loading",
      success: "Report Generated",
      error: "An error has occurred",
    });
  };

  const printPDF = () => {
    const domElement = document.getElementById("print");
    html2canvas(domElement, {
      quality: 2,
      scale: 2,
      onclone: (document) => {
        document.getElementById("button").style.visibility = "hidden";
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPdf({
        orientation: "portrait",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight, "SLOW");
      pdf.save("download.pdf");
    });
  };

  if (!state)
    return (
      <main className="content-container">
        {
          <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
            <div className="flex flex-row justify-end">
              <button
                id="button"
                class="green text-white font-bold py-2 px-4 rounded"
                onClick={printPDF}
              >
                Print
              </button>
            </div>
            <div className="flex flex-col gap-6 sm:p-20" id="print">
              <h2 className="text-xl text-center">
                REPORT FOR {getMonthYear(slip.createAt)}
              </h2>

              {/* Office Details  */}
              <div className="mt-8 uppercase">
                <p>{slip.officeId.name}</p>
                <p>{slip.officeId.location}</p>
              </div>

              {/* Employee Details  */}
              <div className="uppercase">
                <p>{slip.username}</p>
                <p>{slip.userId.designation}</p>
              </div>

              <div className="flex flex-col ">
                {/* <h2 className="text-lg font-bold mb-2">Earnings</h2> */}
                <div className="flex flex-row justify-between items-center border px-2 py-2 ">
                  <p className="font-semibold">Present Days</p>
                  <p className="text-right">{slip.presents}</p>
                </div>
                <div className="flex flex-row justify-between items-center border-b border-l border-r px-2 py-2">
                  <p className="font-semibold">Absent Days</p>
                  <p className="text-right">{slip.absents}</p>
                </div>
                <div className="flex flex-row justify-between items-center border-b border-l border-r px-2 py-2">
                  <p className="font-semibold">Lates</p>
                  <p className="text-right">{slip.lates}</p>
                </div>
                <div className="flex flex-row justify-between items-center border-b border-l border-r px-2 py-2">
                  <p className="font-semibold">Leaves</p>
                  <p className="text-right">{slip.monthlyLeaves}</p>
                </div>
                <div className="flex flex-row justify-between items-center border-b border-l border-r px-2 py-2">
                  <p className="font-semibold">WFH</p>
                  <p className="text-right">{slip.wfh}</p>
                </div>
              </div>

              {/* <p className="font-bold">Net Salary: {slip.netPay} PKR</p> */}
            </div>
          </div>
        }
      </main>
    );
  else
    return (
      <main className="content-container">
        <div>
          <h1 className="text-9xl text-custom-green">404</h1>

          <h1 className="text-3xl font-bold">Page not found</h1>
        </div>
      </main>
    );
};

export default Report;
