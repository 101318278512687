import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ErrorDisplay from "../../../components/ErrorDisplay";
import { removeEmpty } from "../../../components/Functions";
import Modal from "../../../components/Modal";
import ModuleComponent from "../../../components/ModuleComponent";
import PageLoader from "../../../components/PageLoader";
import { userSchema } from "../../../components/Validation/Admin/RequestModules";

const ModulesRequest = () => {
  const [selectedUser, setSelectedUser] = useState({
    photo: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [deleteValue, setDeleteValue] = useState();
  let navigate = useNavigate();

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = methods;

  const getModuleInfo = () => {
    if (!disabled) {
      setDisabled(true);

      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/offices/getModules`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res.data.data);
          setSelectedUser(res.data.data);
          reset({
            fileManagement: res.data.data.fileManagement,
            inventory: res.data.data.inventory,
            leaveManagement: res.data.data.leaveManagement,
            payroll: res.data.data.payroll,
            rewardSystem: res.data.data.rewardSystem,
            shift: res.data.data.shift,
            taskManagement: res.data.data.taskManagement,
            voting: res.data.data.voting,
          });

          setDisabled(false);
          setLoaded(true);
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          return Promise.reject();
        });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Data loaded",
      //   error: "An error has occurred",
      // });
    }
  };

  useEffect(() => {
    getModuleInfo();
  }, []);

  const HandleEditForm = (data) => {
    // const cleanedData = removeEmpty(data);

    // let customData = {
    //   ...selectedUser,
    //   ...cleanedData,
    // };
    // //formatting data according to backend's requirment
    // delete customData._id;
    // delete customData.name;

    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/module`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          reset();
          navigate("/AD/modules/module-records");
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Request Sent",
        error: "An error has occurred",
      });
    }
  };

  const HandleDeleteForm = () => {
    const data = {
      [deleteValue]: false,
    };

    console.log(data);

    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = data;
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/module`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          reset();
          navigate("/AD/modules/module-records");
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Request Sent",
        error: "An error has occurred",
      });
    }
  };

  const handleDeleteConfirmation = (name) => {
    setDeleteValue(name);
    setDeleteModal(true);
  };

  if (loaded === false) {
    return (
      <div className="md:mt-56">
        <PageLoader />
      </div>
    );
  } else
    return (
      <main className="content-container">
        <div className="py-7 px-8 bg-white shadow-2xl rounded-2xl">
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(HandleEditForm)}
              className="flex flex-col gap-6"
            >
              <ErrorDisplay>{error}</ErrorDisplay>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                <ModuleComponent
                  value={selectedUser.fileManagement}
                  heading={"File Management"}
                  description={
                    "For HR facilitation, the app provides record-keeping for required, collected, and disposed documents from employees and the official ones too."
                  }
                  name={"fileManagement"}
                  HandleDeleteForm={handleDeleteConfirmation}
                />
                <ModuleComponent
                  value={selectedUser.inventory}
                  heading={"Inventory"}
                  description={
                    "Inventory control helps maintain and update a comprehensive data log to ensure what inventory has been allotted to whom."
                  }
                  name={"inventory"}
                  HandleDeleteForm={handleDeleteConfirmation}
                />
                <ModuleComponent
                  value={selectedUser.leaveManagement}
                  heading={"Leave Management"}
                  description={
                    "Facilitating both employee and HR, Insignia provides freedom to apply, approve, forward, reject and view any kind of leaves available."
                  }
                  name={"leaveManagement"}
                  HandleDeleteForm={handleDeleteConfirmation}
                />
                <ModuleComponent
                  value={selectedUser.payroll}
                  heading={"Payroll"}
                  description={
                    "Generate payrolls on a single click with complete payroll history and calculate gross and net income."
                  }
                  name={"payroll"}
                  HandleDeleteForm={handleDeleteConfirmation}
                />
                <ModuleComponent
                  value={selectedUser.rewardSystem}
                  heading={"Reward System"}
                  description={
                    "Rewarding employees with recognition and appreciation by offering meaningful rewards to be claimed when mature."
                  }
                  name={"rewardSystem"}
                  HandleDeleteForm={handleDeleteConfirmation}
                />
                <ModuleComponent
                  value={selectedUser.shift}
                  heading={"Shift"}
                  description={
                    "Manage and streamline your employees’ shift rotations automatically and keep a track record of the same."
                  }
                  name={"shift"}
                  HandleDeleteForm={handleDeleteConfirmation}
                />
                <ModuleComponent
                  value={selectedUser.taskManagement}
                  heading={"Task Management"}
                  description={
                    "Designed to keep track of custom workflows of each individual via maintaining separate task sheets and individual performances."
                  }
                  name={"taskManagement"}
                  HandleDeleteForm={handleDeleteConfirmation}
                />
                <ModuleComponent
                  value={selectedUser.voting}
                  heading={"Voting"}
                  description={
                    "Creating polls for many formal and casual matters to help acquire opinions and achieve results in no time."
                  }
                  name={"voting"}
                  HandleDeleteForm={handleDeleteConfirmation}
                />
              </div>

              <ErrorDisplay>{error}</ErrorDisplay>
              {isValid && (
                <input
                  type={"submit"}
                  value={"Request Modules"}
                  className="green p-3 w-3/4 sm:w-1/4 md:w-2/4 lg:w-2/6 mx-auto mt-6 rounded-lg text-white cursor-pointer"
                />
              )}
            </form>
          </FormProvider>
        </div>

        {/* Delete Rules Modal */}
        <Modal
          show={deleteModal}
          close={() => {
            setDeleteModal(false);
          }}
        >
          <div></div>
          <h2 className="text-lg text-center">
            Are you sure you want to submit a removal request <br /> for module{" "}
            {deleteValue}?
          </h2>
          <div className="grid grid-cols-2 gap-4 mt-5">
            <input
              onClick={() => setDeleteModal(false)}
              type="button"
              value="Cancel"
              className="grey p-3 rounded-lg text-white cursor-pointer"
            />
            <input
              type="button"
              onClick={() => HandleDeleteForm()}
              value={"Remove"}
              className="red p-3 rounded-lg text-white cursor-pointer"
            />
          </div>
        </Modal>
      </main>
    );
};

export default ModulesRequest;
