import triangle from "../../images/green_triangle.svg";
import CountUp from "react-countup";
import { useNavigate } from "react-router-dom";

const Cards = (props) => {
  const navigate = useNavigate();
  const navigateToLink = () => {
    navigate(props.link);
  };

  return (
    <div
      className={`p-5 w-full rounded-lg max-w-xs drop-shadow-xl text-white hover:opacity-70 ${props.color} cursor-pointer ${props.className}`}
      onClick={navigateToLink}
    >
      <h2 className="text-xl font-semibold ">{props.label}</h2>
      <div className="flex  flex-row  justify-between items-end mt-2">
        <h1 className="text-5xl font-bold">
          <CountUp end={props.number} duration={2} useEasing={true} />{" "}
        </h1>
      </div>
    </div>
  );
};

export default Cards;
