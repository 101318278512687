import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  ComposedChart,
} from "recharts";

const LineGraph = ({ data, title }) => {
  return (
    <div className="py-6 px-3 rounded-lg  bg-white">
      <h1 className="text-lg md:text-3xl font-semibold uppercase mb-6 ml-7">
        {title}
      </h1>
      <ResponsiveContainer width="99%" aspect={4}>
        <ComposedChart
          margin={{ top: 4, left: 0, right: 10, bottom: 4 }}
          data={data}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="1%" stopColor="#5ABA8A" stopOpacity={0.1} />
              <stop offset="99%" stopColor="#FFFFFF" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="0" strokeDasharray="0 0" />
          <XAxis dataKey="month" dy={10} strokeWidth={0} />
          <YAxis dataKey="value" strokeWidth={0} />

          <Area
            type="monotone"
            dataKey="value"
            stroke={false}
            strokeWidth={2}
            fillOpacity={1}
            fill="url(#colorUv)"
          />
          <Line
            type="monotone"
            dataKey="value"
            fill="url(#colorUv)"
            strokeWidth={2}
            stroke="#5ABA8A"
            activeDot={{ r: 8 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineGraph;
