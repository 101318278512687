import * as yup from "yup";

yup.addMethod(yup.object, "atLeastOneOf", function (list) {
  return this.test({
    name: "atLeastOneOf",
    message: "${path} must have at least one of these keys: ${keys}",
    exclusive: true,
    params: { keys: list.join(", ") },
    test: (value) => value == null || list.some((f) => !!value[f]),
  });
});

export const userSchema = yup
  .object()
  .shape({
    fileManagement: yup.bool(),
    inventory: yup.bool(),
    leaveManagement: yup.bool(),
    payroll: yup.bool(),
    rewardSystem: yup.bool(),
    shift: yup.bool(),
    taskManagement: yup.bool(),
    voting: yup.bool(),
  })
  .atLeastOneOf([
    "fileManagement",
    "inventory",
    "leaveManagement",
    "payroll",
    "rewardSystem",
    "shift",
    "taskManagement",
    "voting",
  ]);
