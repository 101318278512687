import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FormTextArea from "../FormTextArea";
import FormSelectField from "./../FormSelectField";

const INDSelectField = ({
  name,
  customUrl,
  taskId,
  initialValue,
  className = "pr-3 ",
  focusedClassName = "input-form",
  ...otherProps
}) => {
  const [disabled, setDisabled] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [error, setError] = useState("");
  const [focused, setFocused] = useState(false);

  //For form
  const methods = useForm();
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = () => {
    if (!disabled) {
      setDisabled(true);

      setError();

      const promise = axios({
        // Endpoint to send files
        url: customUrl,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res.data.data[name]);
          reset({
            [name]: res.data.data[name],
          });

          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          return Promise.reject();
        });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Data loaded",
      //   error: "An error has occurred",
      // });
    }
  };

  const HandleForm = (data) => {
    console.log(data);

    if (!disabled) {
      setDisabled(true);

      setError();

      const promise = axios({
        // Endpoint to send files
        url: customUrl,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          getInfo();
          setFocused(false);
          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.error);
          setDisabled(false);

          return Promise.reject();
        });
      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "UPDATED",
      //   error: "An error has occurred",
      // });
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(HandleForm)}>
          <div>
            <FormSelectField
              className={focused ? `${focusedClassName}` : `${className}`}
              name={name}
              onFocus={() => setFocused(true)}
              defaultValue={initialValue}
              // onBlur={() => setFocused(false)}
              {...otherProps}
            >
              <option defaultValue={initialValue} disabled selected>
                {initialValue}
              </option>

              <option value="Doing">Doing</option>
              <option value="Todo">Todo</option>
              <option value="Backlog">Backlog</option>
              <option value="Testing">Testing</option>
              <option value="Done">Done</option>
              <option value="Overdue">Overdue</option>
            </FormSelectField>

            {focused && (
              <div className="flex flex-row gap-4 mt-4 text-xs">
                <input
                  type="submit"
                  value={"Save"}
                  className="green text-white rounded-lg p-2 w-20 min-w-fit cursor-pointer "
                />
                <input
                  type="reset"
                  onClick={() => {
                    setFocused(false);
                    reset({
                      [name]: initialValue,
                    });
                    getInfo();
                  }}
                  value={"Cancel"}
                  className="red text-white rounded-lg p-2 w-20 min-w-fit cursor-pointer "
                />
              </div>
            )}
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default INDSelectField;
