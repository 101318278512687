import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import ErrorDisplay from "../../../components/ErrorDisplay";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../../components/PageLoader";
import CSVComponent from "../../../components/CSVComponent";
import {
  downloadFile,
  downloadImage,
  viewImage,
} from "./../../../components/Functions";
import {
  MdArticle,
  MdBeenhere,
  MdOutlineAnnouncement,
  MdOutlineGroups,
  MdOutlineStore,
} from "react-icons/md";
import Note from "../../../components/Note";

const CSV = () => {
  const [selectedUser, setSelectedUser] = useState({
    photo: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [deleteValue, setDeleteValue] = useState();
  let navigate = useNavigate();

  const HandleRequest = (data) => {
    console.log(data);

    if (!disabled) {
      setDisabled(true);

      setError();
      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/${data}/export`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          console.log(
            `${process.env.REACT_APP_BASEURL}/uploads/export/${res.data.data}`
          );
          viewImage(
            `${process.env.REACT_APP_BASEURL}/uploads/export/${res.data.data}`
          );
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "This may take a while",
        success: "CSV Generated",
        error: "An error has occurred",
      });
    }
  };

  if (loaded === false) {
    return <PageLoader />;
  } else
    return (
      <main className="content-container">
        <div className="py-7 px-8">
          <div className="flex flex-col md:flex-row hover:shadow-custom-primary flex-wrap items-start gap-10">
            {/* <CSVComponent
              icon={<MdOutlineAnnouncement size={50} className="fill-black" />}
              heading={"Complaints"}
              name={"complain"}
              HandleRequest={HandleRequest}
            /> */}
            <CSVComponent
              icon={<MdOutlineStore size={50} className="fill-black" />}
              heading={"Offices"}
              name={"offices"}
              buttonText={"Download CSV"}
              HandleRequest={HandleRequest}
            />
          </div>

          <Note className="mt-8">
            Make sure to allow pop-ups on your browser.
          </Note>

          <ErrorDisplay>{error}</ErrorDisplay>
        </div>

        {/* Delete Rules Modal */}
        <Modal
          show={deleteModal}
          close={() => {
            setDeleteModal(false);
          }}
        >
          <div></div>
          <h2 className="text-lg text-center">
            Are you sure you want to submit a removal request <br /> for module{" "}
            {deleteValue}?
          </h2>
          <div className="grid grid-cols-2 gap-4 mt-5">
            <input
              onClick={() => setDeleteModal(false)}
              type="button"
              value="Cancel"
              className="grey p-3 rounded-lg text-white cursor-pointer"
            />
            <input
              type="button"
              onClick={() => HandleRequest()}
              value={"Remove"}
              className="red p-3 rounded-lg text-white cursor-pointer"
            />
          </div>
        </Modal>
      </main>
    );
};

export default CSV;
