const Questions = ({
  question,
  index,
  questionList,
  answer,
  answerObject,
  type,
}) => {
  return (
    <div className="flex flex-col gap-3 border border-gray-400 p-3 w-full">
      <span className="font-semibold text-gray-800 text-base justify-start">
        {question}
      </span>
      {type === "Mcqs" && (
        <div className="flex flex-col gap-2 text-gray-500 text-base">
          {questionList.map((questionList, index) => (
            <p
            // className={questionList.correct ? "text-green-600" : ""}
            >
              {index + 1}. {questionList.text}
            </p>
          ))}
        </div>
      )}

      <span
        className={`${
          answerObject.correct
            ? "text-green-700 border-green-700"
            : "text-red-700 border-red-700"
        }  text-opacity-70 text-base justify-start border-l-2  border-opacity-70 p-2`}
      >
        Choosen Answer: {answer}
      </span>
    </div>
  );
};

export default Questions;
