import { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import { Routes, Route } from "react-router-dom";

import { Outlet } from "react-router";
import Container from "../components/Container/Container";
import RightNavbar from "../components/RightNavbar/RightNavbar";
import NavContext from "../Context/NavContext";
import NavbarSuperVisor from "../components/Navbar/NavbarSuperVisor";
import NavbarAdmin from "../components/Navbar/NavbarAdmin";
import axios from "axios";

export default ({ setModules }) => {
  const [navDetails, setNavDetails] = useState({
    attendance: false,
    leaveManagement: false,
    rewardSystem: false,
    voting: false,
    taskManagement: false,
  });

  useEffect(() => {
    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/api/v1/offices/getModules`,
      method: "GET",
      headers: {
        // Add any auth token here
        "Content-Type": "application/json",
        // authorization: "your token comes here",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      // Attaching the form data
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res.data.data);
        setNavDetails(res.data.data);

        localStorage.setItem("leaveManagement", res.data.data.leaveManagement);
        localStorage.setItem("fileManagement", res.data.data.fileManagement);
        localStorage.setItem("rewardSystem", res.data.data.rewardSystem);
        localStorage.setItem("payroll", res.data.data.payroll);
        localStorage.setItem("shift", res.data.data.shift);
        localStorage.setItem("taskManagement", res.data.data.taskManagement);
        localStorage.setItem("voting", res.data.data.voting);
        localStorage.setItem("inventory", res.data.data.inventory);
      })
      // Catch errors if any
      .catch((err) => {
        console.log(err);
        // toast.error(err.response.data.error);
        return Promise.reject();
      });
  }, []);

  const chooseUser = () => {
    if (localStorage.getItem("role") === "superAdmin") {
      return <Navbar />;
    }

    if (localStorage.getItem("role") === "admin") {
      return <NavbarAdmin navDetails={navDetails} />;
    }

    if (localStorage.getItem("role") === "supervisor") {
      return <NavbarSuperVisor navDetails={navDetails} />;
    }
  };

  return (
    <>
      {chooseUser()}
      <Container stickyNav={<RightNavbar />} content={<Outlet />} />
    </>
  );
};
