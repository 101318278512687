import profile from "../../../images/table/default_profile.svg";
import React, { useState, useRef, useReducer, useEffect } from "react";
import { userSchema } from "../../../components/Validation/Admin/AdminProfileSchema";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import FormInputField from "../../../components/FormInputField";
import FormSelectField from "../../../components/FormSelectField";
import ErrorDisplay from "../../../components/ErrorDisplay";
import { formatDate, removeEmpty } from "../../../components/Functions";
import Image from "../../../components/Image";
import FormImageField from "../../../components/FormImageField";
import PageLoader from "./../../../components/PageLoader";

const OfficeDetails = () => {
  const [selectedUser, setSelectedUser] = useState({
    photo: "",
    _id: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [loaded, setLoaded] = useState(false);

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const getUserInfo = () => {
    if (!disabled) {
      setDisabled(true);

      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/auth/office`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res.data.data);
          setSelectedUser(res.data.data.office);
          reset({
            username: res.data.data.username,
            phone: res.data.data.phone,
            nic: res.data.data.nic,
            dateofBirth: res.data.data.dateofBirth,
            maritalStatus: res.data.data.maritalStatus,
            gender: res.data.data.gender,
            address: res.data.data.address,
            designation: res.data.data.designation,
            salary: res.data.data.salary,
            emerg_name: res.data.data.emerg_name,
            emerg_contact: res.data.data.emerg_contact,
          });
          setLoaded(true);
          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          return Promise.reject();
        });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Data loaded",
      //   error: "An error has occurred",
      // });
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const HandleEditForm = (data) => {
    console.log(data);

    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/offices/${selectedUser._id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setEditForm(false);
          setDisabled(false);
          // reset();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Details Updated ",
        error: "An error has occurred",
      });
    }
  };

  if (loaded === false) {
    return <PageLoader />;
  } else
    return (
      <main className="content-container">
        <div className="py-7 px-8 bg-white shadow-2xl rounded-2xl">
          <FormProvider {...methods}>
            <form
              className="flex flex-col gap-9"
              onSubmit={handleSubmit(HandleEditForm)}
            >
              <FormImageField
                name={"file"}
                edit={editForm}
                value={selectedUser.image}
              />

              <div>
                <h2 className="text-lg font-bold uppercase mb-2">
                  Office Details
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2  gap-4 ">
                  <FormInputField
                    label={"Name"}
                    labelClass="mb-2 text-md"
                    disabled={!editForm}
                    defaultValue={selectedUser.name}
                    name={"name"}
                  />

                  <FormInputField
                    label={"Contact Number"}
                    labelClass="mb-2 text-md"
                    disabled={!editForm}
                    defaultValue={selectedUser.phone}
                    name={"phone"}
                  />

                  <FormInputField
                    label={"Office Initials"}
                    labelClass="mb-2 text-md"
                    disabled
                    defaultValue={selectedUser.temp}
                    name={"temp"}
                  />

                  <FormInputField
                    label={"Address"}
                    labelClass="mb-2 text-md"
                    disabled={!editForm}
                    defaultValue={selectedUser.location}
                    name={"location"}
                  />

                  <FormInputField
                    label={"Website"}
                    labelClass="mb-2 text-md"
                    disabled={!editForm}
                    defaultValue={selectedUser.website}
                    name={"website"}
                  />
                </div>
              </div>

              <div className="">
                <h2 className="text-lg font-bold mb-2 uppercase ">
                  Selected Modules
                </h2>

                <div className="flex flex-row  gap-4 mt-2 flex-wrap">
                  <div className="flex flex-row gap-3 text-left">
                    <input
                      type="checkbox"
                      disabled
                      checked={selectedUser.leaveManagement}
                    />
                    <label htmlFor="leaveManagement">Leave Management</label>
                  </div>

                  <div className="flex flex-row gap-3">
                    <input
                      type="checkbox"
                      disabled
                      checked={selectedUser.rewardSystem}
                    />
                    <label htmlFor="rewardSystem">Reward System</label>
                  </div>

                  <div className="flex flex-row gap-3">
                    <input
                      type="checkbox"
                      disabled
                      checked={selectedUser.voting}
                    />
                    <label htmlFor="voting">Voting</label>
                  </div>

                  <div className="flex flex-row gap-3">
                    <input
                      type="checkbox"
                      disabled
                      checked={selectedUser.attendance}
                    />
                    <label htmlFor="attendance">Attendance</label>
                  </div>
                </div>
              </div>

              <ErrorDisplay>{error}</ErrorDisplay>

              {editForm === true ? (
                <input
                  type={"submit"}
                  value={"Save Changes"}
                  className="green p-3 w-2/4 sm:w-1/4 lg:w-1/6 mt-3 self-center rounded-lg text-white cursor-pointer"
                />
              ) : (
                <button
                  onClick={() => setEditForm(true)}
                  type={"button"}
                  className="green p-3 w-2/4 sm:w-1/4 lg:w-1/6 mt-3 self-center rounded-lg text-white cursor-pointer"
                >
                  Edit{" "}
                </button>
              )}
            </form>
          </FormProvider>
        </div>
      </main>
    );
};

export default OfficeDetails;
