export const roleStyling = (role) => {
  if (role === "admin")
    return {
      borderRadius: "5px",
      background: "#5652a1",
      color: "white",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
  else if (role === "supervisor")
    return {
      background: "#7CC1A8",
      borderRadius: "5px",
      color: "white",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
  else if (role === "employee")
    return {
      borderRadius: "5px",
      background: "#5293A1",
      color: "white",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
  else
    return {
      borderRadius: "5px",
      background: "#5293A1",
      color: "white",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
};

export const statusStyling = (status) => {
  if (status === "Active")
    return {
      borderRadius: "5px",
      background: "#7FC348",
      color: "white",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
  else if (status === "Inactive")
    return {
      background: "#DE5151",
      borderRadius: "5px",
      color: "white",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
  else
    return {
      borderRadius: "5px",
      background: "#5293A1",
      color: "white",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
};

export const candidateStyling = (status) => {
  if (status === "Applied")
    return {
      borderRadius: "5px",
      background: "#01565B",
      color: "white",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
  else if (status === "Passed")
    return {
      background: "#5ABA8A",
      borderRadius: "5px",
      color: "white",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
  else if (status === "Scheduled")
    return {
      background: "#CAB81B",
      borderRadius: "5px",
      color: "white",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
  else
    return {
      borderRadius: "5px",
      background: "#5293A1",
      color: "white",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
};

export const leaveRequestStyling = (status) => {
  if (status === "Approved")
    return {
      border: "1px solid #01565B",
      borderRadius: "5px",
      color: "#01565B",
      width: "8rem",
      minWidth: "fit",
      padding: "0.4rem",
      textAlign: "center",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      fontWeight: "400",
    };
  else if (status === "Pending")
    return {
      border: "1px solid #BBCD69",
      borderRadius: "5px",
      color: "#BBCD69",
      width: "8rem",
      minWidth: "fit",
      padding: "0.4rem",
      textAlign: "center",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      fontWeight: "400",
    };
  else if (status === "Rejected")
    return {
      border: "1px solid #DE5151",
      borderRadius: "5px",
      color: "#DE5151",
      width: "8rem",
      minWidth: "fit",
      padding: "0.4rem",
      textAlign: "center",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      fontWeight: "400",
    };
  else
    return {
      border: "1px solid #01565B",
      borderRadius: "5px",
      color: "#01565B",
      width: "8rem",
      minWidth: "fit",
      padding: "0.4rem",
      textAlign: "center",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      fontWeight: "400",
    };
};

export const attendanceStyling = (status) => {
  if (status === "Present")
    return {
      border: "1px solid #448B43",
      borderRadius: "5px",
      color: "#448B43",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
  else if (status === "Absent")
    return {
      border: "1px solid #8B4343",
      borderRadius: "5px",
      color: "#8B4343",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
  else if (status === "Late")
    return {
      border: "1px solid #b53802",
      borderRadius: "5px",
      color: "#b53802",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
  else if (status === "WFH")
    return {
      border: "1px solid #A9AD24",
      borderRadius: "5px",
      color: "#A9AD24",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
  else
    return {
      color: "#A9AD24",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
};

export const votingStyling = (status) => {
  if (status === "Scheduled")
    return {
      color: "#CAB81B",
      fontWeight: 400,
    };
  else if (status === "Ongoing")
    return {
      color: "#5ABA8A",
      fontWeight: 400,
    };
  else if (status === "Completed")
    return {
      color: "#01565B",
      fontWeight: 400,
    };
  else
    return {
      color: "#A9AD24",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
};

export const scheduleStyling = (status) => {
  if (status === "Pending")
    return {
      border: "1px solid #BBCD69",
      borderRadius: "5px",
      color: "#BBCD69",
      width: "8rem",
      minWidth: "fit",
      padding: "0.4rem",
      textAlign: "center",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      fontWeight: "400",
    };
  else if (status === "In Process")
    return {
      border: "1px solid #01565B",
      borderRadius: "5px",
      color: "#01565B",
      width: "8rem",
      minWidth: "fit",
      padding: "0.4rem",
      textAlign: "center",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      fontWeight: "400",
    };
  else if (status === "Rejected")
    return {
      border: "1px solid #DE5151",
      borderRadius: "5px",
      color: "#DE5151",
      width: "8rem",
      minWidth: "fit",
      padding: "0.4rem",
      textAlign: "center",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      fontWeight: "400",
    };
  else
    return {
      color: "#A9AD24",
      width: "6rem",
      padding: "3px",
      textAlign: "center",
    };
};
