import * as yup from "yup";
import { format } from "date-fns";
const date = new Date();
export const userSchema = yup.object().shape({
  username: yup.string(),
  email: yup
    .string()

    .email("Please enter a valid email address"),
  password: yup.string(),
  phone: yup
    .string()

    .matches(
      /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm,
      "Enter Contact in the format 030X-XXXXXXXX"
    ),
  nic: yup
    .string()

    .matches(/^[0-9]{13}$/, "CNIC must be 13 digits long"),
  designation: yup.string(),
  department: yup.string(),
  maritalStatus: yup.string(),
  dateOfBirth: yup.string(),
  emerg_name: yup.string(),
  emerg_contact: yup
    .string()

    .matches(
      /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm,
      "Enter Contact in the format 030X-XXXXXXXX"
    ),
  gender: yup.string(),
  JoiningDate: yup.string(),
  role: yup.string(),
  photo: yup.string(),
  leaves: yup.string(),
  salaryMethod: yup.string("Please select a salary method"),
  salary: yup.number("Please enter a salary").when("salaryMethod", {
    is: "manual",
    then: yup.number("Please enter a salary").required("Salary is required"),
  }),
  health_allowance: yup.number("Please enter an health allowance"),
  fuel_allowance: yup.string("Please enter a fuel allowance"),
  supervisedBy: yup.string(),
  shiftId: yup.string("Please select a Shift").required("Shift is required"),
  gradeId: yup.string("Please select a Grade").when("salaryMethod", {
    is: "grade",
    then: yup.string("Please select a Grade").required("Grade is required"),
  }),
});
