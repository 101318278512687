import profile from "../../../images/table/default_profile.svg";
import React, { useState, useRef, useReducer, useEffect } from "react";
import { userSchema } from "../../../components/Validation/Admin/AdminProfileSchema";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import FormInputField from "../../../components/FormInputField";
import FormSelectField from "../../../components/FormSelectField";
import ErrorDisplay from "../../../components/ErrorDisplay";
import { formatDate, removeEmpty } from "../../../components/Functions";
import Image from "../../../components/Image";
import FormImageField from "../../../components/FormImageField";
import PageLoader from "../../../components/PageLoader";

const Profile = () => {
  const [selectedUser, setSelectedUser] = useState({
    photo: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [loaded, setLoaded] = useState(false);

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const getUserInfo = () => {
    if (!disabled) {
      setDisabled(true);

      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/auth/me`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res.data.data);
          setSelectedUser(res.data.data);
          console.log(res.data.data.username);
          reset({
            username: res.data.data.username,
            phone: res.data.data.phone,
            nic: res.data.data.nic,
            dateofBirth: res.data.data.dateofBirth,
            maritalStatus: res.data.data.maritalStatus,
            gender: res.data.data.gender,
            address: res.data.data.address,
            designation: res.data.data.designation,
            salary: res.data.data.salary,
            emerg_name: res.data.data.emerg_name,
            emerg_contact: res.data.data.emerg_contact,
          });
          setLoaded(true);
          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          return Promise.reject();
        });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Data loaded",
      //   error: "An error has occurred",
      // });
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const HandleEditForm = (data) => {
    console.log(data);

    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/auth/updateDetails`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setEditForm(false);
          setDisabled(false);

          // reset();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Details Updated ",
        error: "An error has occurred",
      });
    }
  };

  if (loaded === false) {
    return <PageLoader />;
  } else
    return (
      <main className="content-container">
        <div className="py-7 px-8 bg-white shadow-2xl rounded-2xl">
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(HandleEditForm)}
              className="flex flex-col gap-6"
            >
              <FormImageField
                name={"file"}
                edit={editForm}
                value={selectedUser.photo}
                prefix={`${process.env.REACT_APP_IMAGE_PROFILE}`}
              />

              <div>
                <h2 className="text-lg font-bold mb-2">Personal Details</h2>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                  <FormInputField
                    placeholder="Name"
                    label={"Name"}
                    name={"name"}
                    labelClass={"text-md "}
                    // value={editForm ? null : selectedUser.username}
                    defaultValue={selectedUser.username}
                    disabled={!editForm}
                  />

                  <FormInputField
                    name={"phone"}
                    type="number"
                    label={"Contact Number"}
                    placeholder="030XXXXXXXX"
                    labelClass={"text-md "}
                    defaultValue={selectedUser.phone}
                    disabled={!editForm}
                  />
                  <FormInputField
                    name={"nic"}
                    type="number"
                    label={"CNIC"}
                    autocomplete="new-password"
                    placeholder="National Identity Number*"
                    labelClass={"text-md "}
                    defaultValue={selectedUser.nic}
                    disabled={!editForm}
                  />

                  <FormInputField
                    placeholder="Date of Birth"
                    type="text"
                    id="dateOfBirth"
                    label={"Date of Birth"}
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    name={"dateOfBirth"}
                    size="30"
                    labelClass={"text-md "}
                    defaultValue={formatDate(selectedUser.dateOfBirth)}
                    disabled={!editForm}
                  />

                  <FormSelectField
                    name={"maritalStatus"}
                    type="text"
                    autocomplete="new-password"
                    label={"Marital Status"}
                    placeholder="Marital Status*"
                    labelClass={"text-md "}
                    disabled={!editForm}
                  >
                    <option
                      defaultValue={selectedUser.maritalStatus}
                      disabled
                      selected
                    >
                      {selectedUser.maritalStatus}
                    </option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                  </FormSelectField>

                  <FormSelectField
                    name={"gender"}
                    label={"Gender"}
                    autocomplete="new-password"
                    placeholder="National Identity Number"
                    labelClass={"text-md "}
                    disabled={!editForm}
                  >
                    <option
                      defaultValue={selectedUser.gender}
                      disabled
                      selected
                    >
                      {selectedUser.gender}
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </FormSelectField>

                  <FormInputField
                    placeholder="Address"
                    type="text"
                    name={"address"}
                    label={"Address"}
                    labelClass={"text-md "}
                    defaultValue={selectedUser.address}
                    disabled={!editForm}
                  />
                </div>
              </div>
              {console.log(`Edit form = ${editForm}`)}
              <div>
                <h2 className="text-lg font-bold mb-2">Office Details</h2>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                  <FormInputField
                    placeholder="Designation"
                    name={"designation"}
                    label={"Designation"}
                    labelClass={"text-md "}
                    defaultValue={selectedUser.designation}
                    disabled={!editForm}
                  />

                  <FormInputField
                    placeholder="Salary"
                    type="number"
                    name={"salary"}
                    label={"Salary"}
                    labelClass={"text-md "}
                    defaultValue={selectedUser.salary}
                    disabled={!editForm}
                  />
                </div>
              </div>

              <div>
                <h2 className="text-lg font-bold mb-2">Emergency Details</h2>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                  <FormInputField
                    placeholder="Emergency Name"
                    name={"emerg_name"}
                    label={"Emergency Name"}
                    labelClass={"text-md "}
                    defaultValue={selectedUser.emerg_name}
                    disabled={!editForm}
                  />

                  <FormInputField
                    placeholder="030XXXXXXXX*"
                    type="number"
                    name={"emerg_contact"}
                    label={"Emergency Contact"}
                    labelClass={"text-md "}
                    defaultValue={selectedUser.emerg_contact}
                    disabled={!editForm}
                  />
                </div>
              </div>
              <ErrorDisplay>{error}</ErrorDisplay>

              {editForm === true ? (
                <input
                  type={"submit"}
                  value={"Save Changes"}
                  className="green p-3 w-2/4 sm:w-1/4 lg:w-1/6 mt-3 self-center rounded-lg text-white cursor-pointer"
                />
              ) : (
                <button
                  onClick={() => setEditForm(true)}
                  type={"button"}
                  className="green p-3 w-2/4 sm:w-1/4 lg:w-1/6 mt-3 self-center rounded-lg text-white cursor-pointer"
                >
                  Edit{" "}
                </button>
              )}
            </form>
          </FormProvider>
        </div>
      </main>
    );
};

export default Profile;
