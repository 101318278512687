const Note = ({ children, className }) => {
  if (children)
    return (
      <div>
        <p class={`mt-2 text-sm text-gray-500 ${className}`}>{children}</p>
      </div>
    );
  else return null;
};

export default Note;
