import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { MdOutlineGroups } from "react-icons/md";
import CSVComponent from "../../../components/CSVComponent";
import ErrorDisplay from "../../../components/ErrorDisplay";
import PageLoader from "../../../components/PageLoader";
import {
  currentDate,
  formatDate,
  getFirstDay,
} from "./../../../components/Functions";
import { getLastDay } from "./../../../components/Functions";
import { getMonthYear } from "./../../../components/Functions";

const UserInventoryReport = ({ id, username }) => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(true);

  const doc = new jsPDF();

  const HandleRequest = (data) => {
    console.log(disabled);

    if (!disabled) {
      setDisabled(true);

      setError();
      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/inventoryAssignment?userId=${id}`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);

          try {
            GeneratePDF(res.data.data);
          } catch (error) {
            throw new Error();
          }

          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          setError(err.response.data.error);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "This may take a while",
        success: "Inventory Report Generated",
        error: "An error has occurred",
      });
    }
  };

  const GeneratePDF = (data) => {
    doc.setFontSize(18);
    doc.text(`${username}'s Inventory Report`, 14, 20);

    doc.setFontSize(11);
    doc.text(`Generated on ${currentDate()}`, 14, 28);

    //Code for printing table
    const tableCol = [
      "ASSIGNED ON",
      "USERNAME",
      "EMAIL",
      "ITEM",
      "QUANTITY",
      "DESCRIPTION",
      "STATUS",
    ];

    const tableVal = data.map(
      ({
        createAt,
        username,
        userId,
        inventoryId,
        assigned_quantity,
        status,
      }) => {
        return [
          formatDate(createAt),
          username,
          userId && userId.email,
          inventoryId && inventoryId.name,
          assigned_quantity,
          inventoryId && inventoryId.description,
          status,
        ];
      }
    );

    console.log(tableVal);

    autoTable(doc, {
      head: [tableCol],
      body: tableVal,
      styles: { cellPadding: 0.8, fontSize: 8 },
      startY: 40,
    });

    doc.save(`${username} Inventory Report`);
  };

  if (loaded === false) {
    return <PageLoader />;
  } else
    return (
      <div>
        <div
          className="py-2 px-4 text-center text-white  primary rounded-lg cursor-pointer whitespace-nowrap"
          onClick={HandleRequest}
        >
          Inventory Report
        </div>

        <ErrorDisplay>{error}</ErrorDisplay>
      </div>
    );
};

export default UserInventoryReport;
