import { MenuItem, Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import profile from "../../../images/table/default_profile.svg";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import React, { useState, useRef } from "react";
// import { userSchema } from "../../../components/Validation/Admin/AddTaxSchema";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import {
  formatDate,
  formatOnlyTime,
  formatTime,
  formatTimeDate,
  removeEmpty,
} from "../../../components/Functions";
import FormInputField from "../../../components/FormInputField";
import ErrorDisplay from "../../../components/ErrorDisplay";
import DeleteButton from "./../../../components/MaterialTableButtons/DeleteButton";
import ReEnableButton from "./../../../components/MaterialTableButtons/ReEnableButton";
import EditButton from "./../../../components/MaterialTableButtons/EditButton";
import FormTextArea from "../../../components/FormTextArea";
import FormSelectField from "../../../components/FormSelectField";
import FormReactSelect from "./../../../components/FormReactSelect";
import FilterDropdown from "../../../components/MUTable/FilterDropdown";
import { statusStyling } from "../../../components/StylingFunctions";

const Interview = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    name: "",
    arrivalTime: "",
    departureTime: "",
  });
  const [status, setStatus] = useState("Active");
  const [error, setError] = useState("");
  const [meetingError, setMeetingError] = useState("");

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  //For form
  const methods = useForm({
    // resolver: yupResolver(userSchema),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  const deleteUser = (rowData) => {
    setSelectedUser(rowData);
    setDeleteModal(true);
  };

  const editUser = (rowData) => {
    setSelectedUser(rowData);
    reset({
      candidate: rowData.candidate,
      job: rowData.job,
      type: rowData.type,
      date: formatDate(rowData.date),
      start_time: formatOnlyTime(rowData.start_time),
      end_time: formatOnlyTime(rowData.end_time),
      meeting_link: rowData.meeting_link,
    });
    setEditModal(true);
  };

  const GenerateMeetingLink = () => {
    if (!disabled) {
      setDisabled(true);
      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/zoomapi/createMeeting`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setValue("meeting_link", res.data.data.meeting_link);
        })

        // Catch errors if any
        .catch((err) => {
          setMeetingError(err.response.data.error);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Link Generated",
        error: "An error has occurred",
      });
    }
  };

  //Adding user
  const HandleForm = (data) => {
    data["start_time"] = `${data.date}T${data.start_time}`;
    data["end_time"] = `${data.date}T${data.end_time}`;

    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/interview`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setModal(false);
          setDisabled(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Job Added ",
        error: "An error has occurred",
      });
    }
  };

  const HandleEditForm = (data) => {
    data["start_time"] = `${data.date}T${data.start_time}`;
    data["end_time"] = `${data.date}T${data.end_time}`;
    console.log(data);

    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/interview/${selectedUser._id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          setEditForm(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Job Updated",
        error: "An error has occurred",
      });
    }
  };

  const HandleDeleteForm = () => {
    setDeleteModal(false);
    if (!disabled) {
      setDisabled(true);

      const promise = axios({
        // Endpoint to send files
        url: `${
          selectedUser.status === "Active"
            ? `${process.env.REACT_APP_BASEURL}/api/v1/interview/${selectedUser._id}/deactivate`
            : `${process.env.REACT_APP_BASEURL}/api/v1/interview/${selectedUser._id}/activate`
        }`,
        method: `PUT`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Job Updated ",
        error: "An error has occurred",
      });
    }
  };

  const handleImageErr = (err) => {
    err.target.src = profile;
  };

  const columns = [
    { title: "NAME", field: "username", filtering: false },
    { title: "TYPE", field: "type", filtering: false },
    {
      title: "CREATED AT",
      filtering: false,
      field: "createAt",
      render: (rowData) => formatDate(rowData.createAt),
    },
    {
      title: "DATE",
      filtering: false,
      field: "date",
      render: (rowData) => formatDate(rowData.date),
    },
    {
      title: "START TIME",
      filtering: false,
      field: "start_time",
      render: (rowData) => formatOnlyTime(rowData.start_time),
    },
    {
      title: "END TIME",
      filtering: false,
      field: "end_time",
      render: (rowData) => formatOnlyTime(rowData.end_time),
    },
    {
      title: "JOB",
      filtering: false,
      field: "job",
      render: (rowData) => rowData.job && rowData.job.jobTitle,
    },
    {
      title: "Meeting Link",
      filtering: false,
      field: "meeting_link",

      render: (rowData) => (
        <span className="line-clamp-2 break-all">{rowData.meeting_link}</span>
      ),
    },
    {
      title: "STATUS",
      field: "status",
      filterComponent: (props) => (
        <FilterDropdown data={props}>
          <MenuItem value={"Active"}>Active</MenuItem>
          <MenuItem value={"Inactive"}>Inactive</MenuItem>
        </FilterDropdown>
      ),
      render: (rowData) => (
        <div style={statusStyling(rowData.status)}>{rowData.status}</div>
      ),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-col  gap-6 mb-4 md:absolute z-10 right-10">
          <button
            onClick={() => {
              setModal(true);
              setError();
              setMeetingError();
              reset({});
            }}
            className="py-4 px-10 green text-white font-thin rounded-xl whitespace-nowrap"
          >
            Create Schedule
          </button>
        </div>
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            borderBottom: "2px solid #FFFFFF",
            filtering: true,
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderColor: "white",
            },
            search: true,

            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: () => <EditButton />,
              onClick: (event, rowData) => {
                reset();
                editUser(rowData);
                setEditForm(false);
              },
            },
            (rowData) => ({
              icon: () =>
                rowData.status === "Active" ? (
                  <DeleteButton />
                ) : (
                  <ReEnableButton />
                ),

              onClick: (event, rowData) => {
                reset();
                deleteUser(rowData);
              },
            }),

            // (rowData) => ({
            //   icon: "delete",
            //   tooltip: "Delete User",
            //   onClick: (event, rowData) =>
            //     confirm("You want to delete " + rowData.name),
            //   disabled: rowData.birthYear < 2000,
            // }),
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${process.env.REACT_APP_BASEURL}/api/v1/interview?`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              if (query.search) {
                url += "&username=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* Add Job Modal */}
      <Modal
        show={modal}
        className="w-3/4 lg:w-2/6 md:h-fit"
        close={() => {
          setModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className="flex flex-col gap-6 justify-center align-center"
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <FormReactSelect
                apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/recruitmentUser?status=Active`}
                searchCriteria={"username"}
                label="Select Candidate"
                placeholder={"Search by name"}
                name={"candidate"}
              />

              <FormReactSelect
                apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/job?status=Active`}
                searchCriteria={"jobTitle"}
                label="Select Job"
                placeholder={"Search by name"}
                name={"job"}
              />

              <FormSelectField
                name={"type"}
                type="text"
                required
                label={"Interview Type"}
              >
                <option value="" disabled selected>
                  {/* Select Wro* */}
                </option>
                <option value="Onsite">Onsite</option>
                <option value="Online">Online</option>
              </FormSelectField>

              <FormInputField
                required
                label={"Date"}
                name={"date"}
                type="date"
              />

              <FormInputField
                required
                label={"Start Time"}
                name={"start_time"}
                type="time"
              />

              <FormInputField
                required
                label={"End Time"}
                name={"end_time"}
                type="time"
              />
            </div>

            <div className="flex flex-col md:flex-row gap-3 items-center w-full">
              <button
                onClick={() => GenerateMeetingLink()}
                type="button"
                className="green text-white p-3 rounded-lg whitespace-nowrap"
              >
                Generate Link
              </button>
              <FormInputField required name={"meeting_link"} readOnly />
            </div>

            <ErrorDisplay>{meetingError}</ErrorDisplay>
            <ErrorDisplay>{error}</ErrorDisplay>

            <div className="grid grid-cols-2 gap-4">
              <input
                onClick={() => setModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              <input
                type="submit"
                value="Add"
                className="green p-3  rounded-lg text-white cursor-pointer"
              />
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Edit Job Modal */}
      <Modal
        show={editModal}
        className="w-3/4 lg:w-2/6 md:h-fit"
        close={() => {
          setEditModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleEditForm)}
            className="flex flex-col gap-6 justify-center align-center"
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <FormReactSelect
                apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/recruitmentUser?status=Active`}
                searchCriteria={"username"}
                label="Select Candidate"
                placeholder={selectedUser.username}
                name={"candidate"}
                isDisabled={!editForm}
              />

              <FormReactSelect
                apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/job?status=Active`}
                searchCriteria={"jobTitle"}
                label="Select Job"
                placeholder={selectedUser.job && selectedUser.job.jobTitle}
                name={"job"}
                isDisabled={!editForm}
              />

              <FormSelectField
                name={"type"}
                type="text"
                disabled={!editForm}
                label={"Interview Type"}
              >
                <option value={selectedUser.type} disabled selected>
                  {selectedUser.type}
                </option>
                <option value="Onsite">Onsite</option>
                <option value="Online">Online</option>
              </FormSelectField>

              <FormInputField
                label={"Date"}
                name={"date"}
                type="date"
                disabled={!editForm}
              />

              <FormInputField
                required
                label={"Start Time"}
                name={"start_time"}
                type="time"
                disabled={!editForm}
              />

              <FormInputField
                required
                label={"End Time"}
                name={"end_time"}
                type="time"
                disabled={!editForm}
              />
            </div>

            <div className="flex flex-col md:flex-row gap-3 items-center w-full">
              {editForm && (
                <button
                  onClick={() => GenerateMeetingLink()}
                  type="button"
                  className="green text-white p-3 rounded-lg whitespace-nowrap"
                >
                  Generate Link
                </button>
              )}
              <FormInputField
                label={!editForm && "Meeting Link"}
                name={"meeting_link"}
                readOnly
              />
            </div>

            <ErrorDisplay>{meetingError}</ErrorDisplay>
            <ErrorDisplay>{error}</ErrorDisplay>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                onClick={() => setEditModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              {editForm === true ? (
                <input
                  type={"submit"}
                  value={"Save Changes"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                />
              ) : (
                <button
                  onClick={() => setEditForm(true)}
                  type={"button"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                >
                  Edit{" "}
                </button>
              )}
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Delete Job Modal */}
      <Modal
        show={deleteModal}
        close={() => {
          setDeleteModal(false);
        }}
      >
        <h2 className="text-lg">
          Are you sure you want to <br />
          {selectedUser.status == "Active" ? "deactivate " : "activate "}
          {selectedUser.username}
        </h2>
        <div className="grid grid-cols-2 gap-4 mt-5">
          <input
            onClick={() => setDeleteModal(false)}
            type="button"
            value="Cancel"
            className="grey p-3 rounded-lg text-white cursor-pointer"
          />
          <input
            type="button"
            onClick={() => HandleDeleteForm()}
            value={selectedUser.status == "Active" ? "Deactivate" : "Activate"}
            className="red p-3 rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal>
    </main>
  );
};

export default Interview;
