import { MenuItem, Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import profile from "../../../images/table/default_profile.svg";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import editIcon from "../../../images/table/edit.svg";
import deleteIcon from "../../../images/table/delete.svg";
import reactivateIcon from "../../../images/table/reactivate.svg";
import React, { useState, useRef, useReducer, useEffect } from "react";
import { userSchema } from "../../../components/Validation/Admin/AddRuleSchema";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import Display_Supervisor from "../../../components/AddUser/display-supervisor";
import {
  formatTime,
  joinTime,
  removeEmpty,
} from "../../../components/Functions";
import FormInputField from "../../../components/FormInputField";
import FormSelectField from "../../../components/FormSelectField";
import ErrorDisplay from "../../../components/ErrorDisplay";
import EditButton from "./../../../components/MaterialTableButtons/EditButton";
import DeleteButton from "./../../../components/MaterialTableButtons/DeleteButton";
import ReEnableButton from "./../../../components/MaterialTableButtons/ReEnableButton";
import FilterDropdown from "../../../components/MUTable/FilterDropdown";
import { statusStyling } from "../../../components/StylingFunctions";

const AllotmentRules = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [typeCheck, setTypeCheck] = useState();
  const [disabled, setDisabled] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    name: "",
    arrivalTime: "",
    departureTime: "",
  });
  const [status, setStatus] = useState("Active");
  const [error, setError] = useState("");

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const deleteUser = (rowData) => {
    setSelectedUser(rowData);
    setDeleteModal(true);
  };

  const editUser = (rowData) => {
    setSelectedUser(rowData);
    // reset({

    // })
    setEditModal(true);
  };

  const roleStyling = (role) => {
    if (role === "admin")
      return {
        borderRadius: "5px",
        background: "#5652a1",
        color: "white",
        width: "6rem",
        padding: "3px",
        textAlign: "center",
      };

    if (role === "supervisor")
      return {
        background: "#7CC1A8",
        borderRadius: "5px",
        color: "white",
        width: "6rem",
        padding: "3px",
        textAlign: "center",
      };

    if (role === "employee")
      return {
        borderRadius: "5px",
        background: "#5293A1",
        color: "white",
        width: "6rem",
        padding: "3px",
        textAlign: "center",
      };
  };

  //Adding user
  const HandleForm = (data) => {
    //Manually removing fields because react-hook-form is still submitting disabled fields

    if (data.type === "latePenalty") {
      delete data.graceHour;
      delete data.graceMinute;
    }

    if (data.type === "graceTime") {
      delete data.duration;
      delete data.leaveDeduction;

      // if (data.startTime) {
      //   const [startHour, startMinute] = data.startTime.split(":");
      //   data["startHour"] = startHour;
      //   data["startMinute"] = startMinute;
      //   delete data.startTime;
      // }

      // if (data.endTime) {
      //   const [endHour, endMinute] = data.endTime.split(":");
      //   data["endHour"] = endHour;
      //   data["endMinute"] = endMinute;
      //   delete data.endTime;
      // }
    }

    if (data.type === "lateSitting") {
      delete data.graceHour;
      delete data.graceMinute;
      delete data.leaveDeduction;
    }

    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/allotment`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setModal(false);
          setDisabled(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Rule Added ",
        error: "An error has occurred",
      });
    }
  };

  const HandleEditForm = (data) => {
    if (data.type === "latePenalty") {
      delete data.coins;
      delete data.startTime;
      delete data.endTime;
    }

    if (data.type === "graceTime") {
      delete data.duration;

      if (data.startTime) {
        const [startHour, startMinute] = data.startTime.split(":");
        data["startHour"] = startHour;
        data["startMinute"] = startMinute;
        delete data.startTime;
      }

      if (data.endTime) {
        const [endHour, endMinute] = data.endTime.split(":");
        data["endHour"] = endHour;
        data["endMinute"] = endMinute;
        delete data.endTime;
      }
    }

    if (data.type === "lateSitting") {
      delete data.startTime;
      delete data.endTime;
    }

    console.log(data);

    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/allotment/${selectedUser._id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Allotment Updated ",
        error: "An error has occurred",
      });
    }
  };

  const HandleDeleteForm = () => {
    setDeleteModal(false);
    if (!disabled) {
      setDisabled(true);

      const promise = axios({
        // Endpoint to send files
        url: `${
          selectedUser.status === "Active"
            ? `${process.env.REACT_APP_BASEURL}/api/v1/allotment/${selectedUser._id}/deactivate`
            : `${process.env.REACT_APP_BASEURL}/api/v1/allotment/${selectedUser._id}/activate`
        }`,
        method: `PUT`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Rule Updated ",
        error: "An error has occurred",
      });
    }
  };

  const handleImageErr = (err) => {
    err.target.src = profile;
  };

  const joining = () => {};

  const columns = [
    { title: "TITLE", field: "title", filtering: false },
    { title: "TYPE", field: "type", filtering: false },
    { title: "COIN ADJUSTMENT", field: "coins", filtering: false },
    {
      title: "STATUS",
      field: "status",
      filterComponent: (props) => (
        <FilterDropdown data={props}>
          <MenuItem value={"Active"}>Active</MenuItem>
          <MenuItem value={"Inactive"}>Inactive</MenuItem>
        </FilterDropdown>
      ),
      render: (rowData) => (
        <div style={statusStyling(rowData.status)}>{rowData.status}</div>
      ),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-col  gap-6 mb-4 md:absolute z-10 right-10">
          <button
            onClick={() => setModal(true)}
            className="py-4 px-10 green text-white font-thin rounded-xl whitespace-nowrap"
          >
            Add Rule
          </button>
        </div>
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            borderBottom: "2px solid #FFFFFF",
            filtering: true,
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderColor: "white",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: () => <EditButton />,
              onClick: (event, rowData) => {
                reset();
                editUser(rowData);
                setTypeCheck(rowData.type);
                setEditForm(false);
              },
            },
            (rowData) => ({
              icon: () =>
                rowData.status === "Active" ? (
                  <DeleteButton />
                ) : (
                  <ReEnableButton />
                ),

              onClick: (event, rowData) => {
                reset();
                deleteUser(rowData);
              },
            }),

            // (rowData) => ({
            //   icon: "delete",
            //   tooltip: "Delete User",
            //   onClick: (event, rowData) =>
            //     confirm("You want to delete " + rowData.name),
            //   disabled: rowData.birthYear < 2000,
            // }),
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${process.env.REACT_APP_BASEURL}/api/v1/allotment?`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              if (query.search) {
                url += "&key=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* Add Rule Modal */}
      <Modal
        show={modal}
        className="w-3/4 lg:w-2/6 md:h-fit"
        close={() => {
          setModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className="flex flex-col gap-6 justify-center align-center"
          >
            <FormInputField
              required
              label={"Rule Title"}
              labelClass={"text-lg font-semibold"}
              type="text"
              name={"title"}
            />
            <FormSelectField
              name={"type"}
              type="text"
              label={"Rule Type"}
              labelClass={"text-lg font-semibold"}
              onChange={(e) => setTypeCheck(e.target.value)}
              required
            >
              <option value="" disabled selected>
                Select Type
              </option>
              <option value="graceTime">Grace Time</option>
              <option value="latePenalty">Late Penalty</option>
              <option value="lateSitting">Late Sitting</option>
            </FormSelectField>

            {/* Rules for Late Penalty */}
            {typeCheck === "latePenalty" && (
              <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4"}>
                <FormInputField
                  required={typeCheck === "latePenalty" ? true : false}
                  label={"Late Arrivals Allowed"}
                  labelClass={"text-lg font-semibold"}
                  type="number"
                  disabled={typeCheck === "latePenalty" ? false : true}
                  placeholder="0"
                  name={"duration"}
                />

                <FormInputField
                  required={typeCheck === "latePenalty" ? true : false}
                  label={"Leave Deduction"}
                  labelClass={"text-lg font-semibold"}
                  type="number"
                  disabled={typeCheck === "latePenalty" ? false : true}
                  placeholder="0"
                  name={"leaveDeduction"}
                />

                <FormInputField
                  required={typeCheck === "latePenalty" ? true : false}
                  label={"Coins Deduction"}
                  labelClass={"text-lg font-semibold"}
                  type="number"
                  disabled={typeCheck === "latePenalty" ? false : true}
                  placeholder="0"
                  name={"coins"}
                />
              </div>
            )}

            {/* Rules for Grace Time */}
            {typeCheck === "graceTime" && (
              <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4"}>
                <FormInputField
                  required={typeCheck === "graceTime" ? true : false}
                  label={"Hours"}
                  labelClass={"text-lg font-semibold"}
                  disabled={typeCheck === "graceTime" ? false : true}
                  type="number"
                  name={"graceHour"}
                />

                <FormInputField
                  required={typeCheck === "graceTime" ? true : false}
                  label={"Minutes"}
                  labelClass={"text-lg font-semibold"}
                  disabled={typeCheck === "graceTime" ? false : true}
                  type="number"
                  name={"graceMinute"}
                />
                <FormInputField
                  required={typeCheck === "graceTime" ? true : false}
                  label={"Coins Awarded"}
                  labelClass={"text-lg font-semibold"}
                  type="number"
                  disabled={typeCheck === "graceTime" ? false : true}
                  placeholder="0"
                  name={"coins"}
                />
              </div>
            )}

            {/* Rules for Late Sitting */}
            {typeCheck === "lateSitting" && (
              <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4"}>
                <FormInputField
                  required={typeCheck === "lateSitting" ? true : false}
                  label={"Duration (In Hours)"}
                  labelClass={"text-lg font-semibold"}
                  type="number"
                  disabled={typeCheck === "lateSitting" ? false : true}
                  placeholder="0"
                  name={"duration"}
                />

                <FormInputField
                  required={typeCheck === "lateSitting" ? true : false}
                  label={"Coins Awarded"}
                  labelClass={"text-lg font-semibold"}
                  type="number"
                  disabled={typeCheck === "lateSitting" ? false : true}
                  placeholder="0"
                  name={"coins"}
                />
              </div>
            )}

            <ErrorDisplay>{error}</ErrorDisplay>
            <div className="grid grid-cols-2 gap-4">
              <input
                onClick={() => setModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              <input
                type="submit"
                value="Add"
                className="green p-3  rounded-lg text-white cursor-pointer"
              />
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Edit Rules Modal */}
      <Modal
        show={editModal}
        className="w-3/4 lg:w-2/6 md:h-fit"
        close={() => {
          setEditModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleEditForm)}
            className="flex flex-col gap-6 justify-center align-center"
          >
            <FormInputField
              required
              disabled={!editForm}
              defaultValue={selectedUser.title}
              label={"Rule Title"}
              labelClass={"text-lg font-semibold"}
              type="text"
              name={"title"}
            />

            <FormSelectField
              name={"type"}
              type="text"
              label={"Rule Type"}
              disabled={!editForm}
              labelClass={"text-lg font-semibold"}
              onChange={(e) => setTypeCheck(e.target.value)}
              required
            >
              <option defaultValue={selectedUser.type} disabled selected>
                {selectedUser.type}
              </option>
              <option value="graceTime">Grace Time</option>
              <option value="latePenalty">Late Penalty</option>
              <option value="lateSitting">Late Sitting</option>
            </FormSelectField>

            {/* Rules for Late Penalty */}
            {typeCheck === "latePenalty" && (
              <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4"}>
                <FormInputField
                  required={typeCheck === "latePenalty" ? true : false}
                  label={"Late Arrivals Allowed"}
                  labelClass={"text-lg font-semibold"}
                  type="number"
                  disabled={!editForm}
                  defaultValue={selectedUser.duration}
                  placeholder="0"
                  name={"duration"}
                />

                <FormInputField
                  required={typeCheck === "latePenalty" ? true : false}
                  label={"Leave Deduction"}
                  labelClass={"text-lg font-semibold"}
                  type="number"
                  disabled={!editForm}
                  defaultValue={selectedUser.leaveDeduction}
                  placeholder="0"
                  name={"leaveDeduction"}
                />

                <FormInputField
                  required={typeCheck === "latePenalty" ? true : false}
                  label={"Coins Deduction"}
                  labelClass={"text-lg font-semibold"}
                  type="number"
                  disabled={!editForm}
                  defaultValue={selectedUser.coins}
                  placeholder="0"
                  name={"coins"}
                />
              </div>
            )}

            {/* Rules for Grace Time */}
            {typeCheck === "graceTime" && (
              <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4"}>
                <FormInputField
                  required={typeCheck === "graceTime" ? true : false}
                  label={"Hours"}
                  labelClass={"text-lg font-semibold"}
                  disabled={!editForm}
                  defaultValue={selectedUser.graceHour}
                  type="number"
                  name={"graceHour"}
                />

                <FormInputField
                  required={typeCheck === "graceTime" ? true : false}
                  label={"Minutes"}
                  labelClass={"text-lg font-semibold"}
                  disabled={!editForm}
                  defaultValue={selectedUser.graceMinute}
                  type="number"
                  name={"graceMinute"}
                />
                <FormInputField
                  required={typeCheck === "graceTime" ? true : false}
                  label={"Coins Awarded"}
                  labelClass={"text-lg font-semibold"}
                  type="number"
                  disabled={!editForm}
                  defaultValue={selectedUser.coins}
                  placeholder="0"
                  name={"coins"}
                />
              </div>
            )}

            {/* Rules for Late Sitting */}
            {typeCheck === "lateSitting" && (
              <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4"}>
                <FormInputField
                  required={typeCheck === "lateSitting" ? true : false}
                  label={"Duration (In Hours)"}
                  labelClass={"text-lg font-semibold"}
                  type="number"
                  disabled={!editForm}
                  defaultValue={selectedUser.duration}
                  placeholder="0"
                  name={"duration"}
                />

                <FormInputField
                  required={typeCheck === "lateSitting" ? true : false}
                  label={"Coins Awarded"}
                  labelClass={"text-lg font-semibold"}
                  type="number"
                  disabled={!editForm}
                  defaultValue={selectedUser.coins}
                  placeholder="0"
                  name={"coins"}
                />
              </div>
            )}

            <ErrorDisplay>{error}</ErrorDisplay>

            <div className="grid grid-cols-2 gap-4">
              <input
                onClick={() => setEditModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              <input
                onClick={() => setEditForm(!editForm)}
                type={editForm ? "button" : "submit"}
                value={editForm ? "Save Changes" : "Edit"}
                className="green p-3  rounded-lg text-white cursor-pointer"
              />
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Delete Rules Modal */}
      <Modal
        show={deleteModal}
        close={() => {
          setDeleteModal(false);
        }}
      >
        <h2 className="text-lg">
          Are you sure you want to <br />
          {selectedUser.status == "Active" ? "deactivate " : "activate "}
          {selectedUser.title}
        </h2>
        <div className="grid grid-cols-2 gap-4 mt-5">
          <input
            onClick={() => setDeleteModal(false)}
            type="button"
            value="Cancel"
            className="grey p-3 rounded-lg text-white cursor-pointer"
          />
          <input
            type="button"
            onClick={() => HandleDeleteForm()}
            value={selectedUser.status == "Active" ? "Deactivate" : "Activate"}
            className="red p-3 rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal>
    </main>
  );
};

export default AllotmentRules;
