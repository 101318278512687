import profile from "../../../images/table/default_profile.svg";
import React, { useState, useRef, useReducer, useEffect } from "react";
import { userSchema } from "../../../components/Validation/Admin/AdminProfileSchema";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import FormInputField from "../../../components/FormInputField";
import FormSelectField from "../../../components/FormSelectField";
import ErrorDisplay from "../../../components/ErrorDisplay";
import { formatDate, removeEmpty } from "../../../components/Functions";
import Image from "../../../components/Image";
import FormImageField from "../../../components/FormImageField";
import PageLoader from "../../../components/PageLoader";
import FormReactSelect from "../../../components/FormReactSelect";
import FormTextArea from "../../../components/FormTextArea";
import { currentDate } from "./../../../components/Functions";

const AddAppraisalForm = () => {
  const [selectedUser, setSelectedUser] = useState({
    photo: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [loaded, setLoaded] = useState(false);

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const HandleEditForm = (data) => {
    console.log(data);

    //combining parameters into an array
    var parameters = [];
    parameters.push(data.para1, data.para2, data.para3, data.para4, data.para5);
    data["parameters"] = parameters;

    //combining ratings into an array
    var ratings = [];
    ratings.push(
      data.value1,
      data.value2,
      data.value3,
      data.value4,
      data.value5
    );

    // //converting the array of strings to an array of numbers
    // const ratingsLength = ratings.length;
    // var ratingsNumber = [];

    // for (var i = 0; i < ratingsLength; i++)
    //   ratingsNumber.push(parseInt(ratings[i]));

    data["ratings"] = ratings;

    //rough work need to improve later
    delete data.para1;
    delete data.para2;
    delete data.para3;
    delete data.para4;
    delete data.para5;
    delete data.value1;
    delete data.value2;
    delete data.value3;
    delete data.value4;
    delete data.value5;

    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      // let formData = new FormData();

      // appending data into formdata
      // Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/appraisal/appraisalResponse`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: newData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setEditForm(false);
          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Form Submitted",
        error: "An error has occurred",
      });
    }
  };

  return (
    <main className="content-container">
      <div className="py-7 px-8 bg-white shadow-2xl rounded-2xl">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(HandleEditForm)}>
            <div className="flex flex-col items-center  gap-8">
              <h2 className="text-2xl font-bold mx-auto text-center">
                APPRAISAL FORM
              </h2>

              <div className="flex flex-row justify-between content-center w-full text-center uppercase font-semibold text-lg mt-3 px-7">
                <span>BY: {localStorage.getItem("name")}</span>
                {/* <span>DEPARTMENT: {selectedUser.department}</span> */}
                <span>DATE: {currentDate()}</span>
              </div>

              <div className="flex flex-col md:flex-row gap-4 justify-between content-center items-center bg-slate-100 w-full text-center uppercase font-semibold text-lg mt-3 px-7 py-4 rounded">
                <div className="flex flex-row items-center">
                  Employee Name
                  <span className="font-thin ml-3">
                    <FormReactSelect
                      apiUrl={`${
                        process.env.REACT_APP_BASEURL
                      }/api/v1/users?&status=Active&supervisedBy=${localStorage.getItem(
                        "id"
                      )}`}
                      searchCriteria={"username"}
                      name={"employee"}
                      getFetchedData={(employee) => {
                        setSelectedUser(employee);
                      }}
                    />
                  </span>
                </div>

                <span>
                  EMAIL{" "}
                  <span className="font-thin ml-3">{selectedUser.email}</span>
                </span>

                <span>
                  Job Title{" "}
                  <span className="font-thin ml-3">
                    {selectedUser.designation}
                  </span>
                </span>

                <span>
                  Employee ID{" "}
                  <span className="font-thin ml-3">{selectedUser.test}</span>
                </span>
                {/* <span>DEPARTMENT: {selectedUser.department}</span> */}
              </div>
            </div>

            <div className="flex flex-col gap-6 mt-7">
              <div className="grid grid-cols-1 md:grid-cols-7 gap-3 w-full">
                <div className="md:col-span-3 grid grid-cols-1 md:grid-cols-2 items-center whitespace-nowrap gap-4">
                  <FormReactSelect
                    apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/appraisal/appraisalform?status=Active`}
                    searchCriteria={"parameter"}
                    name={"para1"}
                    defaultValue=""
                  />
                  <FormSelectField name={"value1"}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </FormSelectField>

                  <FormReactSelect
                    apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/appraisal/appraisalform?status=Active`}
                    searchCriteria={"parameter"}
                    name={"para2"}
                  />

                  <FormSelectField name={"value2"}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </FormSelectField>

                  <FormReactSelect
                    apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/appraisal/appraisalform?status=Active`}
                    searchCriteria={"parameter"}
                    name={"para3"}
                  />

                  <FormSelectField name={"value3"}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </FormSelectField>

                  <FormReactSelect
                    apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/appraisal/appraisalform?status=Active`}
                    searchCriteria={"parameter"}
                    name={"para4"}
                  />

                  <FormSelectField name={"value4"}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </FormSelectField>

                  <FormReactSelect
                    apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/appraisal/appraisalform?status=Active`}
                    searchCriteria={"parameter"}
                    name={"para5"}
                  />

                  <FormSelectField name={"value5"}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </FormSelectField>
                </div>

                <div className="md:col-span-4 flex flex-col gap-4 ">
                  <FormTextArea
                    name={"supervisorComment"}
                    placeholder="Supervisor's Comments"
                    rows="11"
                  />
                </div>
              </div>

              <ErrorDisplay>{error}</ErrorDisplay>

              <input
                type={"submit"}
                value={"Submit"}
                className="green p-3 w-2/4 sm:w-1/4 lg:w-1/6 mt-3 self-center rounded-lg text-white cursor-pointer"
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </main>
  );
};

export default AddAppraisalForm;
