import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { MdArticle, MdBeenhere, MdOutlineGroups } from "react-icons/md";
import CSVComponent from "../../../components/CSVComponent";
import ErrorDisplay from "../../../components/ErrorDisplay";
import Note from "../../../components/Note";
import PageLoader from "../../../components/PageLoader";
import { viewImage } from "./../../../components/Functions";

const CSV = () => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(true);

  const HandleRequest = (data) => {
    console.log(data);

    if (!disabled) {
      setDisabled(true);

      setError();
      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/${data}/export`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);

          viewImage(
            `${process.env.REACT_APP_BASEURL}/uploads/export/${res.data.data}`
          );
        })
        // .then((res) => {
        //   console.log(
        //     `${process.env.REACT_APP_BASEURL}/uploads/export/${res.data.data}`
        //   );
        // })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "This may take a while",
        success: "CSV Generated",
        error: "An error has occurred",
      });
    }
  };

  if (loaded === false) {
    return <PageLoader />;
  } else
    return (
      <main className="content-container">
        <div className="py-7 px-8">
          <div className="flex flex-col md:flex-row hover:shadow-custom-primary flex-wrap items-start gap-10">
            <CSVComponent
              icon={<MdOutlineGroups size={50} className="fill-black" />}
              heading={"Users"}
              name={"users"}
              buttonText={"Download CSV"}
              HandleRequest={HandleRequest}
            />
            <CSVComponent
              icon={<MdBeenhere size={50} className="fill-black" />}
              heading={"Attendance"}
              name={"attendance"}
              buttonText={"Download CSV"}
              HandleRequest={HandleRequest}
            />
            <CSVComponent
              icon={<MdArticle size={50} className="fill-black" />}
              heading={"Leaves"}
              name={"leaveRequests"}
              buttonText={"Download CSV"}
              HandleRequest={HandleRequest}
            />
            <CSVComponent
              icon={<MdArticle size={50} className="fill-black" />}
              heading={"Complains"}
              name={"complain"}
              buttonText={"Download CSV"}
              HandleRequest={HandleRequest}
            />
          </div>

          <Note className="mt-8">
            Make sure to allow pop-ups on your browser.
          </Note>

          <ErrorDisplay>{error}</ErrorDisplay>
        </div>
      </main>
    );
};

export default CSV;
