import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem, Paper } from "@material-ui/core";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import Modal from "../../../components/Modal";
import { userSchema } from "../../../components/Validation/Admin/AddRequest";

import FilterDropdown from "../../../components/MUTable/FilterDropdown";
import ModulesDisplay from "../../../components/MUTable/ModulesDisplay";
import { leaveRequestStyling } from "../../../components/StylingFunctions";

const ModuleTable = (props) => {
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    complainName: "",
    reason: "",
    status: "",
    _id: "",
    createAt: "",
    description: "",
  });
  const [status, setStatus] = useState("Active");
  const [error, setError] = useState("");

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  //For form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== delete obj[k]);
    return obj;
  };

  const HandleForm = (data) => {
    console.log(data);
    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/complain`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          reset();
          setAddModal(false);

          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Request Added ",
        error: "An error has occurred",
      });
    }
  };

  const viewComplaint = (rowData) => {
    setSelectedUser(rowData);
    setModal(true);
  };

  const columns = [
    {
      title: "MODULES REQUEST",
      filtering: false,
      render: (rowData) => (
        <div className="flex flex-col gap-1">
          <ModulesDisplay
            module={rowData.fileManagement}
            moduleName="File Management"
          />
          <ModulesDisplay module={rowData.inventory} moduleName="Inventory" />
          <ModulesDisplay
            module={rowData.leaveManagement}
            moduleName="Leave Management"
          />
          <ModulesDisplay
            module={rowData.rewardSystem}
            moduleName="Reward System"
          />
          <ModulesDisplay module={rowData.shift} moduleName="Shift" />
          <ModulesDisplay
            module={rowData.taskManagement}
            moduleName="Task Management"
          />
          <ModulesDisplay module={rowData.voting} moduleName="Voting" />
          <ModulesDisplay module={rowData.payroll} moduleName="Payroll" />
        </div>
      ),
    },
    // { title: "SUBMITTED BY", field: "username", filtering: false },
    {
      title: "SUBMISSION DATE",
      filtering: false,
      field: "createAt",
      render: (rowData) => rowData.createdAt && rowData.createdAt.slice(0, 10),
    },
    {
      title: "STATUS",
      field: "status",
      filterComponent: (props) => (
        <FilterDropdown data={props}>
          <MenuItem value={"Approved"}>Approved</MenuItem>
          <MenuItem value={"Pending"}>Pending</MenuItem>
          <MenuItem value={"Rejected"}>Rejected</MenuItem>
        </FilterDropdown>
      ),
      render: (rowData) => (
        <div style={leaveRequestStyling(rowData.status)}>{rowData.status}</div>
      ),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-col  gap-6 mb-4 md:absolute z-10 right-10">
          {/* <button
            onClick={() => setAddModal(true)}
            className="py-4 px-10 green text-white font-thin rounded-xl whitespace-nowrap"
          >
            Add Request
          </button> */}
        </div>
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            filtering: true,
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderBottom: "null",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          // actions={[
          //   {
          //     icon: () => <ArrowButton />,
          //     onClick: (event, rowData) => {
          //       viewComplaint(rowData);
          //     },
          //   },
          // ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${process.env.REACT_APP_BASEURL}/api/v1/module?sort=-createdAt`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              if (query.search) {
                url += "&complainName=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* View request */}
      <Modal
        className="w-4/5  md:w-2/5 h-3/4 h-fit"
        show={modal}
        close={() => {
          setModal(false);
        }}
      >
        <div className=" p-3">
          <h2>Module Adjustments Made</h2>
        </div>
      </Modal>

      {/* Add request Modal */}
      <Modal
        className="md:w-1/4"
        show={addModal}
        close={() => {
          setAddModal(false);
        }}
      >
        <form
          onSubmit={handleSubmit(HandleForm)}
          className="flex flex-col gap-6 justify-center align-center"
        >
          <div className="flex flex-col">
            <input
              className="input-form"
              type="text"
              required
              placeholder="Request Title"
              {...register("complainName")}
            />
          </div>

          <div className="flex flex-col">
            {/* <label className="mb-2 text-lg font-semibold">
              Dish Description
            </label> */}
            <textarea
              className="input-form"
              type="text"
              autocomplete="new-password"
              required
              placeholder="Write your message"
              {...register("description")}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <input
              onClick={() => setModal(false)}
              type="button"
              value="Cancel"
              className="red p-3  rounded-lg text-white cursor-pointer"
            />
            <input
              type="submit"
              value="Add"
              className="green p-3  rounded-lg text-white cursor-pointer"
            />
          </div>
        </form>
      </Modal>

      {/* Delete User Modal */}
    </main>
  );
};

export default ModuleTable;
