import { MenuItem, Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import profile from "../../../images/table/default_profile.svg";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import editIcon from "../../../images/table/edit.svg";
import deleteIcon from "../../../images/table/delete.svg";
import reactivateIcon from "../../../images/table/reactivate.svg";
import React, { useState, useRef, useReducer } from "react";
import { userSchema } from "../../../components/Validation/SuperAdmin/ComplainSchema";
import { useForm, FormProvider } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import FormInputField from "../../../components/FormInputField";
import FormTextArea from "../../../components/FormTextArea";
import ArrowButton from "./../../../components/MaterialTableButtons/ArrowButton";
import FilterDropdown from "../../../components/MUTable/FilterDropdown";
import { leaveRequestStyling } from "../../../components/StylingFunctions";

const Complains = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    userId: {
      username: "",
    },
    complainName: "",
    complainMessage: "",
    status: "",
    _id: "",
    createAt: "",
    description: "",
  });
  const [status, setStatus] = useState("Active");
  const [error, setError] = useState("");

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const viewComplaint = (rowData) => {
    setSelectedUser(rowData);
    setModal(true);
  };

  //Setting complain text value
  const HandleRejectForm = (data) => {
    if (!disabled) {
      setDisabled(true);
      setError();

      data["status"] = "Rejected";
      console.log(data);

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/complain/${selectedUser._id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          setModal(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Complain status updated",
        error: "An error has occurred",
      });
    }
  };

  const HandleResolveForm = (data) => {
    if (!disabled) {
      setDisabled(true);
      setError();

      data["status"] = "Resolved";
      console.log(data);

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/complain/${selectedUser._id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          reset();
          setModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          setModal(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Complain status updated",
        error: "An error has occurred",
      });
    }
  };

  const approveRequest = (data) => {
    console.log(data);
    if (!disabled) {
      setDisabled(true);
      setError();

      const data = {
        status: "Resolved",
      };

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/complain/${selectedUser._id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          setModal(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Complain status updated",
        error: "An error has occurred",
      });
    }
  };

  const rejectRequest = (data) => {
    console.log(data);
    if (!disabled) {
      setDisabled(true);
      setError();

      const data = {
        status: "Rejected",
      };

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/complain/${selectedUser._id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          setModal(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Complain status updated",
        error: "An error has occurred",
      });
    }
  };

  const columns = [
    // {
    //   title: "",
    //   field: "image",
    //   filtering: false,
    //   render: (rowData) => (
    //     <img
    //       src={
    //         rowData.image === "no-image.jpg"
    //           ? profile
    //           : `${process.env.REACT_APP_BASEURL}/uploads/offices/${rowData.image}`
    //       }
    //       className="w-12 rounded-full"
    //     />
    //   ),
    // },
    { title: "NAME", field: "complainName", filtering: false },
    { title: "SUBMITTED BY", field: "username", filtering: false },
    {
      title: "SUBMISSION DATE",
      filtering: false,
      field: "createAt",
      render: (rowData) => rowData.createAt && rowData.createAt.slice(0, 10),
    },
    {
      title: "STATUS",
      field: "status",

      filterComponent: (props) => (
        <FilterDropdown data={props}>
          <MenuItem value={"Pending"}>Pending</MenuItem>
          <MenuItem value={"Rejected"}>Rejected</MenuItem>
          <MenuItem value={"Resolved"}>Resolved</MenuItem>
        </FilterDropdown>
      ),

      render: (rowData) => (
        <div style={leaveRequestStyling(rowData.status)}>{rowData.status}</div>
      ),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 bg-white shadow-2xl rounded-2xl">
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            filtering: true,
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderBottom: "null",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: () => <ArrowButton />,
              onClick: (event, rowData) => {
                viewComplaint(rowData);
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${process.env.REACT_APP_BASEURL}/api/v1/complain?`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              if (query.search) {
                url += "&complainName=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* View leave request */}
      <Modal
        className="w-4/5  md:w-2/5 h-3/4 h-fit"
        show={modal}
        close={() => {
          setModal(false);
        }}
      >
        <div className=" p-3">
          <div className="flex flex-col md:flex-row gap-4 justify-between items-center">
            <div className="flex flex-col md:flex-row gap-4 items-center">
              <div>
                <h2 className="text-xl font-bold ">
                  {selectedUser.complainName}
                </h2>
              </div>
            </div>

            <div>
              <h3
                className={`px-5 py-2 border text-base rounded-md  ${
                  selectedUser.status == "Pending"
                    ? "text-custom-yellow border-custom-yellow"
                    : "" + selectedUser.status == "Resolved"
                    ? "text-custom-green border-custom-green"
                    : "" + selectedUser.status == "Rejected"
                    ? "text-custom-red border-custom-red"
                    : ""
                }`}
              >
                {selectedUser.status}
              </h3>
            </div>
          </div>
          <FormProvider {...methods}>
            <div className="flex flex-col text-base gap-4 mt-4">
              <p className="text-base">{selectedUser.description}</p>
              <h3 className="text-sm">
                <strong>Requested by:</strong> {selectedUser.username}
              </h3>
              <h3 className="text-sm">
                <strong>Requested on:</strong>{" "}
                {selectedUser.createAt.slice(0, 10)}
              </h3>
              <div
                className={selectedUser.status !== "Pending" ? "" : "hidden"}
              >
                <h3 className="text-lg font-bold">Message Sent</h3>
                <p className="text-sm">{selectedUser.complainMessage}</p>
              </div>

              <form
                className={selectedUser.status === "Pending" ? "" : "hidden"}
              >
                <FormTextArea
                  type="textarea"
                  name={"complainMessage"}
                  placeholder={"Type a message"}
                />
                <div
                  className={
                    selectedUser.status === "Pending"
                      ? "flex flex-row mt-6 md:justify-center gap-3"
                      : "hidden"
                  }
                >
                  <button
                    onClick={handleSubmit(HandleRejectForm)}
                    className="red text-white rounded-lg h-10 w-32"
                    type="button"
                  >
                    Reject
                  </button>
                  <button
                    onClick={handleSubmit(HandleResolveForm)}
                    className="primary text-white rounded-lg h-10 w-32"
                    type="button"
                  >
                    Resolve
                  </button>
                </div>
              </form>
            </div>
          </FormProvider>
        </div>
      </Modal>

      {/* Edit Office Modal */}

      {/* Delete User Modal */}
    </main>
  );
};

export default Complains;
