import { MenuItem, Paper } from "@material-ui/core";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import FilterDropdown from "../../../components/MUTable/FilterDropdown";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import { leaveRequestStyling } from "../../../components/StylingFunctions";
import profile from "../../../images/table/default_profile.svg";
import ArrowButton from "./../../../components/MaterialTableButtons/ArrowButton";

const LeaveManagement = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    userId: {
      username: "",
    },
    status: "",
    id: "",
    reason: "",
    type: "",
    days: "",
    createAt: "",
  });
  const [status, setStatus] = useState("Active");
  const [error, setError] = useState("");

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  //For form

  const viewComplaint = (rowData) => {
    setSelectedUser(rowData);
    setModal(true);
  };

  const approveRequest = (data) => {
    console.log(data);
    if (!disabled) {
      setDisabled(true);
      setError();

      const data = {
        status: "Approved",
      };

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/leaveRequests/${selectedUser.id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          setModal(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Leave request status updated",
        error: "An error has occurred",
      });
    }
  };

  const rejectRequest = (data) => {
    console.log(data);
    if (!disabled) {
      setDisabled(true);
      setError();

      const data = {
        status: "Rejected",
      };

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/leaveRequests/${selectedUser.id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          setModal(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Leave request status updated",
        error: "An error has occurred",
      });
    }
  };

  const handleImageErr = (err) => {
    err.target.src = profile;
  };

  const columns = [
    { title: "Title", field: "title", filtering: false },
    { title: "SUBMITTED BY", field: "userId.username", filtering: false },
    { title: "LEAVE TYPE", field: "type", filtering: false },
    { title: "LEAVE DURATION", field: "days", filtering: false },
    {
      title: "SUBMISSION DATE",
      filtering: false,
      field: "createAt",
      render: (rowData) => rowData.createAt && rowData.createAt.slice(0, 10),
    },
    {
      title: "STATUS",
      field: "status",
      filterComponent: (props) => (
        <FilterDropdown data={props}>
          <MenuItem value={"Approved"}>Approved</MenuItem>
          <MenuItem value={"Pending"}>Pending</MenuItem>
          <MenuItem value={"Rejected"}>Rejected</MenuItem>
        </FilterDropdown>
      ),

      render: (rowData) => (
        <div style={leaveRequestStyling(rowData.status)}>{rowData.status}</div>
      ),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 bg-white shadow-2xl rounded-2xl">
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderBottom: "null",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: () => <ArrowButton />,
              onClick: (event, rowData) => {
                viewComplaint(rowData);
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${process.env.REACT_APP_BASEURL}/api/v1/leaveRequests/other/1?`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              if (query.search) {
                url += "&title=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* View leave request */}
      <Modal
        className="md:w-3/4 lg:w-2/4 h-3/4 md:h-fit"
        show={modal}
        close={() => {
          setModal(false);
        }}
      >
        <div className=" p-3">
          <div className="flex flex-col md:flex-row gap-4 justify-between items-center">
            <div className="flex flex-col md:flex-row gap-4 items-center">
              <img
                onError={handleImageErr}
                src={
                  selectedUser.image === "no-image.jpg"
                    ? profile
                    : `${process.env.REACT_APP_BASEURL}/uploads/offices/${selectedUser.image}`
                }
                className="h-20 w-20 rounded-full"
                alt=""
              />
              <div>
                <h2 className="text-xl font-bold ">
                  {selectedUser.userId.username}
                </h2>
                <p className="text-base">johndoe@gmail.com</p>
              </div>
            </div>

            <div>
              <h3 style={leaveRequestStyling(selectedUser.status)}>
                {selectedUser.status}
              </h3>
            </div>
          </div>

          <div className="flex flex-col text-base gap-4 mt-4">
            <h1 className="text-xl font-bold">Reason:</h1>
            <p className="text-base">{selectedUser.reason}</p>
            <h3>
              <strong>Leave Type:</strong> {selectedUser.type}
            </h3>
            <h3>
              <strong>Leave Duration:</strong> {selectedUser.days}
            </h3>
            <h3>
              <strong>Submission Date:</strong>{" "}
              {selectedUser.createAt.slice(0, 10)}
            </h3>
            {/* <h3>
              <strong>Leave Remaining:</strong> Sick
            </h3> */}
          </div>

          <div
            className={
              selectedUser.status === "Pending"
                ? "flex flex-row mt-6 md:justify-end gap-3"
                : "hidden"
            }
          >
            <button
              onClick={rejectRequest}
              className="red text-white rounded-lg h-10 w-32"
              type="button"
            >
              Reject
            </button>
            <button
              onClick={approveRequest}
              className="primary text-white rounded-lg h-10 w-32"
              type="button"
            >
              Accept
            </button>
          </div>
        </div>
      </Modal>

      {/* Edit Office Modal */}

      {/* Delete User Modal */}
    </main>
  );
};

export default LeaveManagement;
