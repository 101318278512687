//STYLES
import styles from "./Navbar.module.scss";

//CONTEXT
import { useContext, useState } from "react";
import NavContext from "../../Context/NavContext";

//REACT ROUTER
import { NavLink, useLocation, matchRoutes } from "react-router-dom";

//ICONS
import {
  MdOutlineDashboard,
  MdOutlineAnnouncement,
  MdArticle,
  MdAttachMoney,
  MdHourglassEmpty,
  MdListAlt,
  MdPersonAddAlt1,
  MdOutlineLibraryBooks,
  MdOutlineGroups,
  MdContacts,
  MdOutlineLibraryAddCheck,
  MdOutlineInventory2,
  MdCardGiftcard,
  MdOutlineLibraryAdd,
  MdPermContactCalendar,
  MdAccountBox,
  MdBeenhere,
  MdOutlineRequestQuote,
  MdOutlineEventNote,
  MdOutlineGrading,
  MdOutlineSettingsAccessibility,
  MdOutlineShoppingCart,
  MdUploadFile,
  MdAccessTime,
  MdRequestPage,
  MdRule,
  MdWorkspacesOutline,
  MdOutlineStore,
  MdAssignmentInd,
  MdOutlineAssignmentInd,
  MdPeople,
  MdToc,
  MdSettingsEthernet,
  MdQuestionAnswer,
  MdOutlinePersonalVideo,
  MdMonetizationOn,
  MdTableView,
  MdOutlinePersonOutline,
} from "react-icons/md";

//LOGOS
import logo from "../../images/insignia_text_logo.svg";
import love from "../../images/made-with-love.svg";

import { AiOutlineForm } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { BsPersonBadge, BsFillQuestionSquareFill } from "react-icons/bs";
import { HiOutlineDocumentReport } from "react-icons/hi";

const NavUrl = ({ url, icon, description, dropdownItems }) => {
  const { dropdownStatus, setDropdownStatus } = useState();
  const { nav, setNav } = useContext(NavContext);
  const checkWindowSize = () => {
    if (window.innerWidth < 1024) setNav(!nav);
  };

  const useCurrentPath = () => {
    const location = useLocation();
    let currentPath = location.pathname;
    currentPath = currentPath.substring(1);
    return currentPath;
  };

  return (
    <li className={`${styles.li_navlink}`}>
      <NavLink
        to={`${url}`}
        className={({ isActive }) => (isActive ? styles.active : undefined)}
        onClick={() => {
          checkWindowSize();
        }}
      >
        {icon}
        <span className={`${styles.li_navlink} whitespace-nowrap p-2 mx-2`}>
          {description}
        </span>
      </NavLink>

      <div
        className={
          useCurrentPath().indexOf(url) > -1 ? `${styles.dropdown}` : "hidden"
        }
      >
        <div className={dropdownItems ? "pb-4" : "hidden"}>
          {dropdownItems
            ? dropdownItems.map((dropdownItems) => (
                <li className={`${styles.dropdown}`}>
                  <NavLink
                    to={`${dropdownItems.url}`}
                    className={({ isActive }) =>
                      isActive ? styles.active : undefined
                    }
                  >
                    {" "}
                    {dropdownItems.icon}
                    <span className={`whitespace-nowrap p-2 mx-2`}>
                      {dropdownItems.description}
                    </span>
                  </NavLink>
                </li>
              ))
            : ""}
        </div>
      </div>
    </li>
  );
};

const NavbarAdmin = ({ navDetails }) => {
  const { nav, setNav } = useContext(NavContext);

  return (
    <div
      className={`${styles.navbar_container}  ${
        nav ? styles.navbar_mobile_active : undefined
      }`}
    >
      <nav className={nav ? undefined : styles.nav_small}>
        {/* LOGO */}
        <div className={styles.logo}>
          <img className="h-24 m-auto" src={logo} alt="" />
          <FaTimes
            className={styles.mobile_cancel_icon}
            onClick={() => {
              setNav(!nav);
            }}
          />
        </div>

        {/* MENU */}

        <ul className={`${styles.menu_container}`}>
          {/* FIRST CATEGORY */}
          {/* <span className={styles.categories}>
            {nav ? "Pages" : <BsThreeDots />}
          </span> */}
          <NavUrl
            url="AD/dashboard"
            icon={<MdOutlineDashboard />}
            description="Dashboard"
          />
          <NavUrl
            url="AD/users"
            icon={<MdOutlineGroups />}
            description="Users"
          />
          {navDetails.leaveManagement && (
            <NavUrl
              url="AD/leave-management"
              icon={<MdArticle />}
              description="Leave Management"
              dropdownItems={[
                {
                  description: "Leave Requests",
                  url: "AD/leave-management/leave-requests",
                  icon: <MdOutlineGrading />,
                },
                {
                  description: "Leave Types",
                  url: "AD/leave-management/leave-types",
                  icon: <MdOutlineEventNote />,
                },
              ]}
            />
          )}

          <NavUrl
            url="AD/attendance"
            icon={<MdBeenhere />}
            description="Attendance"
          />

          {navDetails.rewardSystem && (
            <NavUrl
              url="AD/reward"
              icon={<MdCardGiftcard />}
              description="Reward System"
              dropdownItems={[
                {
                  description: "Employee of the Day",
                  url: "AD/reward/employee-day",
                  icon: <MdContacts />,
                },
                {
                  description: "Employee of the Month",
                  url: "AD/reward/employee-month",
                  icon: <MdPermContactCalendar />,
                },
                {
                  description: "Allotment Rules",
                  url: "AD/reward/allotment-rules",
                  icon: <MdRule />,
                },
                {
                  description: "Custom Allotment",
                  url: "AD/reward/custom-allotment",
                  icon: <MdPersonAddAlt1 />,
                },
                {
                  description: "Redeem Shop",
                  url: "AD/reward/redeem-shop",
                  icon: <MdOutlineShoppingCart />,
                },
                {
                  description: "Redeem Requests",
                  url: "AD/reward/redeem-requests",
                  icon: <MdRequestPage />,
                },
              ]}
            />
          )}

          {navDetails.taskManagement && (
            <NavUrl
              url="AD/task-management"
              icon={<MdListAlt />}
              description="Task Management"
              dropdownItems={[
                {
                  description: "Projects",
                  url: "AD/task-management/projects",
                  icon: <MdOutlineLibraryBooks />,
                },
                {
                  description: "Individual Tasks",
                  url: "AD/task-management/solo-tasks",
                  icon: <MdPersonAddAlt1 />,
                },
              ]}
            />
          )}

          {navDetails.inventory && (
            <NavUrl
              url="AD/inventory"
              icon={<MdOutlineInventory2 />}
              description="Inventory"
              dropdownItems={[
                {
                  description: "Inventory Management",
                  url: "AD/inventory/inventory-management",
                  icon: <MdOutlineLibraryAdd />,
                },
                {
                  description: "Inventory Assignment",
                  url: "AD/inventory/inventory-assignment",
                  icon: <MdOutlineLibraryAddCheck />,
                },
              ]}
            />
          )}

          {navDetails.shift && (
            <NavUrl
              url="AD/shifts"
              icon={<MdAccessTime />}
              description="Shifts"
            />
          )}

          <NavUrl
            url="AD/requests"
            icon={<MdOutlineAnnouncement />}
            description="Requests & Complains"
          />

          {navDetails.fileManagement && (
            <NavUrl
              url="AD/file-management"
              icon={<MdUploadFile />}
              description="File Management"
            />
          )}

          <NavUrl
            url="AD/reports"
            icon={<HiOutlineDocumentReport />}
            description="Reports"
            dropdownItems={[
              {
                description: "Monthly Reports",
                url: "AD/reports/monthly-reports",
                icon: <MdOutlineLibraryBooks />,
              },

              {
                description: "User Reports",
                url: "AD/reports/user-reports",
                icon: <MdOutlinePersonOutline />,
              },
            ]}
          />

          {/* <NavUrl
            url="AD/reports"
            icon={<MdAssessment />}
            description="Reports"
            dropdownItems={[
              {
                description: "Tax Policy",
                url: "AD/payroll/tax-policy",
                icon: <MdOutlineEventNote />,
              },
              {
                description: "Grades",
                url: "AD/payroll/grades",
                icon: <MdOutlineSettingsAccessibility />,
              },
              {
                description: "Salaries & Reports",
                url: "AD/payroll/salary",
                icon: <MdOutlineRequestQuote />,
              },
              {
                description: "Bonuses",
                url: "AD/payroll/bonus",
                icon: <MdCardGiftcard />,
              },
            ]}
          /> */}

          <NavUrl
            url="AD/csv"
            icon={<MdTableView />}
            description="Downloadable CSV's"
          />

          {navDetails.voting && (
            <NavUrl
              url="AD/voting"
              icon={<MdHourglassEmpty />}
              description="Voting"
            />
          )}

          {navDetails.payroll && (
            <NavUrl
              url="AD/payroll"
              icon={<MdAttachMoney />}
              description="Payroll"
              dropdownItems={[
                {
                  description: "Tax Policy",
                  url: "AD/payroll/tax-policy",
                  icon: <MdOutlineEventNote />,
                },
                {
                  description: "Allowances",
                  url: "AD/payroll/allowances",
                  icon: <MdMonetizationOn />,
                },
                {
                  description: "Grades",
                  url: "AD/payroll/grades",
                  icon: <MdOutlineSettingsAccessibility />,
                },

                {
                  description: "Salaries & Reports",
                  url: "AD/payroll/salary",
                  icon: <MdOutlineRequestQuote />,
                },
                {
                  description: "Bonuses",
                  url: "AD/payroll/bonus",
                  icon: <MdCardGiftcard />,
                },
              ]}
            />
          )}

          <NavUrl
            url="AD/appraisal"
            icon={<BsPersonBadge />}
            description="Appraisal"
            dropdownItems={[
              {
                description: "Schedule",
                url: "AD/appraisal/schedule",
                icon: <MdOutlineEventNote />,
              },
              {
                description: "Criteria",
                url: "AD/appraisal/criteria",
                icon: <MdToc />,
              },
              {
                description: "Feedback Questions",
                url: "AD/appraisal/feedback-questions",
                icon: <BsFillQuestionSquareFill />,
              },
              {
                description: "Feedback Responses",
                url: "AD/appraisal/feedback-responses",
                icon: <MdQuestionAnswer />,
              },
              {
                description: "Appraisal Parameters",
                url: "AD/appraisal/appraisal-parameters",
                icon: <MdSettingsEthernet />,
              },
              {
                description: "Appraisal Form",
                url: "AD/appraisal/appraisal-form",
                icon: <AiOutlineForm />,
              },
            ]}
          />

          <NavUrl
            url="AD/recruitment"
            icon={<MdAssignmentInd />}
            description="Recruitment"
            dropdownItems={[
              {
                description: "Jobs",
                url: "AD/recruitment/jobs",
                icon: <MdOutlineAssignmentInd />,
              },
              {
                description: "Candidates",
                url: "AD/recruitment/candidates",
                icon: <MdPeople />,
              },
              {
                description: "Interview",
                url: "AD/recruitment/interview",
                icon: <MdOutlinePersonalVideo />,
              },
            ]}
          />

          <NavUrl
            url="AD/modules"
            icon={<MdWorkspacesOutline />}
            description="Modules"
          />

          <NavUrl
            url="AD/profile"
            icon={<MdAccountBox />}
            description="Profile"
          />
          <NavUrl
            url="AD/office-details"
            icon={<MdOutlineStore />}
            description="Office Details"
          />
          {/* <NavUrl
            url="AD/dishes"
            icon={<MdOutlinedFlag />}
            description="Dishes"
          /> */}
          {/* SECOND CATEGORY */}
          {/* <span className={`${styles.categories} ${styles.second_category}`}>
            {nav ? "More" : <BsThreeDots />}
          </span> */}
          {/* <NavUrl
            url="other1"
            icon={<IoMdLogIn />}
            description="Authentication"
          />
          <NavUrl url="other2" icon={<FaReact />} description="ReactJs" /> */}
        </ul>
        {/* MADE WITH LOVE */}
        <div className={nav ? styles.made_love : styles.made_love_hidden}>
          <img src={love} alt="" />
        </div>
      </nav>

      <div
        className={nav ? styles.mobile_nav_background_active : undefined}
        onClick={() => {
          setNav(!nav);
        }}
      ></div>
    </div>
  );
};

export default NavbarAdmin;
