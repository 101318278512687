import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import SignupInputField from "../components/SignUpInput/SignupInputField";
import SignupPasswordField from "../components/SignUpInput/SignupPasswordField";
import { userSchema } from "../components/Validation/LoginSchema";
import eye_slash from "../images/eye-slash.svg";
import eye from "../images/eye.svg";
import logo from "../images/insignia_logo.svg";

const Login = () => {
  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
    mode: "all",
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = methods;

  // const history = useHistory();

  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const HandleForm = (data) => {
    if (!disabled) {
      setDisabled(true);
      setError();
      setLoading(true);

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/auth/portalLogin`,
        method: "POST",
        headers: {
          // Add any auth token here
          "Content-Type": "application/json",
          // authorization: "your token comes here",
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          // setError(res.message);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("role", res.data.user.role);
          localStorage.setItem("name", res.data.user.username);
          localStorage.setItem("photo", res.data.user.photo);
          localStorage.setItem("id", res.data.user._id);
          localStorage.setItem("officeId", res.data.user.officeId);

          // localStorage.setItem("adminProf", res.data.user._id);

          setLoading(false);
          //redirecting
          if (res.data.token) {
            if (res.data.user.role == "superAdmin") {
              console.log(" Logged In");
              navigate("/SU/Dashboard");
            } else if (res.data.user.role == "admin") {
              console.log(" Logged In");

              localStorage.setItem("adminProf", res.data.user.adminProf);
              if (localStorage.getItem("adminProf") === "false")
                navigate("/complete-details");
              else navigate("/AD/dashboard");
            } else if (res.data.user.role == "supervisor") {
              console.log(" Logged In");
              navigate("/SV/dashboard");
            } else setError("Invalid Email or Password");
          }
        })

        // Catch errors if any
        .catch((err) => {
          setLoading(false);
          setDisabled(false);
          setError(err.response.data.error);
          return Promise.reject();
        });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Signed in ",
      //   error: "Unable to Sign in",
      // });
    }
  };

  return (
    <div className="grid h-screen w-full place-items-center bg-white overflow-auto">
      <div className=" flex flex-col md:flex-row items-center text-center gap-4 p-6 md:gap-36 justify-start ">
        <img className="h-32 md:h-36 xl:h-48" src={logo} alt="" />

        <div className="flex flex-col gap-3  md:py-3 md:px-16 lg:py-8 lg:gap-6 md:shadow-3xl  md:rounded-3xl ">
          <div>
            <h1 className="text-2xl whitespace-nowrap font-bold">Sign In</h1>
            <h3 className="">Access Portal</h3>
          </div>

          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(HandleForm)}
              className="flex flex-col justify-center gap-2 "
            >
              <SignupInputField
                type="email"
                placeholder="Email"
                autoComplete="off"
                name="email"
              />

              <SignupPasswordField
                autoComplete="off"
                placeholder="Password"
                name={"password"}
                randomGenerator={false}
              />

              <p className="text-center text-red-700 mt-2  mb-2 w-full">
                {error}
              </p>

              {/* <div className="error-hndl">
            <p className=" mt-4 error-msg">{errors.email?.message}</p>
            <p className=" mt-4 error-msg">{errors.password?.message}</p>
            <p className="error-msg">{error}</p>
          </div> */}

              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <a
                  href="https://attendance.insigniapp.com/"
                  className="px-2 py-2 secondary rounded-lg text-white self-center text-sm xl:text-base b whitespace-nowrap overflow-ellipsis"
                >
                  Attendance
                </a>
                <button className="px-2 py-2 primary rounded-lg text-white  self-center text-sm xl:text-base  whitespace-nowrap">
                  Sign In
                </button>
              </div>

              {loading === true && (
                <div className="mx-auto mt-2">
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              )}
            </form>
          </FormProvider>

          <div>
            <h3 className="text-sm xl:text-base">Don't have an account yet?</h3>
            <Link
              to={"/signup"}
              className="text-lime-500 underline text-sm xl:text-base"
              href=""
            >
              Sign Up
            </Link>
          </div>

          <Link to={"/forgot"} className=" text-lime-500 text-sm xl:text-base">
            Forgot Password?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
