import cross from "../images/modal/close-circle.svg";

const Modal = ({
  show,
  className,
  closeButtonShow = true,
  close,
  bodyClassName,
  children,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div className="custom-modal  p-4">
      <div
        className={`modal-content overflow-auto max-h-90% p-4 animated fadeIn  fixed z-50 pin bg-smoke-dark ${className}`}
      >
        <div className="flex flex-row justify-end items-end">
          {closeButtonShow && (
            <img
              onClick={close}
              className="h-6 cursor-pointer hover:opacity-70"
              src={cross}
              alt=""
            />
          )}
        </div>
        <div className={bodyClassName}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
