import { yupResolver } from "@hookform/resolvers/yup";
import { Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import { roleStyling } from "../../../components/StylingFunctions";
import { userSchema } from "../../../components/Validation/Admin/AddUserSchema";
import profile from "../../../images/table/default_profile.svg";

const EmployeeOfMonth = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [role_check, setRole_check] = useState();
  const [showPass, setShowPass] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [supervisor, setSuperVisor] = useState([]);
  const [editForm, setEditForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    username: "",
    status: "",
    designation: "",
    email: "",
    phone: "",
    JoiningDate: "",
    supervisedBy: {
      username: "",
      _id: "",
    },
    _id: "",
    address: "",
    photo: "",
  });
  const [error, setError] = useState("");

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  //For form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const displayMonth = (date) => {
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let dateVariable = new Date(date.slice(0, 10));
    return `${month[dateVariable.getMonth()]} ${dateVariable.getFullYear()}`;
  };

  const handleImageErr = (err) => {
    err.target.src = profile;
  };

  const columns = [
    {
      title: "NAME",
      field: "employeeId",
      filtering: false,
      render: (rowData) => (
        <>
          <p className="font-bold text-base">
            {rowData.employeeId && rowData.employeeId.username}
          </p>
          <p className="font-normal">
            {rowData.employeeId && rowData.employeeId.email}
          </p>
        </>
      ),
    },
    {
      title: "ROLE",
      field: "employeeId",
      filtering: false,
      render: (rowData) => (
        <div style={roleStyling(rowData.employeeId && rowData.employeeId.role)}>
          {rowData.employeeId && rowData.employeeId.role}
        </div>
      ),
    },
    {
      title: "AWARDED ON",
      field: "createAt",
      filtering: false,
      render: (rowData) => (
        <div
          style={{
            borderRadius: "5px",
            background: "#7FC348",
            color: "white",
            width: "6rem",
            padding: "3px",
            textAlign: "center",
          }}
        >
          {displayMonth(rowData.createAt)}
        </div>
      ),
    },

    {
      title: "SINICOINS",
      field: "totalcoin",
      filtering: false,
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-col  gap-6 mb-4 md:absolute z-10 right-10"></div>
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            borderBottom: "2px solid #FFFFFF",
            filtering: true,
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderColor: "white",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          // actions={[
          //   {
          //     icon: EditIcon,
          //     onClick: (event, rowData) => {
          //       reset();
          //       editUser(rowData);
          //       setEditForm(false);
          //     },
          //   },
          //   (rowData) => ({
          //     icon: rowData.status === "Active" ? DeleteIcon : RotateLeftIcon,

          //     onClick: (event, rowData) => {
          //       reset();
          //       deleteUser(rowData);
          //     },
          //   }),

          //   // (rowData) => ({
          //   //   icon: "delete",
          //   //   tooltip: "Delete User",
          //   //   onClick: (event, rowData) =>
          //   //     confirm("You want to delete " + rowData.name),
          //   //   disabled: rowData.birthYear < 2000,
          //   // }),
          // ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${process.env.REACT_APP_BASEURL}/api/v1/employeeOfTheMonth?`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              if (query.search) {
                url += "&employeeName=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>
    </main>
  );
};

export default EmployeeOfMonth;
