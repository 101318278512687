// for am/pm formatting
import { saveAs } from "file-saver";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
export const formatTime = (time) => {
  if (time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  } else return null;
};

// for am/pm formatting with date
export const formatTimeDate = (time) => {
  if (time) {
    // Removing the date from the time string
    var stringArray = time.split(/(\s+)/);
    time = stringArray[2];

    // Check correct time format and split into components
    // time = time
    //   .toString()
    //   .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    // if (time.length > 1) {
    //   // If time format correct
    //   time = time.slice(1); // Remove full string match value
    //   time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    //   time[0] = +time[0] % 12 || 12; // Adjust hours
    // }
    // return time.join(""); // return adjusted time or original string

    return time;
  } else return null;
};

export const getMonthYear = (date) => {
  var dateObj = new Date(date);

  var monthName = new Array(11);
  monthName[0] = "January";
  monthName[1] = "February";
  monthName[2] = "March";
  monthName[3] = "April";
  monthName[4] = "May";
  monthName[5] = "June";
  monthName[6] = "July";
  monthName[7] = "August";
  monthName[8] = "September";
  monthName[9] = "October";
  monthName[10] = "November";
  monthName[11] = "December";
  var month = monthName[dateObj.getMonth()]; //months from 1-12
  var day = dateObj.getDate();
  var year = dateObj.getFullYear();

  return `${month} ${year}`;
};

export const getDayMonthYear = (date) => {
  var dateObj = new Date(date);

  var monthName = new Array(11);
  monthName[0] = "January";
  monthName[1] = "February";
  monthName[2] = "March";
  monthName[3] = "April";
  monthName[4] = "May";
  monthName[5] = "June";
  monthName[6] = "July";
  monthName[7] = "August";
  monthName[8] = "September";
  monthName[9] = "October";
  monthName[10] = "November";
  monthName[11] = "December";
  var month = monthName[dateObj.getMonth()]; //months from 1-12
  var day = dateObj.getDate();
  var year = dateObj.getFullYear();

  return `${day} ${month} ${year}`;
};

export const removeEmpty = (obj) => {
  Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== delete obj[k]);
  return obj;
};

export const formatDate = (date) => {
  if (date) {
    return date.slice(0, 10);
  } else return null;
};

export const formatOnlyTime = (date) => {
  if (date) {
    return date.slice(11, 16);
  } else return null;
};

export const formatTempDate = (date) => {
  const d = new Date(date);
  return d;
};

export const getDay = (date) => {
  const d = new Date(date);
  var weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";

  var n = weekday[d.getDay()];
  return n;
};

export const getLastDay = (date) => {
  var now = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  var day = ("0" + now.getDate()).slice(-2);
  var month = ("0" + (now.getMonth() + 1)).slice(-2);
  var today = now.getFullYear() + "-" + month + "-" + day;
  return today;
};

export const getFirstDay = (date) => {
  var now = new Date(date.getFullYear(), date.getMonth(), 1);
  var day = ("0" + now.getDate()).slice(-2);
  var month = ("0" + (now.getMonth() + 1)).slice(-2);
  var today = now.getFullYear() + "-" + month + "-" + day;
  return today;
};

export const joinTime = (hour, minute) => {
  const d = new Date();
  d.setHours(hour);
  d.setMinutes(minute);
  let time = d.toLocaleTimeString();

  return time;
};

export const currentDate = () => {
  var now = new Date();
  now.setDate(now.getDate());
  var day = ("0" + now.getDate()).slice(-2);
  var month = ("0" + (now.getMonth() + 1)).slice(-2);
  var today = now.getFullYear() + "-" + month + "-" + day;

  return today;
};

export const tomorrowDate = () => {
  var now = new Date();
  now.setDate(now.getDate() + 1);
  var day = ("0" + now.getDate()).slice(-2);
  var month = ("0" + (now.getMonth() + 1)).slice(-2);
  var tomorrow = now.getFullYear() + "-" + month + "-" + day;

  return tomorrow;
};

export const getNextDay = (date) => {
  var now = new Date(date);
  now.setDate(now.getDate() + 1);
  var day = ("0" + now.getDate()).slice(-2);
  var month = ("0" + (now.getMonth() + 1)).slice(-2);
  var nextDay = now.getFullYear() + "-" + month + "-" + day;

  return nextDay;
};

export const generateRandom = (number) => {
  var length = number;
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
  // let r = (Math.random() + 1).toString(36).substring(12);
  // setPassword(r);
};

export const displayFileList = (data) => {
  var sendFile = [];
  if (data.item) {
    for (let i = 0; i < data.length; i++) {
      sendFile.push(data.item(i));
    }
  }
  return sendFile;
};

export const convertToArray = (object) => {
  let result = object.map((a) => a.value);
  return result;
};

export const convertToArrayDynamic = (object, value) => {
  let result = object.map((a) => a[value]);
  return result;
};

export const convertToArrayId = (object) => {
  if (object) {
    let result = object.map((a) => a._id);
    return result;
  }
};

export const convertToArrayImage = (object) => {
  if (object) {
    let result = object.map((a) => a._id);
    return result;
  }
};

export const convertToArrayName = (object) => {
  if (object) {
    let result = object.map((a) => a.username);
    return result;
  }
};

export const downloadImage = (url, file) => {
  console.log(url);
  saveAs(url, `${file}`); // Download
};

export const downloadFile = (url) => {
  saveAs(url, "_self"); // Download
};

export const viewImage = (url) => {
  window.open(`${url}`, "_blank", "noreferrer"); // View in a new page
};

export const imagePreview = (file) => {
  return URL.createObjectURL(file);
};

export const Unauthorized = () => {
  toast.error("Unauthorized", { duration: 6000 });
};

export const displayItemsPDF = (object, value) => {
  const array = convertToArrayDynamic(object, value);
  return array.join("\n");
};
