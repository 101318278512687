import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
// import {
//   DataTableCell,
//   Table,
//   TableBody,
//   TableCell,
//   TableHeader,
// } from "@david.kucsai/react-pdf-table";
import { formatDate, getMonthYear } from "./Functions";

const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "solid";
const COL1_WIDTH = 30;
const COLN_WIDTH = (100 - COL1_WIDTH) / 3;
const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginVertical: 20,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol1Header: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol1: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    margin: 3,
    fontSize: 10,
  },
  heading: {
    textAlign: "center",
    fontSize: "14px",
    margin: "3px",
    fontWeight: 500,
  },
  subheading: {
    textAlign: "center",
    fontSize: "11px",
    margin: "3px",
    fontWeight: "bold",
  },
  netPay: {
    textAlign: "left",
    fontSize: "11px",
    margin: "6px",
    fontWeight: "bold",
  },
  signatureContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 70,
    justifyContent: "space-between",
  },
  signatureField: {
    textAlign: "center",
    fontSize: "11px",
    borderTop: 1,
    padding: 12,
    margin: "7px",
    fontWeight: "bold",
  },
});

// Create Document Component
const SalaryPDF = ({ data }) => (
  <Document>
    <Page style={styles.body}>
      <View>
        <Text style={styles.heading}>{data.info.officeId.name}</Text>
        <Text style={styles.subheading}>{data.info.officeId.location}</Text>
        <Text style={styles.subheading}>
          Payslip for {getMonthYear(data.info.date)}
        </Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Employee Name</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.info.username}</Text>
          </View>

          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Hours Worked</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.info.userId.hoursWorked}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Employee ID</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.info.userId.test}</Text>
          </View>

          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Days Absent</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.info.deductionDays}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Designation</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.info.userId.designation}</Text>
          </View>

          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Department</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.info.userId.department}</Text>
          </View>

          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Date of Joining</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              {formatDate(data.info.userId.JoiningDate)}
            </Text>
          </View>

          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Gross Salary</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              {data.info.userId.salary +
                data.info.userId.health_allowance +
                data.info.userId.fuel_allowance}
            </Text>
          </View>

          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}> </Text>
          </View>

          <View style={styles.tableCol}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}> </Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Earnings</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>

          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Deductions</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Basic Salary</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.info.userId.salary}</Text>
          </View>

          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Absent Deductions</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              {data.deductions.absentDeduction}
            </Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Fuel Allowances</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              {data.info.userId.fuel_allowance}
            </Text>
          </View>

          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Tax Deductions</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.deductions.taxDeduction}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Health Allowances</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              {data.info.userId.health_allowance}
            </Text>
          </View>

          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Bonuses</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.info.userId.bonus}</Text>
          </View>

          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Gross Salary</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              {data.info.userId.salary +
                data.info.userId.health_allowance +
                data.info.userId.fuel_allowance}
            </Text>
          </View>

          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Total Deductions</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              {data.deductions.totalDeductions}
            </Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}></Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>

          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Net Pay</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.netPay}</Text>
          </View>
        </View>
      </View>

      <View style={styles.signatureContainer}>
        <Text style={styles.signatureField}>Employer's Signature</Text>
        <Text style={styles.signatureField}>Employee's Signature</Text>
      </View>
    </Page>
  </Document>
);

export default SalaryPDF;
