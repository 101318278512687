import DownloadFile from "./DownloadFile/DownloadFile";
import Image from "./Image";

const FileListDisplay = ({ docURL, name, id }) => {
  return (
    <div className="flex flex-col gap-2 border-b-2 p-3 justify-start">
      <h5 className="font-medium text-center sm:text-left">{name}</h5>
      <div className="flex flex-col w-full gap-3">
        {docURL && docURL.map((docURL, i) => <DownloadFile url={docURL} />)}
      </div>
    </div>
  );
};

export default FileListDisplay;
