import { useFormContext } from "react-hook-form";
import ErrorDisplay from "../ErrorDisplay";

const Question = ({
  question,
  totalQuestions,
  answers,
  handleInputChange,
  index,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex flex-col gap-3 w-full ">
      <div>
        <p className="text-custom-secondary font-semibold">
          Question {index} / {totalQuestions}
        </p>
      </div>

      <p className="font-semibold">{question}</p>

      <div className="grid grid-cols-1">
        {answers &&
          answers.map((answer) => (
            <div class="inline items-center w-full">
              <label key={answer._id}>
                <input
                  {...register(`${index}`, {
                    required: {
                      value: true,
                      message: "Please select an option",
                    },
                  })}
                  type="radio"
                  _id={answer._id}
                  value={answer._id}
                  text={answer.text}
                  correct={answer.correct}
                  className="h-0 w-0 peer"
                  onChange={(e) => handleInputChange(e)}
                />
                <div className="peer-checked:bg-custom-green peer-checked:text-white border border-custom-gray hover:bg-custom-green hover:text-white duration-150 cursor-pointer rounded-xl p-3">
                  {answer.text}
                </div>
              </label>
            </div>
          ))}
      </div>
      <ErrorDisplay>{errors[`${index}`]?.message}</ErrorDisplay>
    </div>
  );
};

export default Question;
