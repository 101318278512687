import { NavLink, Outlet, useLocation } from "react-router-dom";

const Grades = () => {
  const location = useLocation();
  let currentPath = location.pathname;
  currentPath = currentPath.substring(1);

  return (
    <div>
      <div className="px-12 py-5 mt-4 flex flex-row justify-start items-center gap-6">
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "navlink"
              : "" + currentPath === "AD/payroll/grades"
              ? "navlink"
              : "inactive-navlink"
          }
          to={"grades-management"}
        >
          Grade Management
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "navlink" : "inactive-navlink"
          }
          to={"salary-method"}
        >
          Edit Salary Method
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default Grades;
