import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { MdListAlt, MdPersonAdd } from "react-icons/md";
import CSVComponent from "../../../components/CSVComponent";
import ErrorDisplay from "../../../components/ErrorDisplay";
import PageLoader from "../../../components/PageLoader";
import {
  displayItemsPDF,
  formatDate,
  getFirstDay,
} from "../../../components/Functions";
import { getLastDay } from "../../../components/Functions";
import { getMonthYear } from "../../../components/Functions";

const MonthlyNewEmployees = ({ date }) => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(true);

  const doc = new jsPDF();

  const HandleRequest = (data) => {
    console.log(getFirstDay(date));
    console.log(getLastDay(date));
    console.log(disabled);

    if (!disabled) {
      setDisabled(true);

      setError();
      const promise = axios({
        // Endpoint to send files
        url: `${
          process.env.REACT_APP_BASEURL
        }/api/v1/users?JoiningDate[gte]=${getFirstDay(
          date
        )}&JoiningDate[lte]=${getLastDay(date)}`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);

          try {
            GeneratePDF(res.data.data);
          } catch (error) {
            throw new Error(error);
          }

          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          setError(err.response.data.error);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "This may take a while",
        success: "New Employees Report Generated",
        error: "An error has occurred",
      });
    }
  };

  const GeneratePDF = (data) => {
    doc.setFontSize(18);
    doc.text("Monthly New Employees Report", 14, 20);

    doc.setFontSize(11);
    doc.text(getMonthYear(date), 14, 28);

    //Code for printing table
    const tableCol = [
      "ID",
      "NAME",
      "EMAIL",
      "NIC",
      "JOINING DATE",
      "CONTACT",
      "DESIGNATION",
      "GENDER",
      "ROLE",
      "SALARY",
      "SHIFT",
    ];

    const tableVal = data.map(
      ({
        test,
        username,
        email,
        nic,
        JoiningDate,
        phone,
        designation,
        gender,
        role,
        shiftId,
        supervisedBy,
      }) => {
        return [
          test,
          username,
          email,
          nic,
          formatDate(JoiningDate),
          phone,
          designation,
          gender,
          role,
          shiftId && shiftId.name,
          supervisedBy && supervisedBy.username,
        ];
      }
    );

    console.log(tableVal);

    autoTable(doc, {
      head: [tableCol],
      body: tableVal,
      styles: {
        cellPadding: 0.8,
        fontSize: 8,
        // lineColor: [44, 62, 80, 0.1],
        // lineWidth: 0.1,
      },
      //   tableLineColor: [231, 76, 60],
      //   tableLineWidth: 1,

      theme: "striped",
      startY: 40,
    });

    doc.save(`${date} Monthly New Employees Report`);
  };

  if (loaded === false) {
    return <PageLoader />;
  } else
    return (
      <div>
        <CSVComponent
          icon={<MdPersonAdd size={50} className="fill-black" />}
          heading={"New Employees"}
          buttonText={"Download Report"}
          HandleRequest={HandleRequest}
        />

        <ErrorDisplay>{error}</ErrorDisplay>
      </div>
    );
};

export default MonthlyNewEmployees;
