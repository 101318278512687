import React, { useState, useRef, useReducer, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import Image from "../../../components/Image";
import PageLoader from "../../../components/PageLoader";
import Questions from "../../../components/FeedbackForm/Questions";

const TalentGameResponses = () => {
  const [selectedUser, setSelectedUser] = useState({
    photo: "",
  });
  const [userInfo, setUserInfo] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");

  const [loaded, setLoaded] = useState(false);

  //For responseid
  let { id } = useParams();

  const getQuestionInfo = () => {
    if (!disabled) {
      setDisabled(true);

      setError();
      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/talentgame/response/${id}`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res.data.data);
          setSelectedUser(res.data.data);

          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          return Promise.reject();
        });

      Promise.all([promise]).then((values) => {
        setLoaded(true);
      });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Data loaded",
      //   error: "An error has occurred",
      // });
    }
  };

  useEffect(() => {
    getQuestionInfo();
  }, []);

  if (loaded === false) {
    return <PageLoader />;
  } else
    return (
      <main className="content-container">
        <div className="py-7 px-8 bg-white shadow-2xl rounded-2xl w-full">
          <div className="flex flex-col items-center  gap-8">
            <h2 className="text-2xl font-bold mx-auto text-center">
              CANDIDATE FEEDBACK FORM
            </h2>

            <div className="text-center">
              <Image src={""} className="h-20" />
              <h3 className="text-lg text-gray-600 mt-3">
                {selectedUser.candidate_name}
              </h3>
              {/* <span className="text-base text-gray-600">zuhaib@gmail.com</span> */}
            </div>

            <div className="flex flex-col gap-3 w-full">
              {selectedUser.questions.map((object, index) => {
                const answer = selectedUser.answers[index].text;
                const answerObject = selectedUser.answers[index];

                return (
                  <Questions
                    index={index}
                    questionList={object.answers}
                    question={object.question}
                    type={"Mcqs"}
                    answerObject={answerObject}
                    answer={answer}
                    username={selectedUser.candidate_name}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </main>
    );
};

export default TalentGameResponses;
