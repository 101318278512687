import Cards from "../../../components/Dashboard/Cards";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useState, useEffect } from "react";
import LineGraph from "../../../components/LineGraph";
import PageLoader from "../../../components/PageLoader";

const Dashboard = () => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [cardData, setCardData] = useState({
    PendingGenericReq: "",
    PendingLeaves: "",
    absentEmployees: "",
  });
  const [graphData, setGraphData] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getCardData();
    getGraphData();
  }, []);

  //Sorting graph API data
  const sortGraphData = (data) => {
    if (data) {
      let newData = [];

      for (const property in data) {
        newData.push({ month: property, value: data[property] });
      }
      console.log(newData);
      setGraphData(newData);
    }
  };

  //For graphs
  const getGraphData = () => {
    setError();
    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/api/v1/attendance/getGraph`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
        sortGraphData(res.data.data[0].data);
        setLoaded(true);
      })
      // Catch errors if any
      .catch((err) => {
        console.log(err);
        return Promise.reject();
      });
    // toast.promise(promise, {
    //   loading: "Loading",
    //   success: "Graph Data loaded",
    //   error: "An error has occurred",
    // });
  };

  const getCardData = (data) => {
    if (!disabled) {
      setDisabled(true);

      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/attendance/getSupervisorData`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setCardData(res.data.data);

          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          return Promise.reject();
        });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Data loaded",
      //   error: "An error has occurred",
      // });
    }
  };

  if (loaded === false) {
    return <PageLoader />;
  } else
    return (
      <main className="content-container">
        <div className="flex flex-col gap-10 mt-4">
          <div>
            <h1 className="text-3xl md:text-5xl font-extrabold mb-6">
              Dashboard
            </h1>
            <div className="mt-4 flex flex-wrap flex-col  md:flex-row gap-5">
              {/* <Cards
              className="grow"
              label={"GENERIC REQUESTS"}
              number={cardData.PendingGenericReq}
            /> */}
              <Cards
                className="grow"
                label={"PENDING LEAVES"}
                number={cardData.PendingLeaves && cardData.PendingLeaves}
                link={"/SV/leave-management"}
                color={"bg-custom-secondary"}
              />
              <Cards
                className="grow"
                label={"TOTAL ABSENT"}
                number={
                  cardData.absentEmployees && cardData.absentEmployees.length
                }
                link={"/SV/attendance"}
                color={"bg-custom-primary"}
              />
            </div>
          </div>

          <div>
            <h1 className="text-2xl font-bold mb-3">
              Monthly Employee Attendance
            </h1>
            <LineGraph data={graphData} />
          </div>
        </div>
      </main>
    );
};

export default Dashboard;
