import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import ErrorDisplay from "../../../components/ErrorDisplay";
import FormInputField from "../../../components/FormInputField";
import FormReactMultiSelect from "../../../components/FormReactMultiSelect";
import { removeEmpty } from "../../../components/Functions";
import Image from "../../../components/Image";
import { userSchema } from "../../../components/Validation/Admin/AdminProfileSchema";
import dollar from "../../../images/table/dollar.svg";

const CustomAllotment = () => {
  const [selectedUser, setSelectedUser] = useState({
    coin: null,
    emloyeeId: null,
  });
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [customSelectValue, setCustomSelectValue] = useState();
  const [selectedImage, setSelectedImage] = useState([]);

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const HandleSubmitForm = (data) => {
    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/rewards`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          reset({
            coin: null,
            employeeId: null,
          });

          setCustomSelectValue(null);
          setSelectedImage([]);
          setEditForm(false);
          setDisabled(false);

          // navigate("/AD/reward/employee-day");
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.error);
          setDisabled(false);

          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Details Updated ",
        error: "An error has occurred",
      });
    }
  };

  return (
    <main className="content-container">
      <div className="py-7 px-8 bg-white shadow-2xl rounded-2xl ">
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleSubmitForm)}
            className="flex flex-col gap-6 justify-center items-center align-center"
          >
            <Image className="w-24 h-24" src={dollar} />

            <div className="flex flex-col gap-5 md:w-3/5 2xl:w-2/5">
              <FormInputField
                labelClass="text-base font-semibold"
                label={"No. Of SiniCoins"}
                type={"number"}
                name={"coin"}
              />
              <FormReactMultiSelect
                apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/users?&status=Active`}
                searchCriteria={"username"}
                name={"employeeId"}
                labelClass="text-base font-semibold"
                label="Select Users"
                setCustomValue={setCustomSelectValue}
                customValue={customSelectValue}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
              />
            </div>
            <ErrorDisplay>{error}</ErrorDisplay>

            <input
              type={"submit"}
              value={"Allot"}
              className="green p-3 w-2/4 sm:w-1/4 lg:w-1/6 mt-3 self-center rounded-lg text-white cursor-pointer"
            />
          </form>
        </FormProvider>
      </div>
    </main>
  );
};

export default CustomAllotment;
