//STYLES
import styles from "./RightNavbar.module.scss";

//HOOKS
import { useContext } from "react";

//CONTEXT
import NavContext from "../../Context/NavContext";

//ICONS , IMAGES
import { MdOutlineMenu } from "react-icons/md";

//Components
import MyProfile from "./Submenus/MyProfile";
import Support from "./Submenus/Support";
import Notifications from "./Submenus/Notifications";
import Search from "./Submenus/Search";

const RightNavbar = () => {
  const { nav, setNav } = useContext(NavContext);

  return (
    <div className={styles.container}>
      {/* BURGER */}
      <div
        className={styles.burger_container}
        onClick={() => {
          setNav(!nav);
        }}
      >
        {/* For burger's gradient */}
        <svg width="0" height="0">
          <linearGradient
            id="blue-gradient"
            x1="100%"
            y1="100%"
            x2="0%"
            y2="0%"
            gradientTransform="333.55deg"
          >
            +
            <stop stopColor="#5FAE98" offset="0.88%" />
            <stop stopColor="#044460" offset="88.47%" />
          </linearGradient>
        </svg>

        <MdOutlineMenu style={{ fill: "url(#blue-gradient)" }} />
      </div>

      {/* ACTIONS */}
      <div className={styles.actions}>
        {/* <Search />
        <Notifications />
        <Support /> */}
      </div>

      {/* My Profile */}
      <MyProfile />
    </div>
  );
};

export default RightNavbar;
