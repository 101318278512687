import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { MdOutlineGroups } from "react-icons/md";
import CSVComponent from "../../../components/CSVComponent";
import ErrorDisplay from "../../../components/ErrorDisplay";
import PageLoader from "../../../components/PageLoader";
import { formatDate, getFirstDay } from "./../../../components/Functions";
import { getLastDay } from "./../../../components/Functions";
import { getMonthYear } from "./../../../components/Functions";

const MonthlyLates = ({ date }) => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(true);

  const doc = new jsPDF();

  const HandleRequest = (data) => {
    console.log(getFirstDay(date));
    console.log(getLastDay(date));
    console.log(disabled);

    if (!disabled) {
      setDisabled(true);

      setError();
      const promise = axios({
        // Endpoint to send files
        url: `${
          process.env.REACT_APP_BASEURL
        }/api/v1/attendance/getAttendanceStatus?attendanceStatus=Late&createAt[gte]=${getFirstDay(
          date
        )}&createAt[lte]=${getLastDay(date)}`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);

          try {
            GeneratePDF(res.data.data);
          } catch (error) {
            throw new Error();
          }

          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          setError(err.response.data.error);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "This may take a while",
        success: "Late Report Generated",
        error: "An error has occurred",
      });
    }
  };

  const GeneratePDF = (data) => {
    doc.setFontSize(18);
    doc.text("Monthly Late Report", 14, 20);

    doc.setFontSize(11);
    doc.text(getMonthYear(date), 14, 28);

    //Code for printing table
    const tableCol = [
      "DATE",
      "USERNAME",
      "EMAIL",
      "STATUS",
      "WORKING HOURS",
      "WORKING MINUTES",
    ];

    const tableVal = data.map(
      ({
        createAt,
        username,
        email,
        attendanceStatus,
        workingHours,
        workingMinutes,
      }) => {
        return [
          formatDate(createAt),
          username,
          email,
          attendanceStatus,
          workingHours,
          workingMinutes,
        ];
      }
    );

    console.log(tableVal);

    autoTable(doc, {
      head: [tableCol],
      body: tableVal,
      styles: { cellPadding: 0.8, fontSize: 8 },
      startY: 40,
    });

    doc.save(`${date} Monthly Late Report`);
  };

  if (loaded === false) {
    return <PageLoader />;
  } else
    return (
      <div>
        <CSVComponent
          icon={<MdOutlineGroups size={50} className="fill-black" />}
          heading={"Lates"}
          buttonText={"Download Report"}
          HandleRequest={HandleRequest}
        />

        <ErrorDisplay>{error}</ErrorDisplay>
      </div>
    );
};

export default MonthlyLates;
