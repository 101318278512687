import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import SignupInputField from "../../../components/SignUpInput/SignupInputField";
import SignupPasswordField from "../../../components/SignUpInput/SignupPasswordField";
import { userSchema } from "../../../components/Validation/LoginSchema";
import eye_slash from "../../../images/eye-slash.svg";
import eye from "../../../images/eye.svg";
import logo from "../../../images/insignia_logo.svg";

const Login = () => {
  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
    mode: "all",
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = methods;

  // const history = useHistory();

  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const HandleForm = (data) => {
    if (!disabled) {
      setDisabled(true);
      setError();
      setLoading(true);

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/auth/recruitmentLogin`,
        method: "POST",
        headers: {
          // Add any auth token here
          "Content-Type": "application/json",
          // authorization: "your token comes here",
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          // setError(res.message);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("role", "");
          localStorage.setItem("username", res.data.user.username);
          localStorage.setItem("id", res.data.user._id);
          localStorage.setItem("talent", res.data.user.talentGame);
          // localStorage.setItem("adminProf", res.data.user._id);

          setLoading(false);
          //redirecting
          if (res.data.token) {
            console.log("Logged In");
            navigate("/CD/talent-game");
          }
        })

        // Catch errors if any
        .catch((err) => {
          setLoading(false);
          setDisabled(false);
          setError(err.response.data.error);
          return Promise.reject();
        });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Signed in ",
      //   error: "Unable to Sign in",
      // });
    }
  };

  return (
    <div className="grid h-screen w-full place-items-center bg-white overflow-auto">
      <div className=" flex flex-col md:flex-row items-center text-center gap-4 p-6 md:gap-36 justify-start ">
        <img className="h-32 md:h-36 xl:h-48" src={logo} alt="" />

        <div className="flex flex-col gap-3  md:py-3 md:px-16 lg:py-8 lg:gap-6 md:shadow-3xl  md:rounded-3xl ">
          <div>
            <h1 className="text-2xl whitespace-nowrap font-bold">Sign In</h1>
            <h3 className="">Recruitment Portal</h3>
          </div>

          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(HandleForm)}
              className="flex flex-col justify-center gap-2 "
            >
              <SignupInputField
                type="email"
                placeholder="Email"
                autoComplete="off"
                name="email"
              />

              <SignupPasswordField
                autoComplete="off"
                placeholder="Password"
                name={"password"}
                randomGenerator={false}
              />

              {/* <div className="error-hndl">
            <p className=" mt-4 error-msg">{errors.email?.message}</p>
            <p className=" mt-4 error-msg">{errors.password?.message}</p>
            <p className="error-msg">{error}</p>
          </div> */}

              <div className="flex flex-col items-center mt-3 gap-3">
                <button className="px-7 py-2 primary rounded-lg text-white  self-center text-sm xl:text-base  whitespace-nowrap">
                  Sign In
                </button>
              </div>

              <p className="text-center text-red-700 mt-2  mb-2 w-full">
                {error}
              </p>

              {loading === true && (
                <div className="mx-auto mt-2">
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              )}
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default Login;
