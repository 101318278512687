import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem, Paper } from "@material-ui/core";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { MdClose } from "react-icons/md";
import ErrorDisplay from "../../../components/ErrorDisplay";
import FormInputField from "../../../components/FormInputField";
import FormSelectField from "../../../components/FormSelectField";
import FormTextArea from "../../../components/FormTextArea";
import {
  convertToArrayDynamic,
  formatDate,
} from "../../../components/Functions";
import DeleteButton from "../../../components/MaterialTableButtons/DeleteButton";
import EditButton from "../../../components/MaterialTableButtons/EditButton";
import ReEnableButton from "../../../components/MaterialTableButtons/ReEnableButton";
import Modal from "../../../components/Modal";
import FilterDropdown from "../../../components/MUTable/FilterDropdown";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import { statusStyling } from "../../../components/StylingFunctions";
import { userSchema } from "../../../components/Validation/Admin/AddQuestionSchema";
import profile from "../../../images/table/default_profile.svg";

const FeedbackQuestions = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    name: "",
    arrivalTime: "",
    departureTime: "",
  });
  const [error, setError] = useState("");

  //For setting the question as either an mcq or descriptive
  const [questionType, setQuestionType] = useState("Descriptive");

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    handleSubmit,
    reset,
    register,
    control,
    formState: { errors },
  } = methods;

  //For dynamic form fields
  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
    rules: { minLength: 2 },
  });

  const deleteUser = (rowData) => {
    setSelectedUser(rowData);
    setDeleteModal(true);
  };

  const editUser = (rowData) => {
    setSelectedUser(rowData);
    reset({
      question: rowData.question,
      type: rowData.type,
      options: rowData.options,
    });
    setQuestionType(rowData.type);
    setEditModal(true);
  };

  //Adding user
  const HandleForm = (data) => {
    if (data.type === "Descriptive") delete data.options;

    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      // //removing empty strings
      // const newData = removeEmpty(data);
      // console.log(newData);
      // let formData = new FormData();

      // Commenting it out because the API might not support formData
      // // appending data into formdata
      // Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/appraisal/feedback`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setModal(false);
          setDisabled(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Question Added",
        error: "An error has occurred",
      });
    }
  };

  const HandleEditForm = (data) => {
    if (data.type === "Mcqs") {
      data["options_id"] = convertToArrayDynamic(data.options, "_id");
      data["options_text"] = convertToArrayDynamic(data.options, "text");
    }

    delete data.options;

    console.log(data);

    if (!disabled) {
      setDisabled(true);

      setError();

      // //removing empty strings
      // const newData = removeEmpty(data);
      // console.log(newData);
      // let formData = new FormData();

      // Commenting it out because the API might not support formData
      // // appending data into formdata
      // Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/appraisal/feedback/${selectedUser._id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          setEditForm(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Question Updated",
        error: "An error has occurred",
      });
    }
  };

  const HandleDeleteForm = () => {
    setDeleteModal(false);
    if (!disabled) {
      setDisabled(true);

      const promise = axios({
        // Endpoint to send files
        url: `${
          selectedUser.status === "Active"
            ? `${process.env.REACT_APP_BASEURL}/api/v1/appraisal/feedback/${selectedUser._id}/deactivate`
            : `${process.env.REACT_APP_BASEURL}/api/v1/appraisal/feedback/${selectedUser._id}/activate`
        }`,
        method: `PUT`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Question Updated",
        error: "An error has occurred",
      });
    }
  };

  const handleImageErr = (err) => {
    err.target.src = profile;
  };

  const columns = [
    {
      title: "QUESTION",
      filtering: false,
      field: "question",
      render: (rowData) => (
        <span className="line-clamp-1">{rowData.question}</span>
      ),
    },
    { title: "TYPE", field: "type", filtering: false },
    {
      title: "CREATED AT",
      field: "createAt",
      filtering: false,
      render: (rowData) => formatDate(rowData.createAt),
    },
    {
      title: "STATUS",
      field: "status",
      filterComponent: (props) => (
        <FilterDropdown data={props}>
          <MenuItem value={"Active"}>Active</MenuItem>
          <MenuItem value={"Inactive"}>Inactive</MenuItem>
        </FilterDropdown>
      ),
      render: (rowData) => (
        <div style={statusStyling(rowData.status)}>{rowData.status}</div>
      ),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-col  gap-6 mb-4 md:absolute z-10 right-10">
          <button
            onClick={() => {
              setModal(true);
              reset({});
              setQuestionType("Descriptive");
            }}
            className="py-4 px-10 green text-white font-thin rounded-xl whitespace-nowrap"
          >
            Add Question
          </button>
        </div>
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            borderBottom: "2px solid #FFFFFF",
            filtering: true,
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderColor: "white",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: () => <EditButton />,
              onClick: (event, rowData) => {
                reset();
                editUser(rowData);
                setEditForm(false);
              },
            },
            (rowData) => ({
              icon: () =>
                rowData.status === "Active" ? (
                  <DeleteButton />
                ) : (
                  <ReEnableButton />
                ),

              onClick: (event, rowData) => {
                reset();
                deleteUser(rowData);
              },
            }),

            // (rowData) => ({
            //   icon: "delete",
            //   tooltip: "Delete User",
            //   onClick: (event, rowData) =>
            //     confirm("You want to delete " + rowData.name),
            //   disabled: rowData.birthYear < 2000,
            // }),
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${process.env.REACT_APP_BASEURL}/api/v1/appraisal/feedback?`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              if (query.search) {
                url += "&question=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* Add Question Modal */}
      <Modal
        show={modal}
        className="w-3/4 lg:w-3/6 md:h-fit"
        close={() => {
          setModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className="flex flex-col gap-6 justify-center align-center"
          >
            <FormTextArea name={"question"} required label={"Question"} />

            <FormSelectField
              name={"type"}
              required
              label={"Type"}
              onChange={(e) => setQuestionType(e.target.value)}
            >
              <option value="Descriptive">Descriptive</option>
              <option value="Mcqs">Mcqs</option>
            </FormSelectField>

            {questionType === "Mcqs" && (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {fields.map(({ id, text }, index) => (
                    <div className="relative" key={id}>
                      <FormInputField
                        type="text"
                        required
                        defaultValue={text}
                        label={`Option ${index + 1}`}
                        name={`options.${index}.text`}
                      />

                      {index ? (
                        <MdClose
                          type="button"
                          className="absolute right-0 top-4 cursor-pointer hover:opacity-70"
                          onClick={() => remove(index)}
                        />
                      ) : null}
                    </div>
                  ))}
                </div>

                <ErrorDisplay>{errors["options"]?.message}</ErrorDisplay>
                <button
                  className="green p-3 rounded-lg text-white cursor-pointer"
                  type="button"
                  onClick={() => append({ text: "" })}
                >
                  Add Option
                </button>
              </>
            )}

            <ErrorDisplay>{error}</ErrorDisplay>

            <div className="grid grid-cols-2 gap-4 ">
              <input
                onClick={() => setModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              <input
                type="submit"
                value="Add"
                className="green p-3  rounded-lg text-white cursor-pointer"
              />
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Edit Question Modal */}
      <Modal
        show={editModal}
        className="w-3/4 lg:w-3/6 md:h-fit"
        close={() => {
          setEditModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleEditForm)}
            className="flex flex-col gap-6 justify-center align-center"
          >
            <FormTextArea
              name={"question"}
              disabled={!editForm}
              label={"Question"}
            />

            <FormSelectField
              name={"type"}
              disabled={!editForm}
              label={"Type"}
              onChange={(e) => setQuestionType(e.target.value)}
            >
              <option value={selectedUser.type} disabled selected>
                {selectedUser.type}
              </option>
              <option value="Descriptive">Descriptive</option>
              <option value="Mcqs">Mcqs</option>
            </FormSelectField>

            {questionType === "Mcqs" && (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {fields.map(({ id, text }, index) => (
                    <div className="relative" key={id}>
                      <FormInputField
                        type="text"
                        disabled={!editForm}
                        defaultValue={text}
                        label={`Option ${index + 1}`}
                        name={`options.${index}.text`}
                      />

                      {/* {editForm && index ? (
                        <MdClose
                          type="button"
                          className="absolute right-0 top-4 cursor-pointer hover:opacity-70"
                          onClick={() => remove(index)}
                        />
                      ) : null} */}
                    </div>
                  ))}
                </div>

                {editForm && (
                  <ErrorDisplay>{errors["options"]?.message}</ErrorDisplay>
                )}
                {/* {editForm && (
                  <button
                    className="green p-3 rounded-lg text-white cursor-pointer"
                    type="button"
                    onClick={() => append({ text: "" })}
                  >
                    Add Option
                  </button>
                )} */}
              </>
            )}

            <ErrorDisplay>{error}</ErrorDisplay>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                onClick={() => setEditModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              {editForm === true ? (
                <input
                  type={"submit"}
                  value={"Save Changes"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                />
              ) : (
                <button
                  onClick={() => setEditForm(true)}
                  type={"button"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                >
                  Edit{" "}
                </button>
              )}
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Delete Question Modal */}
      <Modal
        show={deleteModal}
        close={() => {
          setDeleteModal(false);
        }}
      >
        <h2 className="text-lg">
          Are you sure you want to {""}
          {selectedUser.status == "Active" ? "deactivate " : "activate "}
          <br />
          <strong>{selectedUser.question}</strong>
        </h2>
        <div className="grid grid-cols-2 gap-4 mt-5">
          <input
            onClick={() => setDeleteModal(false)}
            type="button"
            value="Cancel"
            className="grey p-3 rounded-lg text-white cursor-pointer"
          />
          <input
            type="button"
            onClick={() => HandleDeleteForm()}
            value={selectedUser.status == "Active" ? "Deactivate" : "Activate"}
            className="red p-3 rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal>
    </main>
  );
};

export default FeedbackQuestions;
