import { MenuItem, Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import profile from "../../../images/table/default_profile.svg";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import React, { useState, useRef, useReducer, useEffect } from "react";
import { userSchema } from "../../../components/Validation/Admin/AddTask";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import {
  convertToArrayId,
  convertToArrayImage,
  convertToArrayName,
  formatDate,
  removeEmpty,
} from "../../../components/Functions";
import FormInputField from "../../../components/FormInputField";
import ErrorDisplay from "../../../components/ErrorDisplay";
import EditButton from "./../../../components/MaterialTableButtons/EditButton";
import DeleteButton from "./../../../components/MaterialTableButtons/DeleteButton";
import ReEnableButton from "./../../../components/MaterialTableButtons/ReEnableButton";
import FormReactSelect from "../../../components/FormReactSelect";
import FormTextArea from "../../../components/FormTextArea";
import ArrowButton from "../../../components/MaterialTableButtons/ArrowButton";
import { useLocation } from "react-router-dom";
import PageLoader from "../../../components/PageLoader";
import ProjectDesc from "../../../components/ProjectView/ProjectDesc";

import "file-upload-with-preview/dist/file-upload-with-preview.min.css";
import { FileUploadWithPreview } from "file-upload-with-preview";
import MultiFileSelect from "../../../components/ProjectView/MultiFileSelect";
import FormReactMultiSelect from "../../../components/FormReactMultiSelect";
import ManageUsers from "../../../components/ProjectView/ManageUsers";
import INDInputField from "../../../components/IndependantFormFields/INDInputField";
import INDITextAreaField from "../../../components/IndependantFormFields/INDITextAreaField";
import INDITimeField from "../../../components/IndependantFormFields/INDITimeField";
import ManageFiles from "../../../components/ProjectView/ManageFiles";
import ManageComments from "./../../../components/ProjectView/ManageComments";
import INDSelectField from "./../../../components/IndependantFormFields/INDSelectField";
import FilterDropdown from "../../../components/MUTable/FilterDropdown";
import { statusStyling } from "../../../components/StylingFunctions";

const SoloTasks = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    name: "",
    arrivalTime: "",
    departureTime: "",
  });
  const [error, setError] = useState("");

  //For File Attachments
  const [selectedImages, setSelectedImages] = useState([]);

  //For Users to assign
  const [customSelectValue, setCustomSelectValue] = useState();
  const [userImage, setUserImage] = useState([]);

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  // const forceUpdate = React.useReducer((bool) => !bool)[1];

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const deleteUser = (rowData) => {
    setSelectedUser(rowData);
    setDeleteModal(true);
  };

  const editUser = (rowData) => {
    setSelectedUser(rowData);
    reset({
      project: rowData.project,
      description: rowData.description,
    });
    setEditModal(true);
  };

  //Adding user
  const HandleForm = (data) => {
    console.log(data);

    // console.log(selectedImages);
    // console.log(state._id);

    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);

      console.log(newData);
      let formData = new FormData();

      for (let index = 0; index < data.members.length; index++) {
        formData.append("members", data.members[index]);
      }

      delete newData["members"];

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      // appending additonal data into formdata
      for (let index = 0; index < selectedImages.length; index++) {
        formData.append("attachment", selectedImages[index]);
      }

      // for (let index = 0; index < data.members.length; index++) {
      //   formData.append("members", data.members[index]);
      // }

      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      // formData.append("projectId", state._id);

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/task`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setModal(false);
          setDisabled(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Project Added ",
        error: "An error has occurred",
      });
    }
  };

  const HandleEditForm = (data) => {
    console.log(data);
    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/project/${selectedUser._id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          setEditForm(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Task Updated",
        error: "An error has occurred",
      });
    }
  };

  const HandleDeleteForm = () => {
    setDeleteModal(false);
    if (!disabled) {
      setDisabled(true);

      const promise = axios({
        // Endpoint to send files
        url: `${
          selectedUser.status !== "Inactive"
            ? `${process.env.REACT_APP_BASEURL}/api/v1/task/${selectedUser._id}/deactivate`
            : `${process.env.REACT_APP_BASEURL}/api/v1/task/${selectedUser._id}/activate`
        }`,
        method: `PUT`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Project Updated ",
        error: "An error has occurred",
      });
    }
  };

  const handleImageErr = (err) => {
    err.target.src = profile;
  };

  const columns = [
    {
      title: "TASK",
      field: "taskDetail",
      filtering: false,
      render: (rowData) => (
        <span className="line-clamp-1 font-bold">{rowData.taskDetail}</span>
      ),
    },
    {
      title: "DESCRIPTION",
      filtering: false,
      field: "description",
      render: (rowData) => (
        <span className="line-clamp-2">{rowData.description}</span>
      ),
    },
    {
      title: "CREATED AT",
      field: "createdAt",
      filtering: false,
      render: (rowData) => formatDate(rowData.createAt),
    },

    {
      title: "DEADLINE",
      field: "endDate",
      filtering: false,
      render: (rowData) => formatDate(rowData.endDate),
    },

    {
      title: "STATUS",
      field: "status",
      filterComponent: (props) => (
        <FilterDropdown data={props}>
          <MenuItem value={"Active"}>Active</MenuItem>
          <MenuItem value={"Inactive"}>Inactive</MenuItem>
        </FilterDropdown>
      ),
      render: (rowData) => (
        <div style={statusStyling(rowData.status)}>{rowData.status}</div>
      ),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-col  gap-6 mb-4 md:absolute z-10 right-10">
          <button
            onClick={() => {
              setModal(true);
              setSelectedImages([]);
              setCustomSelectValue([]);
              setUserImage([]);
              setError();

              reset({});
            }}
            className="py-4 px-10 green text-white font-thin rounded-xl whitespace-nowrap"
          >
            Add Task
          </button>
        </div>
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            borderBottom: "2px solid #FFFFFF",
            filtering: true,
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderColor: "white",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: () => <ArrowButton />,
              onClick: (event, rowData) => {
                reset();
                editUser(rowData);
                setEditForm(false);
              },
            },
            (rowData) => ({
              icon: () =>
                rowData.status !== "Inactive" ? (
                  <DeleteButton />
                ) : (
                  <ReEnableButton />
                ),

              onClick: (event, rowData) => {
                reset();
                deleteUser(rowData);
              },
            }),

            // (rowData) => ({
            //   icon: "delete",
            //   tooltip: "Delete User",
            //   onClick: (event, rowData) =>
            //     confirm("You want to delete " + rowData.name),
            //   disabled: rowData.birthYear < 2000,
            // }),
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${process.env.REACT_APP_BASEURL}/api/v1/task?`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              if (query.search) {
                url += "&username=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* Add Task Modal */}
      <Modal
        show={modal}
        className="w-5/6 lg:w-4/6 xl:w-3/6 md:h-fit "
        bodyClassName="px-5"
        close={() => {
          setModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className="flex flex-col gap-6 justify-center align-center"
          >
            <MultiFileSelect
              selectedImages={selectedImages}
              setSelectedImages={setSelectedImages}
            />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormInputField
                name={"taskDetail"}
                label={"Task's Name"}
                labelClass="text-lg font-semibold"
                type={"text"}
              />

              <FormInputField
                name={"startDate"}
                label={"Start Date"}
                labelClass="text-lg font-semibold"
                type={"text"}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
              />

              <FormInputField
                name={"endDate"}
                label={"End Date"}
                labelClass="text-lg font-semibold"
                type={"text"}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
              />

              <FormInputField
                name={"endTime"}
                label={"End Time"}
                labelClass="text-lg font-semibold"
                type={"text"}
                onFocus={(e) => (e.target.type = "time")}
                onBlur={(e) => (e.target.type = "text")}
              />
            </div>

            <FormReactMultiSelect
              apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/users?&status=Active`}
              searchCriteria={"username"}
              name={"members"}
              label="Select Users"
              setCustomValue={setCustomSelectValue}
              customValue={customSelectValue}
              selectedImage={userImage}
              setSelectedImage={setUserImage}
            />

            {/* <FormReactSelect
              apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/users?&status=Active`}
              searchCriteria={"username"}
              name={"userId"}
              label="Select User"
            /> */}

            <FormTextArea
              name={"description"}
              label={"Description"}
              labelClass="text-lg font-semibold"
              type={"text"}
            />

            <ErrorDisplay>{error}</ErrorDisplay>

            <div className="grid grid-cols-2 gap-4 ">
              <input
                onClick={() => setModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              <input
                type="submit"
                value="Add"
                className="grey p-3  rounded-lg text-white cursor-pointer"
              />
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Edit Project Modal */}

      <Modal
        show={editModal}
        className="w-11/12 lg:w-5/6 xl:w-6/12 md:h-fit "
        close={() => {
          setEditModal(false);
          refreshTable();
        }}
      >
        <FormProvider {...methods}>
          <div className="flex flex-col gap-8 justify-center align-center  md:w-3/4">
            <h2 className="uppercase text-xl font-bold">
              <INDInputField
                name={"taskDetail"}
                taskId={selectedUser._id}
                className="uppercase text-xl font-bold"
                customUrl={`${process.env.REACT_APP_BASEURL}/api/v1/task/${selectedUser._id}`}
                initialValue={selectedUser.taskDetail}
              />
            </h2>

            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-4">
                <h4 className="text-base ">Status:</h4>
                <div className="font-medium">
                  {
                    <INDSelectField
                      name={"status"}
                      taskId={selectedUser._id}
                      customUrl={`${process.env.REACT_APP_BASEURL}/api/v1/task/${selectedUser._id}`}
                      initialValue={selectedUser.status}
                      className=""
                    />
                  }
                </div>
              </div>
              <div className="flex flex-row gap-4">
                <h4 className="text-base ">Deadline:</h4>
                <div className="font-medium">
                  {
                    <INDITimeField
                      name={"endDate"}
                      taskId={selectedUser._id}
                      customUrl={`${process.env.REACT_APP_BASEURL}/api/v1/task/${selectedUser._id}`}
                      initialValue={selectedUser.endDate}
                      type={"date"}
                    />
                  }
                </div>
              </div>

              <p className="text-base  ">Members:</p>
              <ManageUsers
                users={selectedUser.members}
                taskId={selectedUser._id}
                prefix={`${process.env.REACT_APP_IMAGE_PROFILE}`}
              />

              <p className="text-base  ">Attachments:</p>
              <ManageFiles
                users={selectedUser.attachment}
                taskId={selectedUser._id}
                prefix={`${process.env.REACT_APP_IMAGE_TASK}`}
              />
            </div>

            <div className="flex flex-col gap-3">
              <h3 className="uppercase text-xl font-bold">Task Description</h3>

              <INDITextAreaField
                name={"description"}
                taskId={selectedUser._id}
                customUrl={`${process.env.REACT_APP_BASEURL}/api/v1/task/${selectedUser._id}`}
                initialValue={selectedUser.description}
              />
            </div>

            <div className="flex flex-col gap-3 mb-6">
              <h3 className="uppercase text-xl font-bold">Activity</h3>

              <ManageComments
                name={"description"}
                taskId={selectedUser._id}
                customUrl={`${process.env.REACT_APP_BASEURL}/api/v1/task/${selectedUser._id}`}
                initialValue={selectedUser.description}
              />
            </div>

            <ErrorDisplay>{error}</ErrorDisplay>

            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                onClick={() => setEditModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              {editForm === true ? (
                <input
                  type={"submit"}
                  value={"Save Changes"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                />
              ) : (
                <button
                  onClick={() => setEditForm(true)}
                  type={"button"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                >
                  Edit{" "}
                </button>
              )}
            </div> */}
          </div>
        </FormProvider>
      </Modal>

      {/* Delete Project Modal */}
      <Modal
        show={deleteModal}
        close={() => {
          setDeleteModal(false);
        }}
      >
        <h2 className="text-lg">
          Are you sure you want to <br />
          {selectedUser.status !== "Inactive" ? "deactivate " : "activate "}
          {selectedUser.taskDetail}
        </h2>
        <div className="grid grid-cols-2 gap-4 mt-5">
          <input
            onClick={() => setDeleteModal(false)}
            type="button"
            value="Cancel"
            className="grey p-3 rounded-lg text-white cursor-pointer"
          />
          <input
            type="button"
            onClick={() => HandleDeleteForm()}
            value={
              selectedUser.status !== "Inactive" ? "Deactivate" : "Activate"
            }
            className="red p-3 rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal>
    </main>
  );
};

export default SoloTasks;
