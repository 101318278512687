import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem, Paper } from "@material-ui/core";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import ErrorDisplay from "../../../components/ErrorDisplay";
import FormReactSelect from "../../../components/FormReactSelect";
import { formatDate, removeEmpty } from "../../../components/Functions";
import EditButton from "../../../components/MaterialTableButtons/EditButton";
import Modal from "../../../components/Modal";
import FilterDropdown from "../../../components/MUTable/FilterDropdown";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import { statusStyling } from "../../../components/StylingFunctions";
import { userSchema } from "../../../components/Validation/Admin/InventoryAssignmentSchema";
import profile from "../../../images/table/default_profile.svg";
import DeleteButton from "./../../../components/MaterialTableButtons/DeleteButton";
import ReEnableButton from "./../../../components/MaterialTableButtons/ReEnableButton";
import FormInputField from "../../../components/FormInputField";

const InventoryAssignment = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    inventoryId: {
      name: "",
    },
  });
  const [error, setError] = useState("");

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const deleteUser = (rowData) => {
    setSelectedUser(rowData);
    setDeleteModal(true);
  };

  const editUser = (rowData) => {
    setSelectedUser(rowData);
    reset({
      userId: rowData.userId,
      inventoryId: rowData.inventoryId._id,
      assigned_quantity: rowData.assigned_quantity,
    });
    setEditModal(true);
  };

  //Adding user
  const HandleForm = (data) => {
    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/inventoryAssignment`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setModal(false);
          setDisabled(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Assigned ",
        error: "An error has occurred",
      });
    }
  };

  const HandleEditForm = (data) => {
    console.log(data);
    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/inventoryAssignment/${selectedUser._id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Assignment Updated",
        error: "An error has occurred",
      });
    }
  };

  const HandleDeleteForm = () => {
    setDeleteModal(false);
    if (!disabled) {
      setDisabled(true);

      const promise = axios({
        // Endpoint to send files
        url: `${
          selectedUser.status === "Active"
            ? `${process.env.REACT_APP_BASEURL}/api/v1/inventoryAssignment/${selectedUser._id}/deactivate`
            : `${process.env.REACT_APP_BASEURL}/api/v1/inventoryAssignment/${selectedUser._id}/activate`
        }`,
        method: `PUT`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Assignment Updated ",
        error: "An error has occurred",
      });
    }
  };

  const handleImageErr = (err) => {
    err.target.src = profile;
  };

  const columns = [
    { title: "NAME", field: "username", filtering: false },
    { title: "ASSIGNED ITEM", field: "inventoryId.name", filtering: false },
    {
      title: "ASSIGNED ON",
      filtering: false,
      field: "createAt",
      render: (rowData) => formatDate(rowData.createAt),
    },
    {
      title: "STATUS",
      field: "status",
      filterComponent: (props) => (
        <FilterDropdown data={props}>
          <MenuItem value={"Active"}>Active</MenuItem>
          <MenuItem value={"Inactive"}>Inactive</MenuItem>
        </FilterDropdown>
      ),
      render: (rowData) => (
        <div style={statusStyling(rowData.status)}>{rowData.status}</div>
      ),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-col  gap-6 mb-4 md:absolute z-10 right-10">
          <button
            onClick={() => setModal(true)}
            className="py-4 px-10 green text-white font-thin rounded-xl whitespace-nowrap"
          >
            Assign Item
          </button>
        </div>
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            borderBottom: "2px solid #FFFFFF",
            filtering: true,
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderColor: "white",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: () => <EditButton />,
              onClick: (event, rowData) => {
                reset();
                editUser(rowData);
                setEditForm(false);
              },
            },
            (rowData) => ({
              icon: () =>
                rowData.status === "Active" ? (
                  <DeleteButton />
                ) : (
                  <ReEnableButton />
                ),

              onClick: (event, rowData) => {
                reset();
                deleteUser(rowData);
              },
            }),

            // (rowData) => ({
            //   icon: "delete",
            //   tooltip: "Delete User",
            //   onClick: (event, rowData) =>
            //     confirm("You want to delete " + rowData.name),
            //   disabled: rowData.birthYear < 2000,
            // }),
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${process.env.REACT_APP_BASEURL}/api/v1/inventoryAssignment?`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              if (query.search) {
                url += "&username=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* Add Assignment Modal */}
      <Modal
        show={modal}
        className="w-3/4 lg:w-3/6 md:h-fit"
        close={() => {
          setModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className="flex flex-col gap-6 justify-center align-center"
          >
            <div className="grid grid-cols-1  gap-4 whitespace-nowrap">
              <FormReactSelect
                apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/users?&status=Active`}
                searchCriteria={"username"}
                name={"userId"}
                label="Select User"
              />
              <FormReactSelect
                apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/inventory?&status=Active`}
                searchCriteria={"name"}
                name={"inventoryId"}
                label="Select Inventory Item"
              />
              <FormInputField
                name={"assigned_quantity"}
                required
                type="number"
                label="Assigned Quantity"
                placeholder="0"
              />
            </div>

            <ErrorDisplay>{error}</ErrorDisplay>

            <div className="grid grid-cols-2 gap-4">
              <input
                onClick={() => setModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              <input
                type="submit"
                value="Add"
                className="green p-3  rounded-lg text-white cursor-pointer"
              />
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Edit Assignment Modal */}
      <Modal
        show={editModal}
        className="w-3/4 lg:w-3/6 md:h-fit"
        close={() => {
          setEditModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleEditForm)}
            className="flex flex-col gap-6 justify-center align-center"
          >
            {/* Only for displaying to the user */}
            {/* <div
              className={
                !editForm
                  ? "hidden"
                  : `grid grid-cols-1 md:grid-cols-2 gap-4 whitespace-nowrap`
              }
            >
              <FormInputField
                name={"userId"}
                defaultValue={selectedUser.username}
                isDisabled={}
                label="User"
              />

              <FormInputField
                name={"inventoryId"}
                defaultValue={selectedUser.inventoryId.name}
                disabled
                label="Inventory Item"
              />
            </div> */}

            {/* For Editing */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 whitespace-nowrap">
              <FormReactSelect
                apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/users?&status=Active`}
                searchCriteria={"username"}
                name={"userId"}
                isDisabled={!editForm}
                label="User"
                required
                placeholder={selectedUser.username}
              />

              <FormReactSelect
                apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/inventory?&status=Active`}
                searchCriteria={"name"}
                name={"inventoryId"}
                isDisabled={!editForm}
                required
                label="Inventory Item"
                placeholder={selectedUser.inventoryId.name}
              />

              <FormInputField
                placeholder="0"
                type="number"
                disabled={!editForm}
                label={"Assigned Quantity"}
                name={"assigned_quantity"}
              />
            </div>

            <ErrorDisplay>{error}</ErrorDisplay>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                onClick={() => setEditModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              {editForm === true ? (
                <input
                  type={"submit"}
                  value={"Save Changes"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                />
              ) : (
                <button
                  onClick={() => setEditForm(true)}
                  type={"button"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                >
                  Edit{" "}
                </button>
              )}
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Delete User Modal */}
      <Modal
        show={deleteModal}
        close={() => {
          setDeleteModal(false);
        }}
      >
        <h2 className="text-lg">
          Are you sure you want to <br />
          {selectedUser.status == "Active" ? "deactivate " : "activate "}
          {selectedUser.name}
        </h2>
        <div className="grid grid-cols-2 gap-4 mt-5">
          <input
            onClick={() => setDeleteModal(false)}
            type="button"
            value="Cancel"
            className="grey p-3 rounded-lg text-white cursor-pointer"
          />
          <input
            type="button"
            onClick={() => HandleDeleteForm()}
            value={selectedUser.status == "Active" ? "Deactivate" : "Activate"}
            className="red p-3 rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal>
    </main>
  );
};

export default InventoryAssignment;
