import { NavLink, Outlet, useLocation } from "react-router-dom";

const Modules = () => {
  const location = useLocation();
  let currentPath = location.pathname;
  currentPath = currentPath.substring(1);

  return (
    <div>
      <div className="px-12 py-5 mt-4  flex flex-row justify-start items-center gap-6">
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "navlink"
              : "" + currentPath === "AD/modules"
              ? "navlink"
              : "inactive-navlink"
          }
          to={"module-req"}
        >
          Module Requests
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "navlink" : "inactive-navlink"
          }
          to={"module-records"}
        >
          Module Records
        </NavLink>
      </div>

      <Outlet />
    </div>
  );
};

export default Modules;
