import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import ReactDatePicker from "react-datepicker";

import ErrorDisplay from "../../../components/ErrorDisplay";
import PageLoader from "../../../components/PageLoader";
import { viewImage } from "./../../../components/Functions";
import MonthlyComplains from "./MonthlyComplains";
import MonthlyRequests from "./MonthlyRequests";

const MonthlyReports = () => {
  const [disabled, setDisabled] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(true);

  const HandleRequest = (data) => {
    console.log(data);

    if (!disabled) {
      setDisabled(true);

      setError();
      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/${data}/export`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);

          viewImage(
            `${process.env.REACT_APP_BASEURL}/uploads/export/${res.data.data}`
          );
        })
        // .then((res) => {
        //   console.log(
        //     `${process.env.REACT_APP_BASEURL}/uploads/export/${res.data.data}`
        //   );
        // })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "This may take a while",
        success: "Monthly Reports Generated",
        error: "An error has occurred",
      });
    }
  };

  if (loaded === false) {
    return <PageLoader />;
  } else
    return (
      <main className="content-container">
        <ReactDatePicker
          selected={selectedDate}
          onChange={(date) => {
            setSelectedDate(date);
          }}
          dateFormat="MMMM yyyy"
          showMonthYearPicker
          placeholderText="Select Month"
          className="input-calendar cursor-pointer"
        />

        <div className="py-7 px-8">
          <div className="flex flex-col md:flex-row hover:shadow-custom-primary flex-wrap items-start gap-10">
            <MonthlyComplains date={selectedDate} />
            <MonthlyRequests date={selectedDate} />
          </div>

          <ErrorDisplay>{error}</ErrorDisplay>
        </div>
      </main>
    );
};

export default MonthlyReports;
