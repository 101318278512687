import { MenuItem, Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import profile from "../../../images/table/default_profile.svg";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import EditIcon from "@material-ui/icons/Edit";
import { MdDone } from "react-icons/md";
import React, { useState, useRef, useReducer } from "react";
import { userSchema } from "../../../components/Validation/SuperAdmin/AddOfficeSchema";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import { NavLink } from "react-router-dom";
import Image from "../../../components/Image";
import FormInputField from "../../../components/FormInputField";
import EditButton from "./../../../components/MaterialTableButtons/EditButton";
import DoneButton from "./../../../components/MaterialTableButtons/DoneButton";
import FormImageField from "../../../components/FormImageField";
import FilterDropdown from "../../../components/MUTable/FilterDropdown";
import { statusStyling } from "../../../components/StylingFunctions";

const OfficeRequests = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    name: "",
    status: "",
    _id: "",
    location: "",
    phone: "",
    image: "",
    rewardSystem: "",
    leaveManagement: "",
    voting: "",
    attendance: "",
  });
  const [status, setStatus] = useState("Active");
  const [error, setError] = useState("");

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === "" || obj[key] == null) {
        delete obj[key];
      }
    });
    return obj;
  };

  const approveRequest = (data) => {
    if (!disabled) {
      setDisabled(true);
      setError();

      const data = {
        requestStatus: "Approved",
        status: "Active",
      };
      console.log(data);
      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/offices/${selectedUser._id}`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);

          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Office status updated",
        error: "An error has occurred",
      });
    }
  };

  const rejectRequest = (data) => {
    console.log(data);
    if (!disabled) {
      setDisabled(true);
      setError();

      const data = {
        requestStatus: "Rejected",
        status: "Inactive",
      };

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/offices/${selectedUser._id}`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);

          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Office status updated",
        error: "An error has occurred",
      });
    }
  };

  const deleteUser = (rowData) => {
    setSelectedUser(rowData);
    setDeleteModal(true);
  };

  const editUser = (rowData) => {
    setSelectedUser(rowData);
    reset({
      name: rowData.name,
      phone: rowData.phone,
      username: rowData.adminId && rowData.adminId.username,
      email: rowData.adminId && rowData.adminId.email,
    });
    setEditModal(true);
  };

  const approveUser = (rowData) => {
    setSelectedUser(rowData);
    setModal(true);
  };

  const HandleEditForm = (data) => {
    if (data.file) {
      const file = data["file"];
      data["file"] = file[0];
    }
    console.log(data);
    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/offices/${selectedUser._id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          refreshTable();
          setEditModal(false);
          setEditForm(false);
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Office status updated",
        error: "An error has occurred",
      });
    }
  };

  const HandleApproveForm = (data) => {
    const file = data["file"];
    data["file"] = file[0];

    console.log(data);
    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/offices/${selectedUser._id}`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err.response.data.error);
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Office Added ",
        error: "An error has occurred",
      });
    }
  };

  const HandleDeleteForm = () => {
    setDeleteModal(false);
    if (!disabled) {
      setDisabled(true);

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/offices/${selectedUser._id}?requestStatus=Pending`,
        method: `${selectedUser.status === "Active" ? "DELETE" : "POST"}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);

          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Office Updated ",
        error: "An error has occurred",
      });
    }
  };

  const handleImageErr = (err) => {
    err.target.src = profile;
  };

  const columns = [
    {
      title: "",
      field: "image",
      filtering: false,
      render: (rowData) => (
        <img
          src={
            rowData.image === "no-image.jpg"
              ? profile
              : `${process.env.REACT_APP_BASEURL}/uploads/offices/${rowData.image}`
          }
          className="w-12 rounded-full"
        />
      ),
    },
    { title: "NAME", field: "name", filtering: false },
    { title: "ADMIN'S NAME", field: "adminId.username", filtering: false },
    { title: "PHONE", field: "phone", filtering: false },

    // {
    //   title: "ADMIN",
    //   field: "Payment title",
    //   render: (rowData) => rowData.payment_date.split(" ").slice(0, 1),
    // },
    // {
    //   title: "CREATED DATE",
    //   field: "createdAt",
    //   render: (rowData) => rowData.createdAt.split(" ").slice(0, 1),
    // },
    // {
    //   title: "NO. OF EMPLOYEES",
    //   field: "employees",
    //   render: (rowData) => rowData.payment_date.split(" ").slice(0, 1),
    // },
    {
      title: "STATUS",
      field: "status",
      filterComponent: (props) => (
        <FilterDropdown data={props}>
          <MenuItem value={"Active"}>Active</MenuItem>
          <MenuItem value={"Inactive"}>Inactive</MenuItem>
        </FilterDropdown>
      ),
      render: (rowData) => (
        <div style={statusStyling(rowData.status)}>{rowData.status}</div>
      ),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            filtering: true,

            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderBottom: "null",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: () => <EditButton />,
              onClick: (event, rowData) => {
                reset();
                editUser(rowData);
                setEditForm(false);
              },
            },
            {
              icon: () => <DoneButton />,
              onClick: (event, rowData) => {
                approveUser(rowData);
              },
            },

            // (rowData) => ({
            //   icon: "delete",
            //   tooltip: "Delete User",
            //   onClick: (event, rowData) =>
            //     confirm("You want to delete " + rowData.name),
            //   disabled: rowData.birthYear < 2000,
            // }),
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${process.env.REACT_APP_BASEURL}/api/v1/offices?requestStatus=Pending`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              if (query.search) {
                url += "&name=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* Edit Modal */}
      <Modal
        show={editModal}
        className=" w-4/5 md:w-3/5 lg:w-2/5"
        close={() => {
          setEditModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleEditForm)}
            className="flex flex-col gap-6 justify-center align-center"
          >
            <FormImageField
              name={"file"}
              edit={editForm}
              value={selectedUser.image}
              prefix={`${process.env.REACT_APP_IMAGE_OFFICE}`}
            />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <FormInputField
                  disabled={!editForm}
                  label={"Office Name"}
                  defaultValue={selectedUser.name}
                  name={"name"}
                />
              </div>

              <FormInputField
                disabled={!editForm}
                label={"Contact Number"}
                defaultValue={selectedUser.phone}
                name={"phone"}
              />
              {selectedUser.adminId && (
                <FormInputField
                  disabled={!editForm}
                  label={"Admin's Name"}
                  defaultValue={selectedUser.adminId.name}
                  name={"username"}
                />
              )}

              {selectedUser.adminId && (
                <FormInputField
                  disabled={!editForm}
                  label={"Admin's Email"}
                  defaultValue={selectedUser.adminId.email}
                  name={"email"}
                />
              )}
            </div>

            {/* <div>
              <h2 className="text-lg font-bold">Selected Modules</h2>

              <div className="flex flex-row gap-4 mt-2 flex-wrap">
                <div className="flex flex-row gap-3 text-left">
                  <input
                    type="checkbox"
                    disabled={!editForm}
                    checked={editForm ? null : selectedUser.leaveManagement}
                    name="leaveManagement"
                    {...register("leaveManagement")}
                  />
                  <label htmlFor="leaveManagement">Leave Management</label>
                </div>

                <div className="flex flex-row gap-3">
                  <input
                    type="checkbox"
                    disabled={!editForm}
                    checked={editForm ? null : selectedUser.rewardSystem}
                    name="rewardSystem"
                    {...register("rewardSystem")}
                  />
                  <label htmlFor="rewardSystem">Reward System</label>
                </div>

                <div className="flex flex-row gap-3">
                  <input
                    type="checkbox"
                    disabled={!editForm}
                    checked={editForm ? null : selectedUser.voting}
                    name="voting"
                    {...register("voting")}
                  />
                  <label htmlFor="voting">Voting</label>
                </div>

                <div className="flex flex-row gap-3">
                  <input
                    type="checkbox"
                    disabled={!editForm}
                    checked={editForm ? null : selectedUser.attendance}
                    name="attendance"
                    {...register("attendance")}
                  />
                  <label htmlFor="attendance">Attendance</label>
                </div>

                <div className="flex flex-row gap-3">
                  <input
                    type="checkbox"
                    disabled={!editForm}
                    checked={editForm ? null : selectedUser.payroll}
                    name="payroll"
                    {...register("payroll")}
                  />
                  <label htmlFor="attendance">Payroll</label>
                </div>
              </div>
            </div> */}
            {/* For editing office details */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                onClick={() => setEditModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              {editForm === true ? (
                <input
                  type={"submit"}
                  value={"Save Changes"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                />
              ) : (
                <button
                  onClick={() => setEditForm(true)}
                  type={"button"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                >
                  Edit{" "}
                </button>
              )}
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Accept Request Office Modal */}
      <Modal
        show={modal}
        className=" w-4/5 md:w-3/5 lg:w-2/5"
        close={() => {
          setModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleApproveForm)}
            className="flex flex-col gap-6 justify-center align-center"
          >
            <Image
              className={"h-28 w-28 mx-auto rounded-full"}
              src={
                selectedUser.image === "no-image.jpg"
                  ? profile
                  : `${process.env.REACT_APP_BASEURL}/uploads/offices/${selectedUser.image}`
              }
              alt=""
            />
            <div className={editForm ? "flex flex-col" : "hidden"}>
              <label className="mb-2 text-lg font-semibold">Image</label>
              <input
                type="file"
                {...register("file")}
                onChange={(e) => console.log(e.target.files)}
              />
            </div>
            {/* <input
            type="file"
            
            onChange={(e) => console.log(e.target.files)}
            {...register("file")}
          /> */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <FormInputField
                  disabled={!editForm}
                  label={"Office Name"}
                  value={editForm ? null : selectedUser.name}
                  name={"name"}
                />
              </div>

              <FormInputField
                disabled={!editForm}
                label={"Contact Number"}
                value={editForm ? null : selectedUser.phone}
                name={"phone"}
              />
              {selectedUser.adminId && (
                <FormInputField
                  disabled={!editForm}
                  label={"Admin's Name"}
                  value={editForm ? null : selectedUser.adminId.username}
                  name={"username"}
                />
              )}

              {selectedUser.adminId && (
                <FormInputField
                  disabled={!editForm}
                  label={"Admin's Email"}
                  value={editForm ? null : selectedUser.adminId.email}
                  name={"email"}
                />
              )}
            </div>
            {/* <div>
              <h2 className="text-lg font-bold">Selected Modules</h2>

              <div className="flex flex-row gap-4 mt-2 flex-wrap">
                <div className="flex flex-row gap-3 text-left">
                  <input
                    type="checkbox"
                    disabled={!editForm}
                    checked={editForm ? null : selectedUser.leaveManagement}
                    name="leaveManagement"
                    {...register("leaveManagement")}
                  />
                  <label htmlFor="leaveManagement">Leave Management</label>
                </div>

                <div className="flex flex-row gap-3">
                  <input
                    type="checkbox"
                    disabled={!editForm}
                    checked={editForm ? null : selectedUser.rewardSystem}
                    name="rewardSystem"
                    {...register("rewardSystem")}
                  />
                  <label htmlFor="rewardSystem">Reward System</label>
                </div>

                <div className="flex flex-row gap-3">
                  <input
                    type="checkbox"
                    disabled={!editForm}
                    checked={editForm ? null : selectedUser.voting}
                    name="voting"
                    {...register("voting")}
                  />
                  <label htmlFor="voting">Voting</label>
                </div>

                <div className="flex flex-row gap-3">
                  <input
                    type="checkbox"
                    disabled={!editForm}
                    checked={editForm ? null : selectedUser.payroll}
                    name="payroll"
                    {...register("payroll")}
                  />
                  <label htmlFor="payroll">Payroll</label>
                </div>

                <div className="flex flex-row gap-3">
                  <input
                    type="checkbox"
                    disabled={!editForm}
                    checked={editForm ? null : selectedUser.attendance}
                    name="attendance"
                    {...register("attendance")}
                  />
                  <label htmlFor="attendance">Attendance</label>
                </div>
              </div>
            </div> */}
            {/* For editing office details */}
            <div className="flex flex-row mt-6 md:justify-center gap-3">
              <button
                onClick={approveRequest}
                className="primary text-white rounded-lg h-10 w-32 cursor-pointer"
                type="button"
              >
                Accept
              </button>
              <button
                onClick={rejectRequest}
                className="red text-white rounded-lg h-10 w-32 cursor-pointer"
                type="button"
              >
                Reject
              </button>
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Delete User Modal */}
      <Modal
        show={deleteModal}
        close={() => {
          setDeleteModal(false);
        }}
      >
        <h2 className="text-lg">
          Are you sure you want to <br />
          {selectedUser.status == "Active" ? "deactivate " : "activate "}
          {selectedUser.name}
        </h2>
        <div className="grid grid-cols-2 gap-4 mt-5">
          <input
            onClick={() => setDeleteModal(false)}
            type="button"
            value="Cancel"
            className="grey p-3 rounded-lg text-white cursor-pointer"
          />
          <input
            type="button"
            onClick={() => HandleDeleteForm()}
            value={selectedUser.status == "Active" ? "Deactivate" : "Activate"}
            className="red p-3 rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal>
    </main>
  );
};

export default OfficeRequests;
