//STYLES
import styles from "./Navbar.module.scss";

//CONTEXT
import { useContext } from "react";
import NavContext from "../../Context/NavContext";

//REACT ROUTER
import { NavLink, useLocation } from "react-router-dom";

//ICONS
import {
  MdOutlineDashboard,
  MdOutlineStore,
  MdOutlineAnnouncement,
  MdWorkspacesOutline,
  MdPostAdd,
  MdOutlineLibraryAdd,
  MdOutlineShoppingCart,
  MdToc,
  MdOutlineRequestQuote,
  MdTableView,
  MdOutlineLibraryBooks,
} from "react-icons/md";

//LOGOS
import logo from "../../images/insignia_text_logo.svg";
import love from "../../images/made-with-love.svg";

import { FaTimes } from "react-icons/fa";
import { RiQuestionnaireLine } from "react-icons/ri";
import { BsFillQuestionSquareFill } from "react-icons/bs";
import { HiOutlineDocumentReport } from "react-icons/hi";

const NavUrl = ({ url, icon, description, dropdownItems }) => {
  const { nav, setNav } = useContext(NavContext);
  const checkWindowSize = () => {
    if (window.innerWidth < 1024) setNav(!nav);
  };

  const useCurrentPath = () => {
    const location = useLocation();
    let currentPath = location.pathname;
    currentPath = currentPath.substring(1);
    return currentPath;
  };

  return (
    <li className={`${styles.li_navlink}`}>
      <NavLink
        to={`${url}`}
        className={({ isActive }) => (isActive ? styles.active : undefined)}
        onClick={() => {
          checkWindowSize();
        }}
      >
        {icon}
        <span className={`${styles.li_navlink} whitespace-nowrap p-2 mx-2`}>
          {description}
        </span>
      </NavLink>

      <div
        className={
          useCurrentPath().indexOf(url) > -1 ? `${styles.dropdown}` : "hidden"
        }
      >
        <div className={dropdownItems ? "pb-4" : "hidden"}>
          {dropdownItems
            ? dropdownItems.map((dropdownItems) => (
                <li className={`${styles.dropdown}`}>
                  <NavLink
                    to={`${dropdownItems.url}`}
                    className={({ isActive }) =>
                      isActive ? styles.active : undefined
                    }
                  >
                    {" "}
                    {dropdownItems.icon}
                    <span className={`whitespace-nowrap p-2 mx-2`}>
                      {dropdownItems.description}
                    </span>
                  </NavLink>
                </li>
              ))
            : ""}
        </div>
      </div>
    </li>
  );
};

const Navbar = ({ navDetails }) => {
  const { nav, setNav } = useContext(NavContext);

  return (
    <div
      className={`${styles.navbar_container}  ${
        nav ? styles.navbar_mobile_active : undefined
      }`}
    >
      <nav className={nav ? undefined : styles.nav_small}>
        {/* LOGO */}
        <div className={styles.logo}>
          <img className="h-24 m-auto" src={logo} alt="" />
          <FaTimes
            className={styles.mobile_cancel_icon}
            onClick={() => {
              setNav(!nav);
            }}
          />
        </div>

        {/* MENU */}
        <ul className={`${styles.menu_container}`}>
          {/* FIRST CATEGORY */}
          {/* <span className={styles.categories}>
            {nav ? "Pages" : <BsThreeDots />}
          </span> */}
          <NavUrl
            url="SU/dashboard"
            icon={<MdOutlineDashboard />}
            description="Dashboard"
          />
          <NavUrl
            url="SU/offices"
            icon={<MdOutlineStore />}
            description="Offices"
          />
          <NavUrl
            url="SU/complains"
            icon={<MdOutlineAnnouncement />}
            description="Complains"
          />
          <NavUrl
            url="SU/custom-categories"
            icon={<MdPostAdd />}
            description="Custom Categories"
            dropdownItems={[
              {
                description: "Inventory Types",
                url: "SU/custom-categories/inventory-types",
                icon: <MdOutlineLibraryAdd />,
              },
              {
                description: "Allowance Types",
                url: "SU/custom-categories/allowance-types",
                icon: <MdOutlineRequestQuote />,
              },
              {
                description: "Product Types",
                url: "SU/custom-categories/product-types",
                icon: <MdOutlineShoppingCart />,
              },
            ]}
          />
          <NavUrl
            url="SU/talent-game"
            icon={<RiQuestionnaireLine />}
            description="Talent Game"
            dropdownItems={[
              {
                description: "Criteria",
                url: "SU/talent-game/criteria",
                icon: <MdToc />,
              },
              {
                description: "Questions",
                url: "SU/talent-game/questions",
                icon: <BsFillQuestionSquareFill />,
              },
            ]}
          />

          <NavUrl
            url="SU/reports"
            icon={<HiOutlineDocumentReport />}
            description="Reports"
            dropdownItems={[
              {
                description: "Monthly Reports",
                url: "SU/reports/monthly-reports",
                icon: <MdOutlineLibraryBooks />,
              },
            ]}
          />

          <NavUrl
            url="SU/csv"
            icon={<MdTableView />}
            description="Downloadable CSV's"
          />

          {/* <NavUrl
            url="SU/product-types"
            icon={<MdOutlineShoppingCart />}
            description="Custom Categories"
          /> */}
          <NavUrl
            url="SU/modules"
            icon={<MdWorkspacesOutline />}
            description="Modules"
          />
          {/* SECOND CATEGORY */}
          {/* <span className={`${styles.categories} ${styles.second_category}`}>
            {nav ? "More" : <BsThreeDots />}
          </span> */}
          {/* <NavUrl
            url="other1"
            icon={<IoMdLogIn />}
            description="Authentication"
          />
          <NavUrl url="other2" icon={<FaReact />} description="ReactJs" /> */}
        </ul>

        {/* MADE WITH LOVE */}
        <div className={nav ? styles.made_love : styles.made_love_hidden}>
          <img src={love} alt="" />
        </div>
      </nav>

      <div
        className={nav ? styles.mobile_nav_background_active : undefined}
        onClick={() => {
          setNav(!nav);
        }}
      ></div>
    </div>
  );
};

export default Navbar;
