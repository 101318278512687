import { useState } from "react";
import { MdOutlineRestartAlt } from "react-icons/md";
import { useFormContext } from "react-hook-form";

import eye from "../../images/eye.svg";
import eye_slash from "../../images/eye-slash.svg";
import { generateRandom } from "../Functions";

const SignupPasswordField = ({
  name,
  className,
  peak = true,
  label,
  randomGenerator = true,
  labelClass = "text-lg font-semibold",
  ...otherProps
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [password, setPassword] = useState();
  const [editPassword, setEditPassword] = useState(false);
  const [showPass, setShowPass] = useState(false);

  return (
    <div className="flex flex-col w-full">
      {label && (
        <label className={`inline-block mb-2 ${labelClass} `}>{label}</label>
      )}

      <div className="relative ">
        {peak && (
          <img
            className="absolute h-5 w-5 hover:opacity-60 cursor-pointer top-2/4  right-5 -translate-y-2/4"
            src={showPass ? eye_slash : eye}
            onClick={() => {
              setShowPass((prevCheck) => !prevCheck);
              console.log(showPass);
            }}
            alt=""
          />
        )}
        {randomGenerator && (
          <MdOutlineRestartAlt
            onClick={() => setPassword(generateRandom(8))}
            className="absolute h-5 w-5 hover:opacity-60 cursor-pointer top-2/4  right-11 -translate-y-2/4"
          />
        )}

        <input
          name="password"
          value={password}
          type={showPass ? "text" : "password"}
          autoComplete="off"
          onChange={(event) => setPassword(event.target.value)}
          className={`${className} input-form-signup`}
          min={0}
          {...register(name)}
          {...otherProps}
        />
      </div>
      <div>
        <p className="text-red-700 mt-2 text-left">{errors[name]?.message}</p>
      </div>
    </div>
  );
};

export default SignupPasswordField;
