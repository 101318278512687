import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ImCross } from "react-icons/im";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MdOutlineClose } from "react-icons/md";

import Image from "../Image";
import AddMember from "../../images/add_member.svg";
import PageLoader from "../PageLoader";

import { userSchema } from "../Validation/Admin/AddComment";
import FloatingModal from "./FloatingModal";
import FormReactMultiSelect from "../FormReactMultiSelect";
import FormInputField from "../FormInputField";
import FormReactMultiSelectTask from "./FormReactMultiSelectTask";
import ErrorDisplay from "../ErrorDisplay";
import INDITextAreaField from "../IndependantFormFields/INDITextAreaField";
import {
  formatDate,
  formatOnlyTime,
  formatTime,
  formatTimeDate,
  getDayMonthYear,
} from "./../Functions";
import FormTextArea from "./../FormTextArea";

const ManageComments = ({ taskId, prefix, projectId }) => {
  const [disabled, setDisabled] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [smallModal, setSmallModel] = useState(false);
  const [users, setUsers] = useState();
  const [error, setError] = useState("");

  //For Users to assign
  const [customSelectValue, setCustomSelectValue] = useState();
  const [userImage, setUserImage] = useState([]);

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = methods;

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = () => {
    if (!disabled) {
      setDisabled(true);

      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/task/${taskId}`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res.data.data);
          setUsers(res.data.data.comments);

          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          return Promise.reject();
        });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Data loaded",
      //   error: "An error has occurred",
      // });
    }
  };

  const removeUser = (_id) => {
    if (!disabled) {
      setDisabled(true);
      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/comment/${_id}`,
        method: `DELETE`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching data
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          getInfo();
          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });
    }
  };

  const HandleForm = (data) => {
    console.log(data);

    if (!disabled) {
      setDisabled(true);

      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/comment/${taskId}`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);

          reset();
          getInfo();

          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);

          return Promise.reject();
        });
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4 md:w-4/6">
        {/* <div className="flex flex-row justify-between items-center gap-4">
            <h3 className="text-base">Add Members</h3>
            <MdOutlineClose
              size={20}
              className="text-gray-500 hover:opacity-70 cursor-pointer"
              onClick={() => setSmallModel(false)}
            />
          </div> */}

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(HandleForm)}>
            <div className="flex flex-row gap-3 items-start">
              <Image
                src={"www.test.com"}
                className="h-12 w-12 rounded-lg cursor-pointer"
              />

              <div className="flex flex-col gap-4  text-xs w-full">
                <FormTextArea
                  name={"body"}
                  customUrl={`${process.env.REACT_APP_BASEURL}/api/v1/comment/${taskId}`}
                  placeHolder={"Write a commment..."}
                  className="px-5 py-3 text-sm shadow-custom font-light rounded-xl"
                  required
                />
                {isValid && (
                  <input
                    type="submit"
                    value={"Save"}
                    className="green text-white rounded-lg p-2 w-20 min-w-fit cursor-pointer  self-end"
                  />
                )}
              </div>
            </div>
          </form>
        </FormProvider>

        {/* <FormReactMultiSelectTask
            apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/project/${projectId}`}
            name={"members"}
            imageClassName={"h-12 w-12"}
            search={false}
            searchCriteria={"username"}
            setCustomValue={setCustomSelectValue}
            customValue={customSelectValue}
            selectedImage={userImage}
            setSelectedImage={setUserImage}
            images={false}
          /> */}

        {users && users.length > 0 && (
          <div className="flex flex-col gap-8 self-start flex-wrap mt-9 w-full transition ease-in-out delay-150">
            {users &&
              users.map((users) => (
                <div key={users._id} className="relative ">
                  {console.log(users)}

                  <div className="flex flex-row gap-3 items-start  ">
                    <Image
                      src={`${prefix}/${users.photo}`}
                      className="h-12 w-12 rounded-lg "
                      alt="upload"
                    />

                    <div className="flex flex-col gap-3 w-full">
                      <div className="text-xs text-gray-500 font-semibold flex flex-row gap-5 ">
                        <span className="text-black">
                          {users.userId && users.userId.username}
                        </span>
                        <span>
                          {" "}
                          {getDayMonthYear(users.createAt)} at{" "}
                          {formatOnlyTime(users.createAt)}
                        </span>

                        <ImCross
                          onClick={() => {
                            removeUser(users._id);
                          }}
                          size={16}
                          className="red text-white p-1 rounded-full cursor-pointer mr-0 mx-auto self-end"
                        />
                      </div>
                      <INDITextAreaField
                        name={"body"}
                        taskId={taskId}
                        customUrl={`${process.env.REACT_APP_BASEURL}/api/v1/comment/${users._id}`}
                        initialValue={users.body}
                        className="px-5 py-3 text-sm shadow-custom font-light rounded-xl w-full"
                        focusedClassName="px-5 py-3 text-sm shadow-custom font-light rounded-xl w-full"
                        required
                      />
                    </div>
                  </div>

                  {/* <button
              className="red text-white p-1.5 rounded font-thin text-xs mt-3 "
              type="button"
              onClick={() => deleteHandler(image)}
            >
              Remove
            </button> */}
                </div>
              ))}
          </div>
        )}
      </div>

      <ErrorDisplay>{error}</ErrorDisplay>

      {disabled && <PageLoader size={30} type="ThreeDots" />}
    </>
  );
};

export default ManageComments;
