import { Box, FormControl, Select } from "@material-ui/core";

const FilterDropdown = ({ data, children }) => {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          // displayEmpty={true}
          // renderValue={(value) =>
          //   value?.length
          //     ? Array.isArray(value)
          //       ? value.join(", ")
          //       : value
          //     : "All"
          // }
          id="demo-simple-select"
          onChange={(e) => {
            console.log(data.columnDef.tableData.id);
            data.onFilterChanged(data.columnDef.tableData.id, e.target.value);
          }}
        >
          {children}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FilterDropdown;
