import { NavLink, Outlet, useLocation } from "react-router-dom";

const Offices = () => {
  const location = useLocation();
  let currentPath = location.pathname;
  currentPath = currentPath.substring(1);

  return (
    <div>
      <div className="px-12 py-5 mt-4 flex flex-row justify-start items-center gap-6">
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "navlink"
              : "" + currentPath === "SU/offices"
              ? "navlink"
              : "inactive-navlink"
          }
          to={"all-offices"}
        >
          All Offices
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "navlink"
              : "inactive-navlink" + currentPath === "SU/offices/office-req"
              ? "navlink"
              : "inactive-navlink"
          }
          to={"office-req"}
        >
          Office Requests
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default Offices;
