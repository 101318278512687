import React, { useState, useRef, useReducer, useEffect } from "react";
import { userSchema } from "../Validation/Admin/AdminProfileSchema";
import { FormProvider, useForm } from "react-hook-form";
import { MdModeEdit } from "react-icons/md";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import PageLoader from "../PageLoader";
import { removeEmpty } from "../Functions";
import Modal from "../Modal";
import FormInputField from "../FormInputField";
import FormTextArea from "../FormTextArea";
import ErrorDisplay from "../ErrorDisplay";
import ManageFiles from "../ProjectDesc/ManageFiles";
import ManageUsers from "../ProjectDesc/ManageUsers";

const ProjectDesc = ({ _id, editable = true }) => {
  const [selectedUser, setSelectedUser] = useState({
    photo: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [editModal, setEditModal] = useState(false);

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = () => {
    if (!disabled) {
      setDisabled(true);

      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/project/${_id}`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res.data.data);
          setSelectedUser(res.data.data);
          console.log(res.data.data.project);
          reset({
            project: res.data.data.project,
            description: res.data.data.description,
          });
          setLoaded(true);
          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          return Promise.reject();
        });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Data loaded",
      //   error: "An error has occurred",
      // });
    }
  };

  const editUser = () => {
    setEditModal(true);
  };

  const HandleEditForm = (data) => {
    console.log(data);

    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/project/${_id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setEditForm(false);
          setEditModal(false);
          setDisabled(false);
          getInfo();

          // reset();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Details Updated ",
        error: "An error has occurred",
      });
    }
  };

  if (loaded === false) {
    return <PageLoader type="ThreeDots" size={40} />;
  } else
    return (
      <div className="flex flex-col gap-4">
        <span className="flex flex-row gap-5 items-center">
          <h2 className="text-3xl">{selectedUser.project}</h2>
          {editable && (
            <MdModeEdit
              size={20}
              className={"text-gray-500 cursor-pointer hover:opacity-75"}
              onClick={() => {
                editUser();
              }}
            />
          )}
        </span>
        <p>{selectedUser.description}</p>
        <p className="text-base  ">Members:</p>
        <ManageUsers
          users={selectedUser.members}
          taskId={selectedUser._id}
          projectId={_id}
          prefix={`${process.env.REACT_APP_IMAGE_PROFILE}`}
          editable={editable}
        />

        <p className="text-base  ">Attachments:</p>
        <ManageFiles
          users={selectedUser.attachment}
          taskId={selectedUser._id}
          projectId={_id}
          prefix={`${process.env.REACT_APP_IMAGE_PROJECT}`}
          editable={editable}
        />
        {/* <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(HandleEditForm)}
          className="flex flex-col gap-6"
        >
          <FormImageField
            name={"file"}
            edit={editForm}
            value={selectedUser.photo}
            prefix={`${process.env.REACT_APP_IMAGE_PROFILE}`}
          />

          <div>
            <h2 className="text-lg font-bold mb-2">Personal Details</h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <FormInputField
                placeholder="Name"
                label={"Name"}
                name={"name"}
                labelClass={"text-md "}
                // value={editForm ? null : selectedUser.username}
                defaultValue={selectedUser.username}
                disabled={!editForm}
              />

              <FormInputField
                name={"phone"}
                type="number"
                label={"Contact Number"}
                placeholder="030XXXXXXXX"
                labelClass={"text-md "}
                defaultValue={selectedUser.phone}
                disabled={!editForm}
              />
              <FormInputField
                name={"nic"}
                type="number"
                label={"CNIC"}
                autocomplete="new-password"
                placeholder="National Identity Number*"
                labelClass={"text-md "}
                defaultValue={selectedUser.nic}
                disabled={!editForm}
              />

              <FormInputField
                placeholder="Date of Birth"
                type="text"
                id="dateOfBirth"
                label={"Date of Birth"}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                name={"dateOfBirth"}
                size="30"
                labelClass={"text-md "}
                defaultValue={formatDate(selectedUser.dateOfBirth)}
                disabled={!editForm}
              />

              <FormSelectField
                name={"maritalStatus"}
                type="text"
                autocomplete="new-password"
                label={"Marital Status"}
                placeholder="Marital Status*"
                labelClass={"text-md "}
                disabled={!editForm}
              >
                <option
                  defaultValue={selectedUser.maritalStatus}
                  disabled
                  selected
                >
                  {selectedUser.maritalStatus}
                </option>
                <option value="single">Single</option>
                <option value="married">Married</option>
              </FormSelectField>

              <FormSelectField
                name={"gender"}
                label={"Gender"}
                autocomplete="new-password"
                placeholder="National Identity Number"
                labelClass={"text-md "}
                disabled={!editForm}
              >
                <option defaultValue={selectedUser.gender} disabled selected>
                  {selectedUser.gender}
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </FormSelectField>

              <FormInputField
                placeholder="Address"
                type="text"
                name={"address"}
                label={"Address"}
                labelClass={"text-md "}
                defaultValue={selectedUser.address}
                disabled={!editForm}
              />
            </div>
          </div>
          {console.log(`Edit form = ${editForm}`)}
          <div>
            <h2 className="text-lg font-bold mb-2">Office Details</h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <FormInputField
                placeholder="Designation"
                name={"designation"}
                label={"Designation"}
                labelClass={"text-md "}
                defaultValue={selectedUser.designation}
                disabled={!editForm}
              />

              <FormInputField
                placeholder="Salary"
                type="number"
                name={"salary"}
                label={"Salary"}
                labelClass={"text-md "}
                defaultValue={selectedUser.salary}
                disabled={!editForm}
              />
            </div>
          </div>

          <div>
            <h2 className="text-lg font-bold mb-2">Emergency Details</h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <FormInputField
                placeholder="Emergency Name"
                name={"emerg_name"}
                label={"Emergency Name"}
                labelClass={"text-md "}
                defaultValue={selectedUser.emerg_name}
                disabled={!editForm}
              />

              <FormInputField
                placeholder="030XXXXXXXX*"
                type="number"
                name={"emerg_contact"}
                label={"Emergency Contact"}
                labelClass={"text-md "}
                defaultValue={selectedUser.emerg_contact}
                disabled={!editForm}
              />
            </div>
          </div>
          <ErrorDisplay>{error}</ErrorDisplay>

          {editForm === true ? (
            <input
              type={"submit"}
              value={"Save Changes"}
              className="green p-3 w-2/4 sm:w-1/4 lg:w-1/6 mt-3 self-center rounded-lg text-white cursor-pointer"
            />
          ) : (
            <button
              onClick={() => setEditForm(true)}
              type={"button"}
              className="green p-3 w-2/4 sm:w-1/4 lg:w-1/6 mt-3 self-center rounded-lg text-white cursor-pointer"
            >
              Edit{" "}
            </button>
          )}
        </form>
      </FormProvider> */}

        {/* Edit Project Modal */}
        <Modal
          show={editModal}
          className="w-3/4 lg:w-2/6 md:h-fit"
          close={() => {
            setEditModal(false);
          }}
        >
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(HandleEditForm)}
              className="flex flex-col gap-6 justify-center align-center"
            >
              <FormInputField
                name={"project"}
                required
                label={"Project"}
                labelClass="text-lg font-semibold"
                disabled={!editForm}
                type={"text"}
              />

              <FormTextArea
                name={"description"}
                required
                label={"Description"}
                labelClass="text-lg font-semibold"
                disabled={!editForm}
                rows="8"
                type={"text"}
              />

              <ErrorDisplay>{error}</ErrorDisplay>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  onClick={() => setEditModal(false)}
                  type="button"
                  value="Cancel"
                  className="red p-3  rounded-lg text-white cursor-pointer"
                />
                {editForm === true ? (
                  <input
                    type={"submit"}
                    value={"Save Changes"}
                    className="green p-3  rounded-lg text-white cursor-pointer"
                  />
                ) : (
                  <button
                    onClick={() => setEditForm(true)}
                    type={"button"}
                    className="green p-3  rounded-lg text-white cursor-pointer"
                  >
                    Edit{" "}
                  </button>
                )}
              </div>
            </form>
          </FormProvider>
        </Modal>
      </div>
    );
};

export default ProjectDesc;
