import Image from "./Image";

const InventoryListDisplay = ({ src, name, id }) => {
  return (
    <div className="flex flex-row gap-4 justify-start items-center p-3">
      <Image className="h-10 w-10" src={src} />
      <p>{name}</p>
      {console.log(name)}
    </div>
  );
};

export default InventoryListDisplay;
