import axios from "axios";
import { useEffect, useState } from "react";
import { ImCross } from "react-icons/im";
import { FormProvider, useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";

import Image from "../Image";
import AddMember from "../../images/add_member.svg";
import PageLoader from "../PageLoader";

import ErrorDisplay from "../ErrorDisplay";
import FormReactMultiSelect from "../FormReactMultiSelect";

const ManageAllowance = ({ prefix, editable, gradeId }) => {
  const [disabled, setDisabled] = useState(false);
  const [smallModal, setSmallModel] = useState(false);
  const [allowance, setAllowances] = useState();
  const [error, setError] = useState("");

  //For Users to assign
  const [customSelectValue, setCustomSelectValue] = useState();
  const [userImage, setUserImage] = useState([]);

  //For form
  const methods = useForm({
    // resolver: yupResolver(userSchema),
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = () => {
    if (!disabled) {
      setDisabled(true);

      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/grade/${gradeId}`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res.data.data);
          setAllowances(res.data.data.allowance);

          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          return Promise.reject();
        });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Data loaded",
      //   error: "An error has occurred",
      // });
    }
  };

  const removeUser = (_id) => {
    const allowance = [_id];
    console.log("Allowance ID " + _id);
    console.log("Grade ID " + gradeId);

    const data = {
      allowance: allowance,
    };

    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/grade/${gradeId}/removeAllowance`,
        method: `PUT`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          getInfo();
          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });
    }
  };

  const HandleForm = (data) => {
    console.log(data);
    console.log(customSelectValue);

    if (!disabled) {
      setDisabled(true);

      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/grade/${gradeId}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setCustomSelectValue(null);
          setUserImage([]);
          getInfo();
          setSmallModel(false);
          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);

          return Promise.reject();
        });
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4 ">
        <p className="text-lg font-semibold">Allowances</p>
        <div className="grid grid-cols-1 gap-1 mb-4 items-start w-full">
          {allowance &&
            allowance.map((allowance) => (
              <div key={allowance._id} className="relative p-2 ">
                {editable && (
                  <ImCross
                    onClick={() => {
                      removeUser(allowance._id);
                    }}
                    size={16}
                    className="red text-white p-1 rounded-full cursor-pointer absolute right-0 top-2 ml-auto mr-0"
                  />
                )}
                {console.log(allowance)}

                <div className="mt-2 px-5 input-form flex flex-col items-start font-thin text-start whitespace-normal overflow-clip">
                  <p className="font-bold">{allowance.allowanceType.name}</p>
                  {/* <p className="font-normal"> {allowance.description} </p> */}
                  <p> {allowance.value} </p>
                </div>
              </div>
            ))}
          {editable && (
            <>
              {!smallModal && (
                <div className="relative p-2 ">
                  <Image
                    src={AddMember}
                    className="h-16 w-16 self-start rounded-lg cursor-pointer hover:opacity-70"
                    alt="upload"
                    onClick={() => setSmallModel(true)}
                  />

                  {/* <div className="mt-2 p-1  flex flex-col items-center overflow-clip">
                <span className="font-thin w-24 text-ellipsis text-center whitespace-normal break-after-all">
                  {"Add Allowance"}
                </span>
              </div> */}
                </div>
              )}
            </>
          )}
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(HandleForm)}
              className={
                smallModal
                  ? "flex flex-col gap-6 justify-start align-center w-full"
                  : "hidden"
              }
            >
              <div className="flex flex-col gap-3  mb-4">
                <div className="flex flex-row justify-end items-center">
                  <MdOutlineClose
                    size={20}
                    className="text-gray-500 hover:opacity-70 cursor-pointer"
                    onClick={() => setSmallModel(false)}
                  />
                </div>

                <FormReactMultiSelect
                  apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/allowance?&status=Active`}
                  searchCriteria={"description"}
                  name={"allowance"}
                  setCustomValue={setCustomSelectValue}
                  customValue={customSelectValue}
                  selectedImage={userImage}
                  setSelectedImage={setUserImage}
                  images={false}
                />

                <input
                  type="submit"
                  value={"Save"}
                  className="green text-white rounded-lg p-2 w-28 min-w-fit cursor-pointer"
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </div>

      <ErrorDisplay>{error}</ErrorDisplay>

      {disabled && <PageLoader size={30} type="ThreeDots" />}
    </>
  );
};

export default ManageAllowance;
