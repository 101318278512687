import * as yup from "yup";

export const userSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  username: yup.string().required("Admin's name is required"),
  phone: yup
    .string()
    .required("Contact number is required")
    .matches(
      /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm,
      "Inavlid contact format"
    ),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email address is required"),
  password: yup.string().required("Password is required"),
});
