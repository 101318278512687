import { Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";

import tableIcons from "../../../components/MUTable/MaterialTableIcons";

import React, { useState, useRef, useReducer, useEffect } from "react";
import { userSchema } from "../../../components/Validation/Admin/AddGradeSchema";
import { useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";

import {
  formatDate,
  getFirstDay,
  getLastDay,
  removeEmpty,
} from "../../../components/Functions";
import FormInputField from "../../../components/FormInputField";
import ErrorDisplay from "../../../components/ErrorDisplay";
import GenerateSlipBtn from "../../../components/GenerateSlipBtn";
import { useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SalarySlip from "./SalarySlip";
// import ReactPDF, { pdf, PDFDownloadLink } from "@react-pdf/renderer";
// import SalaryPDF from "../../../components/SalaryPDF";
// import ReportPDF from "../../../components/ReportPDF";

const Salary = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedUser, setSelectedUser] = useState({
    name: "",
    arrivalTime: "",
    departureTime: "",
  });
  const [error, setError] = useState("");
  let navigate = useNavigate();

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  //Sending deducation days
  const HandleForm = (data) => {
    console.log(data);

    navigate("slip-generation", {
      state: { _id: selectedUser._id },
    });

    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/salary/${selectedUser._id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          navigate("slip-generation", {
            state: { _id: selectedUser._id },
          });
          // setModal(false);
          // setDisabled(false);
          // reset();
          // refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Deduction Days Updated",
        error: "An error has occurred",
      });
    }
  };

  // const downloadPDF = async (data) => {
  //   // const blob = await pdf(<SalaryPDF data={data} />).toBlob();
  //   // saveAs(blob, `${data.info.username}'s Slip`);
  // };

  // const downloadReport = async (data) => {
  //   // const blob = await pdf(<ReportPDF data={data} />).toBlob();
  //   // saveAs(blob, "Report.pdf");
  // };

  // const handlingPayslipGeneration = async (rowData) => {
  //   if (!disabled) {
  //     setDisabled(true);
  //     setError();
  //     const promise = axios({
  //       // Endpoint to send files
  //       url: `${process.env.REACT_APP_BASEURL}/api/v1/salary/${rowData._id}`,
  //       method: "GET",
  //       headers: {
  //         // Add any auth token here
  //         // "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     })
  //       // Handle the response from backend here
  //       .then((res) => {
  //         setDisabled(false);
  //         console.log(res.data.data);
  //         downloadPDF(res.data.data);
  //       })
  //       // Catch errors if any
  //       .catch((err) => {
  //         setDisabled(false);
  //         console.log(err);
  //         return Promise.reject();
  //       });
  //     toast.promise(promise, {
  //       loading: "Loading",
  //       success: "Slip Generated",
  //       error: "An error has occurred",
  //     });
  //   }
  // };

  // const handleReportGeneration = async (rowData) => {
  //   if (!disabled) {
  //     setDisabled(true);
  //     setError();
  //     const promise = axios({
  //       // Endpoint to send files
  //       url: `${process.env.REACT_APP_BASEURL}/api/v1/salary/report/${rowData._id}`,
  //       method: "GET",
  //       headers: {
  //         // Add any auth token here
  //         // "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     })
  //       // Handle the response from backend here
  //       .then((res) => {
  //         setDisabled(false);
  //         console.log(res.data.data);
  //         downloadReport(res.data.data);
  //       })
  //       // Catch errors if any
  //       .catch((err) => {
  //         setDisabled(false);
  //         console.log(err);
  //         return Promise.reject();
  //       });
  //     toast.promise(promise, {
  //       loading: "Loading",
  //       success: "Report Generated",
  //       error: "An error has occurred",
  //     });
  //   }
  // };

  const reportPage = (rowData) => {
    console.log(rowData._id);

    navigate("report-generation", {
      state: { _id: rowData._id },
    });
  };

  const salaryPage = (rowData) => {
    console.log(rowData._id);
    navigate("slip-generation", {
      state: { _id: rowData._id },
    });
  };

  const columns = [
    { title: "NAME", field: "username", filtering: false },
    {
      title: "Email",
      filtering: false,
      field: "userId.email",
    },
    {
      title: "USERID",
      filtering: false,
      field: "userId.test",
    },
    {
      title: "DESIGNATION",
      filtering: false,
      field: "userId.designation",
    },
    {
      title: "DATE",
      filtering: false,
      field: "date",
      render: (rowData) => formatDate(rowData.date),
    },
    {
      filtering: false,
      render: (rowData) => (
        <div className="flex flex-col gap-3">
          <SalarySlip id={rowData._id} date={startDate} />
          {/* <div
            className="py-2 px-4 text-center text-white  green rounded-lg cursor-pointer whitespace-nowrap"
            onClick={() => {
              // reset();
              // salaryPage(rowData);
              handlingPayslipGeneration(rowData);
            }}
          >
            Generate Salary Slip
          </div> */}

          {/* <div
            className="py-2 px-4 text-center text-white transition duration-400 bg-blue-500 hover:shadow hover:shadow-blue-500 rounded-lg cursor-pointer whitespace-nowrap "
            onClick={() => {
              // reset();
              // salaryPage(rowData);
              handleReportGeneration(rowData);
            }}
          >
            <h2>Generate Report</h2>
          </div> */}
        </div>
      ),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-row md:absolute gap-6 mb-4 z-10 right-10 top-9">
          <ReactDatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              refreshTable();
            }}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            placeholderText="Select Month"
            className="input-calendar cursor-pointer"
          />
          {console.log(getLastDay(startDate))}
          {console.log(getFirstDay(startDate))}
          {/* <button
            onClick={() => setModal(true)}
            className="py-4 px-10 green text-white font-thin rounded-xl whitespace-nowrap"
          >
            Add Salary
          </button> */}
        </div>
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",

                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            borderBottom: "2px solid #FFFFFF",
            filtering: true,
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderColor: "white",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          //   actions={[
          //     {
          //       icon: FaMoneyCheckAlt,

          //       onClick: (event, rowData) => {
          //         reset();
          //         editUser(rowData);
          //         setEditForm(false);
          //       },
          //     },
          //     {
          //       icon: GenerateSlipBtn,
          //       onClick: (event, rowData) => {
          //         reset();
          //         editUser(rowData);
          //         setEditForm(false);
          //       },
          //     },

          //     // (rowData) => ({
          //     //   icon: "delete",
          //     //   tooltip: "Delete User",
          //     //   onClick: (event, rowData) =>
          //     //     confirm("You want to delete " + rowData.name),
          //     //   disabled: rowData.birthYear < 2000,
          //     // }),
          //   ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${
                process.env.REACT_APP_BASEURL
              }/api/v1/salary?&date[gte]=${getFirstDay(
                startDate
              )}&date[lte]=${getLastDay(startDate)}`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value)
                    return `&${filter.column.field}${filter.operator}${filter.value}`;
                });

                url += filter.join("");
              }

              if (query.search) {
                url += "&username=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  return Promise.reject(response); // 2. reject instead of throw
                })
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data);
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                  });
                })
                .catch((error) => console.log("error", error));
            })
          }
        />
      </div>

      {/* Add Deduction days */}
      {/* <Modal
        show={modal}
        className="w-3/4 lg:w-1/6 md:h-fit"
        close={() => {
          setModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className="flex flex-col gap-6 justify-center align-center"
          >
            <FormInputField
              name={"deductionDays"}
              required
              label={"Days to be deducted"}
              labelClass="text-lg font-semibold"
              type={"number"}
            />

            <ErrorDisplay>{error}</ErrorDisplay>

            <div className="grid grid-cols-2 gap-4 ">
              <input
                onClick={() => setModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              <input
                type="submit"
                value="Send"
                className="grey p-3  rounded-lg text-white cursor-pointer"
              />
            </div>
          </form>
        </FormProvider>
      </Modal> */}

      {/* Delete Salary Modal */}
      {/* <Modal
        show={deleteModal}
        close={() => {
          setDeleteModal(false);
        }}
      >
        <h2 className="text-lg">
          Are you sure you want to <br />
          {selectedUser.status == "Active" ? "deactivate " : "activate "}
          {selectedUser.name}
        </h2>
        <div className="grid grid-cols-2 gap-4 mt-5">
          <input
            onClick={() => setDeleteModal(false)}
            type="button"
            value="Cancel"
            className="grey p-3 rounded-lg text-white cursor-pointer"
          />
          <input
            type="button"
            onClick={() => HandleDeleteForm()}
            value={selectedUser.status == "Active" ? "Deactivate" : "Activate"}
            className="red p-3 rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal> */}
    </main>
  );
};

export default Salary;
