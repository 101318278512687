import Image from "./Image";

const ImageListDisplay = ({ src, imageClass }) => {
  return (
    <div>
      <Image className={imageClass} src={src} />
    </div>
  );
};

export default ImageListDisplay;
