import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";

//Components
import ErrorBoundary from "./components/ErrorBoundary";
import ProtectedRoute from "./components/protected-routes/ProtectedRoute";
import SalaryPDF from "./components/SalaryPDF";
import NavContext from "./Context/NavContext";
import WithNav from "./routes/WithNav";
import WithoutNav from "./routes/WithoutNav";

//Admin
import AddAppraisalForm from "./pages/Admin/Appraisal/AddAppraisalForm";
import AppraisalForm from "./pages/Admin/Appraisal/AppraisalForm";
import AppraisalFormList from "./pages/Admin/Appraisal/AppraisalFormList";
import Criteria from "./pages/Admin/Appraisal/Criteria";
import FeedbackQuestions from "./pages/Admin/Appraisal/FeedbackQuestions";
import FeedbackResponses from "./pages/Admin/Appraisal/FeedbackResponses";
import FeedbackResponseView from "./pages/Admin/Appraisal/FeedbackResponseView";
import Parameters from "./pages/Admin/Appraisal/Parameters";
import Schedule from "./pages/Admin/Appraisal/Schedule";
import AdAttendance from "./pages/Admin/Attendance/Attendance";
import ADTodayAttendance from "./pages/Admin/Attendance/TodayAttendance";
import ADUserAttendance from "./pages/Admin/Attendance/UserAttendance";
import CompleteDetails from "./pages/Admin/CompleteDetails/CompleteDetails";
import ADCSV from "./pages/Admin/CSV/CSV";
import AdDashboad from "./pages/Admin/Dashboard/Dashboard";
import FileManagement from "./pages/Admin/FileManagement/FileManagement";
import InventoryAssignment from "./pages/Admin/Inventory/InventoryAssignment";
import InventoryManagement from "./pages/Admin/Inventory/InventoryManagement";
import AdLeaveManagement from "./pages/Admin/LeaveManagement/LeaveManagement";
import LeaveTypes from "./pages/Admin/LeaveManagement/LeaveTypes";
import Modules from "./pages/Admin/Modules/Modules";
import ModuleRequest from "./pages/Admin/Modules/ModulesRequest";
import ModuleTable from "./pages/Admin/Modules/ModuleTable";
import OfficeDetails from "./pages/Admin/OfficeDetails/OfficeDetails";
import Allowances from "./pages/Admin/Payroll/Allowances";
import Bonus from "./pages/Admin/Payroll/Bonus";
import Grades from "./pages/Admin/Payroll/Grades";
import GradesManagement from "./pages/Admin/Payroll/GradesManagement";
import Report from "./pages/Admin/Payroll/Report";
import Salary from "./pages/Admin/Payroll/Salary";
import SalaryMethod from "./pages/Admin/Payroll/SalaryMethod";
import SalarySlip from "./pages/Admin/Payroll/SalarySlip";
import TaxPolicy from "./pages/Admin/Payroll/TaxPolicy";
import Profile from "./pages/Admin/Profile/Profile";
import Candidates from "./pages/Admin/Recruitment/Candidates";
import Interview from "./pages/Admin/Recruitment/Interview";
import Jobs from "./pages/Admin/Recruitment/Jobs";
import Requests from "./pages/Admin/Requests/Requests";
import UserRequests from "./pages/Admin/Requests/UserRequests";
import YourRequests from "./pages/Admin/Requests/YourRequests";
import AllotmentRules from "./pages/Admin/RewardSystem/AllotmentRules";
import CustomAllotment from "./pages/Admin/RewardSystem/CustomAllotment";
import EmployeeOfDay from "./pages/Admin/RewardSystem/EmployeeOfDay";
import EmployeeOfMonth from "./pages/Admin/RewardSystem/EmployeeOfMonth";
import RedeemRequests from "./pages/Admin/RewardSystem/RedeemRequests";
import RedeemShop from "./pages/Admin/RewardSystem/RedeemShop";
import Shifts from "./pages/Admin/Shifts/Shifts";
import ADProjects from "./pages/Admin/TaskManagement/Projects";
import ADProjectView from "./pages/Admin/TaskManagement/ProjectView";
import ADSoloTasks from "./pages/Admin/TaskManagement/SoloTasks";
import Users from "./pages/Admin/Users/Users";
import Voting from "./pages/Admin/Voting/Voting";
import ADMonthlyReports from "./pages/Admin/Reports/MonthlyReports";
import UserReports from "./pages/Admin/Reports/UserReports";

//SuperAdmin
import SUCSV from "./pages/SuperAdmin/CSV/CSV";
import Complains from "./pages/SuperAdmin/Complains/Complains";
import AllowanceTypes from "./pages/SuperAdmin/CustomCategories/AllowanceTypes";
import InventoryTypes from "./pages/SuperAdmin/CustomCategories/InventoryTypes";
import ProductTypes from "./pages/SuperAdmin/CustomCategories/ProductTypes";
import Dashboard from "./pages/SuperAdmin/Dashboard/Dashboard";
import ModulesRequest from "./pages/SuperAdmin/Modules/ModulesRequest";
import AllOffices from "./pages/SuperAdmin/Offices/AllOffices";
import OfficeRequests from "./pages/SuperAdmin/Offices/OfficeRequests";
import Offices from "./pages/SuperAdmin/Offices/Offices";
import TalentCriteria from "./pages/SuperAdmin/TalentGame/TalentCriteria";
import TalentQuestions from "./pages/SuperAdmin/TalentGame/TalentQuestions";
import SUMonthlyReports from "./pages/SuperAdmin/Reports/MonthlyReports";

//Public
import EmailSent from "./pages/EmailSent";
import ForgotPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import ResetPassword from "./pages/ResetPassword";
import SignUp from "./pages/SignUp";
import SignUpWait from "./pages/SignUpWait";
import TalentGameResponses from "./pages/Admin/Recruitment/TalentGameResponses";
import PublicRecruitment from "./pages/PublicRecruitment";

//Candidate
import CDLogin from "./pages/Candidate/Login/Login";
import TalentGame from "./pages/Candidate/TalentGame/TalentGame";

//Supervisor
import SVAttendance from "./pages/SuperVisor/Attendance/Attendance";
import SVTodayAttendance from "./pages/SuperVisor/Attendance/TodayAttendance";
import SVUserAttendance from "./pages/SuperVisor/Attendance/UserAttendance";
import SvDashboad from "./pages/SuperVisor/Dashboard/Dashboard";
import LeaveManagement from "./pages/SuperVisor/LeaveManagement/LeaveManagement";
import SVProjects from "./pages/SuperVisor/TaskManagement/Projects";
import SVProjectView from "./pages/SuperVisor/TaskManagement/ProjectView";
import SVSoloTasks from "./pages/SuperVisor/TaskManagement/SoloTasks";
import CheckInternet from "./components/CheckInternet";

function App() {
  const [nav, setNav] = useState(true);

  const value = { nav, setNav };

  return (
    <ErrorBoundary>
      <CheckInternet />

      <div className="App">
        <NavContext.Provider value={value}>
          <Toaster />

          <Routes>
            <Route element={<WithoutNav />}>
              {/* PUBLIC */}
              <Route path="/recruitment/:id" element={<PublicRecruitment />} />
              <Route path="/recruitment" element={<CDLogin />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/signup-wait" element={<SignUpWait />} />
              <Route path="/forgot" element={<ForgotPassword />} />
              <Route path="/email-sent" element={<EmailSent />} />
              <Route path="/resetpassword/:id" element={<ResetPassword />} />
              <Route path="/test" element={<SalaryPDF />} />
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Login />} />
              <Route path="*" element={<PageNotFound />} />

              {/* ADMIN */}
              <Route path="/AD/*" element={<ProtectedRoute user={"admin"} />}>
                <Route path="complete-details" element={<CompleteDetails />} />
              </Route>

              {/* CANDIDATE */}
              <Route path="/CD/*" element={<ProtectedRoute user={""} />}>
                <Route path="talent-game" element={<TalentGame />} />
              </Route>
            </Route>

            <Route element={<WithNav />}>
              {/* SUPERADMIN */}
              <Route
                path="/SU/*"
                element={<ProtectedRoute user={"superAdmin"} />}
              >
                <Route path="Dashboard" element={<Dashboard />} />

                <Route path="offices/*" element={<Offices />}>
                  <Route index element={<AllOffices />} />
                  <Route path="all-offices" element={<AllOffices />} />
                  <Route path="office-req" element={<OfficeRequests />} />
                </Route>

                {/* <Route path="offices/" element={<AllOffices />} /> */}

                <Route path="complains" element={<Complains />} />
                <Route path="modules" element={<ModulesRequest />} />

                <Route path="custom-categories">
                  <Route index element={<Navigate to={"inventory-types"} />} />
                  <Route path="inventory-types" element={<InventoryTypes />} />
                  <Route path="allowance-types" element={<AllowanceTypes />} />
                  <Route path="product-types" element={<ProductTypes />} />
                </Route>

                <Route path="csv" element={<SUCSV />} />

                <Route path="reports/*">
                  <Route index element={<Navigate to={"monthly-reports"} />} />
                  <Route
                    path="monthly-reports"
                    element={<SUMonthlyReports />}
                  />
                </Route>

                <Route path="talent-game">
                  <Route index element={<Navigate to={"criteria"} />} />
                  <Route path="criteria" element={<TalentCriteria />} />
                  <Route path="questions" element={<TalentQuestions />} />
                </Route>
              </Route>

              {/* ADMIN */}

              <Route path="/AD/*" element={<ProtectedRoute user={"admin"} />}>
                <Route path="dashboard" element={<AdDashboad />} />
                <Route path="users" element={<Users />} />

                <Route path="attendance/*" element={<AdAttendance />}>
                  <Route index element={<ADTodayAttendance />} />
                  <Route
                    path="today-attendance"
                    element={<ADTodayAttendance />}
                  />
                  <Route
                    path="user-attendance"
                    element={<ADUserAttendance />}
                  />
                </Route>

                <Route path="leave-management/*">
                  <Route index element={<Navigate to={"leave-requests"} />} />

                  <Route
                    path="leave-requests"
                    element={<AdLeaveManagement />}
                  />

                  <Route path="leave-types" element={<LeaveTypes />} />
                </Route>

                <Route path="requests/*" element={<Requests />}>
                  <Route index element={<UserRequests />} />
                  <Route path="user-req" element={<UserRequests />} />
                  <Route path="your-req" element={<YourRequests />} />
                </Route>

                <Route path="reward/*">
                  <Route index element={<Navigate to={"employee-day"} />} />
                  <Route path="employee-day" element={<EmployeeOfDay />} />
                  <Route path="employee-month" element={<EmployeeOfMonth />} />
                  <Route path="allotment-rules" element={<AllotmentRules />} />
                  <Route
                    path="custom-allotment"
                    element={<CustomAllotment />}
                  />
                  <Route path="redeem-shop" element={<RedeemShop />} />
                  <Route path="redeem-requests" element={<RedeemRequests />} />
                </Route>

                <Route path="inventory/*">
                  <Route
                    index
                    element={<Navigate to={"inventory-management"} />}
                  />
                  <Route
                    path="inventory-management"
                    element={<InventoryManagement />}
                  />
                  <Route
                    path="inventory-assignment"
                    element={<InventoryAssignment />}
                  />
                </Route>

                <Route path="task-management/*">
                  <Route index element={<Navigate to={"projects"} />} />

                  <Route path="projects/*">
                    <Route index element={<ADProjects />} />
                    <Route path="view-project" element={<ADProjectView />} />
                  </Route>

                  <Route path="solo-tasks" element={<ADSoloTasks />} />
                </Route>

                <Route path="payroll/*">
                  <Route index element={<Navigate to={"tax-policy"} />} />
                  <Route path="tax-policy" element={<TaxPolicy />} />
                  <Route path="bonus" element={<Bonus />} />
                  <Route path="allowances" element={<Allowances />} />
                  <Route path="grades/*" element={<Grades />}>
                    <Route index element={<GradesManagement />} />
                    <Route
                      path="grades-management"
                      element={<GradesManagement />}
                    />
                    <Route path="salary-method" element={<SalaryMethod />} />
                  </Route>
                  <Route path="salary/*">
                    <Route index element={<Salary />} />
                    <Route path="slip-generation" element={<SalarySlip />} />
                    <Route path="report-generation" element={<Report />} />
                  </Route>
                </Route>

                <Route path="voting" element={<Voting />} />

                <Route path="modules/*" element={<Modules />}>
                  <Route index element={<ModuleRequest />} />
                  <Route path="module-req" element={<ModuleRequest />} />
                  <Route path="module-records" element={<ModuleTable />} />
                </Route>

                <Route path="recruitment/*">
                  <Route index element={<Navigate to={"jobs"} />} />
                  <Route path="jobs" element={<Jobs />} />

                  <Route path="candidates/*">
                    <Route index element={<Candidates />} />
                    <Route
                      path="talent-game/:id"
                      element={<TalentGameResponses />}
                    />
                  </Route>

                  <Route path="interview" element={<Interview />} />
                </Route>

                <Route path="appraisal/*">
                  <Route index element={<Navigate to={"schedule"} />} />
                  <Route path="schedule" element={<Schedule />} />
                  <Route path="criteria" element={<Criteria />} />
                  <Route
                    path="feedback-questions"
                    element={<FeedbackQuestions />}
                  />

                  <Route
                    path="feedback-responses"
                    element={<FeedbackResponses />}
                  />

                  <Route
                    path="feedback-responses/:id"
                    element={<FeedbackResponseView />}
                  />

                  <Route path="appraisal-parameters" element={<Parameters />} />

                  <Route path="appraisal-form/*">
                    <Route index element={<AppraisalFormList />} />
                    <Route path="add" element={<AddAppraisalForm />} />

                    <Route path="form-view/:id" element={<AppraisalForm />} />
                  </Route>
                </Route>

                <Route path="shifts" element={<Shifts />} />
                <Route path="file-management" element={<FileManagement />} />

                <Route path="reports/*">
                  <Route index element={<Navigate to={"monthly-reports"} />} />
                  <Route
                    path="monthly-reports"
                    element={<ADMonthlyReports />}
                  />
                  <Route path="user-reports" element={<UserReports />} />
                </Route>

                <Route path="csv" element={<ADCSV />} />
                <Route path="profile" element={<Profile />} />
                <Route path="office-details" element={<OfficeDetails />} />
              </Route>

              {/* SUPERVISOR */}
              <Route
                path="/SV/*"
                element={<ProtectedRoute user={"supervisor"} />}
              >
                <Route path="dashboard" element={<SvDashboad />} />
                <Route path="leave-management" element={<LeaveManagement />} />
                <Route path="attendance/*" element={<SVAttendance />}>
                  <Route index element={<SVTodayAttendance />} />
                  <Route
                    path="today-attendance"
                    element={<SVTodayAttendance />}
                  />
                  <Route
                    path="user-attendance"
                    element={<SVUserAttendance />}
                  />
                </Route>

                <Route path="task-management/*">
                  <Route index element={<Navigate to={"projects"} />} />

                  <Route path="projects/*">
                    <Route index element={<SVProjects />} />
                    <Route path="view-project" element={<SVProjectView />} />
                  </Route>

                  <Route path="solo-tasks" element={<SVSoloTasks />} />
                </Route>

                {/* <Route path="/SV/requests" element={<SVRequests />} /> */}
              </Route>
            </Route>
          </Routes>
        </NavContext.Provider>
      </div>
    </ErrorBoundary>
  );
}

export default App;
