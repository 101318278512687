import { useIsOnline } from "react-use-is-online";
import Modal from "./Modal";
import { MdSignalWifiBad } from "react-icons/md";

const CheckInternet = () => {
  const { isOnline, isOffline, error } = useIsOnline();

  return (
    <Modal closeButtonShow={false} show={!isOnline}>
      <div className="flex flex-col items-center gap-4 text-center">
        <MdSignalWifiBad className="text-red-600" size={60} />
        <p className="text-lg text-red-600 font-semibold">
          NO INTERNET CONNECTION
        </p>
        <p className="w-3/4">
          You seem to be offline. Please check your internet connection and try
          again.
        </p>
        <button
          className="secondary p-3 text-white rounded-lg"
          onClick={() => window.location.reload()}
        >
          Reload
        </button>
      </div>
    </Modal>
  );
};

export default CheckInternet;
