import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { MdComment, MdOutlineQuestionAnswer } from "react-icons/md";
import CSVComponent from "../../../components/CSVComponent";
import ErrorDisplay from "../../../components/ErrorDisplay";
import PageLoader from "../../../components/PageLoader";
import { formatDate, getFirstDay } from "./../../../components/Functions";
import { getLastDay } from "./../../../components/Functions";
import { getMonthYear } from "./../../../components/Functions";

const MonthlyRequests = ({ date }) => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(true);

  const doc = new jsPDF();

  const HandleRequest = (data) => {
    console.log(getFirstDay(date));
    console.log(getLastDay(date));
    console.log(disabled);

    if (!disabled) {
      setDisabled(true);

      setError();
      const promise = axios({
        // Endpoint to send files
        url: `${
          process.env.REACT_APP_BASEURL
        }/api/v1/offices?&createAt[gte]=${getFirstDay(
          date
        )}&createAt[lte]=${getLastDay(date)}`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);

          try {
            GeneratePDF(res.data.data);
          } catch (error) {
            throw new Error();
          }

          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          setError(err.response.data.error);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "This may take a while",
        success: "Requests Report Generated",
        error: "An error has occurred",
      });
    }
  };

  const formatModules = (data) => {
    return data.join("\n");
  };

  const GeneratePDF = (data) => {
    doc.setFontSize(18);
    doc.text("Monthly Requests Report", 14, 20);

    doc.setFontSize(11);
    doc.text(getMonthYear(date), 14, 28);

    //Code for printing table
    const tableCol = [
      "CREATED AT",
      "OFFICE",
      "ADMIN",
      "EMAIL",
      "CONTACT",
      //   "MODULES",
      "STATUS",
    ];

    const tableVal = data.map(
      ({
        createAt,
        name,
        adminId,
        email,
        phone,
        // fileManagement,
        // inventory,
        // leaveManagement,
        // payroll,
        // rewardSystem,
        // shift,
        // taskManagement,
        status,
      }) => {
        return [
          formatDate(createAt),
          name,
          adminId && adminId.username,
          email,
          phone,
          //   formatModules([
          //     `File Management : ${fileManagement}`,
          //     `Inventory : ${inventory}`,
          //     `Leave Management : ${leaveManagement}`,
          //     `Payroll : ${payroll}`,
          //     `Reward System : ${rewardSystem}`,
          //     `Shift : ${shift}`,
          //     `Task Management : ${taskManagement}`,
          //   ]),
          status,
        ];
      }
    );

    console.log(tableVal);

    autoTable(doc, {
      head: [tableCol],
      body: tableVal,
      styles: { cellPadding: 0.8, fontSize: 8 },
      startY: 40,
    });

    doc.save(`${date} Monthly Request Report`);
  };

  if (loaded === false) {
    return <PageLoader />;
  } else
    return (
      <div>
        <CSVComponent
          icon={<MdOutlineQuestionAnswer size={50} className="fill-black" />}
          heading={"Requests"}
          buttonText={"Download Report"}
          HandleRequest={HandleRequest}
        />

        <ErrorDisplay>{error}</ErrorDisplay>
      </div>
    );
};

export default MonthlyRequests;
