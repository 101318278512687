import React, { useState, useEffect } from "react";
import { userSchema } from "../components/Validation/Admin/AdminProfileSchema";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import FormInputField from "../components/FormInputField";
import logo from "../images/insignia_logo.svg";
import FormSelectField from "../components/FormSelectField";
import ErrorDisplay from "../components/ErrorDisplay";
import { formatDate, removeEmpty } from "../components/Functions";
import Image from "../components/Image";
import FormImageField from "../components/FormImageField";
import PageLoader from "./../components/PageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import FormFileField from "../components/FormFileField";

const PublicRecruitment = () => {
  const [jobData, setJobData] = useState();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [loaded, setLoaded] = useState(false);

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  let navigate = useNavigate();

  let { id } = useParams();

  const getUserInfo = () => {
    if (!disabled) {
      setDisabled(true);

      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/job/${id}`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res.data.data);
          setJobData(res.data.data);
          setLoaded(true);
          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Data loaded",
      //   error: "An error has occurred",
      // });
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const errorDisplay = (error) => {
    if (error) {
      const answer = error.split(",").join("\n");
      return answer;
    }
  };

  const HandleForm = (data) => {
    const resume = data["resume"];
    data["resume"] = resume[0];
    data["job"] = "642ab98516d7a9067c74f425";
    data["status"] = "Applied";
    data["officeId"] = jobData.officeId._id;

    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();
      //removing empty strings

      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/recruitmentUser`,
        method: "POST",
        headers: {
          // Add any auth token here
          "Content-Type": "application/json",
          // authorization: "your token comes here",
        },
        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          // setError(res.message);

          //redirecting

          navigate("/signup-wait");
        })
        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          setError(err.response.data.error);
          // toast.error(err.response.data.error);
          return Promise.reject();
        });
      toast.promise(promise, {
        loading: "Loading",
        success: "Request Sent",
        error: "An error has occurred",
      });
    }
  };
  if (loaded === false) {
    return (
      <div className="grid h-screen w-full place-items-center bg-white overflow-auto">
        <div>
          {!error && <PageLoader />}
          {error && (
            <p className="text-center text-red-700 mt-7 w-full">
              {errorDisplay(error)}
            </p>
          )}
        </div>
      </div>
    );
  } else
    return (
      <div className="grid h-screen w-full place-items-center bg-white overflow-auto p-12">
        <div className=" flex flex-col  items-center text-center gap-6 p-6 sm:p-1  justify-start">
          <div className="flex flex-col gap-3 items-center">
            <img className="h-24 xl:h-32" src={logo} alt="" />
          </div>

          {/* Office Details */}
          <div>
            <p className="uppercase font-semibold">
              {jobData.officeId && jobData.officeId.name}
            </p>
            <p>{jobData.officeId && jobData.officeId.email}</p>
          </div>

          {/* Wrapper div */}
          <div className="grid grid-cols-1 items-center gap-6">
            <div className="flex flex-col gap-4 items-center bg-custom-ash p-4 rounded-lg   min-w-fit">
              <div className="flex flex-col md:flex-row items-center gap-4 md:gap-7 ">
                <p>
                  <strong>Title :</strong> {jobData.jobTitle}
                </p>

                <p>
                  <strong>Type :</strong> {jobData.jobType}
                </p>

                <p>
                  <strong>Location :</strong> {jobData.jobLocation}
                </p>
              </div>

              <div>
                <p>
                  <strong>Description</strong>
                </p>
                <p className=" w-full md:w-80">{jobData.description}</p>
              </div>
            </div>

            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(HandleForm)}
                action=""
                className="flex flex-col justify-center  gap-4"
              >
                <div className="mb-6 ">
                  <FormFileField name="resume" label="Upload Resume" />
                </div>

                <div className="grid  grid-cols-1 md:grid-cols-2 content-center gap-4">
                  <FormInputField
                    type="text"
                    placeholder="Full Name"
                    name={"username"}
                    required
                  />

                  <FormInputField
                    type="email"
                    placeholder="Email"
                    name="email"
                    required
                  />
                </div>

                <FormInputField
                  type="number"
                  placeholder="Experience"
                  min={0}
                  name="experience"
                  required
                />

                <p className="text-center text-red-700 mt-2 w-full">
                  {errorDisplay(error)}
                </p>

                {console.log(errors)}

                <button className=" px-4 py-2 primary rounded-lg text-white w-full lg:w-2/5 self-center text-sm xl:text-base">
                  Apply
                </button>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    );
};

export default PublicRecruitment;
