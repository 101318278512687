import * as yup from "yup";

export const userSchema = yup.object().shape({
  question: yup.string(),
  type: yup.string(),
  options: yup.array().when("type", {
    is: "Mcqs",
    then: yup.array().min(2, "Minimum two options required"),
  }),

  // choices: yup.array().min(2, "Minimum two polling options required"),
});
